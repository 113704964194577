// 2021-08-12 CJ: This file contains all application level parameter

import { environment as env } from 'src/environments/environment';

export const appParam = {
  apiBase: env.apiBase,
  defaultDateFormat: 'dd/MMM/yyyy',
  defaultDateTimeFormat: 'dd/MMM/yyyy hh:mm:ss',
  userType: {
    charity: 'CHARITY',
    basic: 'BASIC',
    premium: 'PREMIUM'
  },
  orderType: {
    drop: 'DROPOFF',
    pickup: 'PICKUP'
  },
  containerCategory: {
    drum: 'DRUM',
    bin: '240LBIN'
  },
  subscriptionCategory: {
    charity: 'CHARITY',
    basic: 'BASIC',
    premium: 'PREMIUM'
  },
  manifestStatus: {
    draft: 'DRAFT',
    optimizeInprogress: 'OPTIMIZE-IN-PROGRESS',
    optimized: 'OPTIMIZED',
    driverAssigned: 'DRIVER-ASSIGNED',
    startRoute: 'ROUTE-IN-PROGRESS',
    binsCollectedFromCustomer: 'BINS-COLLECTED-FROM-CUSTOMER',
    complete: 'COMPLETE'
  },
  auth: {
    region: 'ap-southeast-2',
    userPoolId: env.Cognito.userPoolId,
    userPoolWebClientId: env.Cognito.userPoolWebClientId,
    domain: env.Cognito.domain,
    redirectSignIn: env.Cognito.redirectSignIn,
    redirectSignOut: env.Cognito.redirectSignOut
  },
  cacheKeys: {
    userDetails: 'userDetails',
    authToken: 'authToken'
  },

  // routing paths are parameterised so that any changes to the path can be managed from one location
  routing: {
    signUpType: {
      cash: 'PERSONAL',
      charity: 'CHARITY',
      external: {
        vinnies: 'VINNIES',
        sammyd: 'SAMMYD'
      }
    },
    Userhome: 'userhome',
    C1_signUp: {
      id: 'C1',
      C1001: 'signup-001',
      C1001b: 'signup-001b',
      C1002: 'signup-002',
      C1003: 'signup-003',
      C1004: 'signup-004',
      C1004b: 'signup-004b',
      C1004c: 'signup-004c',
      C1004d: 'signup-004d',
      C1004dConfirm: 'signup-004d-confirm',
      C1004e: 'signup-004e',
      C1004f: 'signup-004f',
      C1004g: 'signup-unavailable',
      C1005: 'userhome'
    },
    C2_signIn: {
      id: 'C2',
      C2001: 'sign-in'
    },
    C3_forgotEmail: {
      id: 'C3',
      C3001: 'forgotemail-001',
      C3002: 'forgotemail-002',
      C3003: 'forgotemail-003'
    },
    C4_forgotPassword: {
      id: 'C4',
      C4001: 'forgotpassword-001',
      C4002: 'forgotpassword-002',
      C4003: 'forgotpassword-003'
    },
    C5_orderBin: {
      id: 'C5',
      C5001: 'orderbin-001',
      C5002: 'orderbin-002',
      C5003: 'orderbin-003',
      C5004: 'orderbin-004',
      C5005: 'orderbin-005',
      C5006: 'orderbin-006',
      C5007: 'orderbin-007',
      C5007b: 'orderbin-007/confirm', // aka 007b
      C5008: 'orderbin-008',
      C5009: 'orderbin-009'
    },
    C7_orderBinCharity: {
      id: 'C7',
      C7001: 'orderbinCharity-001',
      C7003: 'orderbinCharity-003'
    },
    C8_requestPickup: {
      id: 'C8',
      C8001: 'requestPickup-001',
      C8002: 'requestPickup-002', // not required
      C8003: 'requestPickup-003',
      C8004: 'requestPickup-004',
      C8005: 'requestPickup-005'
    },
    C9_requestPickupChange: {
      id: 'C9',
      C9001: 'requestPickupChange-001',
      C9002: 'requestPickupChange-002',
      C9003: 'requestPickupChange-003',
      C9004: 'requestPickupChange-004'
    },
    C10_orderStatus: {
      id: 'C10',
      C10001: 'orderstatus-summary',
      C10002: 'orderstatus-detail'
    },
    C11_changePassword: {
      id: 'C11',
      C11001: 'changePassword-001'
    },
    C12_updateBankDetails: {
      id: 'C12',
      C12001: 'updateBankDetails-001'
    },
    C13_binStatus: {
      id: 'C13',
      C13001: 'binstatus-summary'
    }
  }
};
