import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appWorkflowData } from '../../helper/appWorkflowData';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-uxc1004g',
  templateUrl: './uxc1004g.component.html',
  styleUrls: ['./uxc1004g.component.scss']
})
export class Uxc1004gComponent implements OnInit {
  _style: string = '';

  // screen config data related to the workflow step
  screenConfig: any;

  constructor(
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004g;
    this.appService.appSpinner(false);
  }

  ngOnInit(): void {}

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
