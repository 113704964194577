import { IOptionType } from './appInterfaces';
import * as moment from 'moment';

export const appUtils = {
  //  search and return a specific json object
  objectArrayHas(_array: any[], _searchKey: string, _searchValue: any) {
    for (var index = 0; index < _array.length; ++index) {
      var _object = _array[index];
      if (_object[_searchKey] == _searchValue) {
        return _object;
      }
    }

    return false;
  },

  censorWord(_value: string, _displayChars: number = 2) {
    if (_value.length < 3) return _value;
    return (
      _value[0] +
      '*'.repeat(_value.length - _displayChars - 1) +
      _value.slice(-_displayChars)
    );
  },

  censorEmail(_email: string) {
    if (_email == undefined || _email == '') return '';

    var arr = _email.split('@');
    return this.censorWord(arr[0]) + '@' + this.censorWord(arr[1]);
  },

  async removeItemFromArray(
    _array: any[],
    _keyField: string,
    _keyValue: string
  ) {
    await _array.forEach((item, index) => {
      if (item[_keyField] == _keyValue) {
        _array.splice(index, 1);
      }
    });
    return _array;
  },

  isInDateArray(_array: any[], value: any) {
    if (value == undefined) {
      return false;
    }
    return !!_array.find((item) => {
      return item.getTime() == value.getTime();
    });
  },

  // getFromArray(_array: any[], value: any) {
  //   if (value == undefined) { return false }
  //   return _array.find(item => { return item });
  // },

  getDayofWeek(_date: any) {
    const date = new Date(_date);
    var weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    return weekday[date.getDay()];
  },

  async addToOptionsIfNew(
    _currentArray: IOptionType[],
    _newKey: string,
    _newValue: string
  ) {
    let isNew = true;

    await _currentArray.forEach((item) => {
      if (item.key == _newKey) {
        isNew = false;
        return;
      }
    });

    if (isNew && _newKey != '' && _newValue != '') {
      _currentArray.push({
        key: _newKey,
        description: _newValue,
        checked: false
      });
    }

    return _currentArray;
  },

  async setOptionsDefault(_currentArray: IOptionType[], _defaultKey: string) {
    await _currentArray.forEach((item) => {
      if (item.key == _defaultKey) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });

    return _currentArray;
  },

  async searchInArray(
    _currentArray: any[],
    _searchKey: string,
    _searchValue: string
  ) {
    let itemFound: any = undefined;

    await _currentArray.forEach((item) => {
      if (item[_searchKey] === _searchValue) {
        itemFound = item;
      }
    });

    return itemFound;
  },

  async updateSelectedItem(_currentArray: IOptionType[], _key: string) {
    let itemFound: any = undefined;

    await _currentArray.forEach((item) => {
      item.checked = false;

      if (item.key == _key) {
        item.checked = true;
      }
    });

    return _currentArray;
  },

  getObjectByKey(_object: any, _keyName: string) {
    let key;

    for (key in _object) {
      if (key == _keyName) {
        return _object[key];
      }
    }
  },

  formatCurrency(_value: any): any {
    return parseFloat(_value).toFixed(2);
  },

  formatShortDate(_date: any) {
    return moment(_date).format('DD-MMM');
  },

  formatHumanDate(_date: any) {
    return moment(_date).format('dddd D MMMM');
  }
};
