import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C5_orderBinFullPayment = {
  steps: 4, // used for the progress indicator on each screen

  // NOTE: NOT USED ANYMORE
  C5001: {
    id: appParam.routing.C5_orderBin.id + '001',
    progressTitle: 'Location',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 1,
    title: 'Where do we drop off the bin ?',
    primaryButtonCaption: 'Next',
    next: {
      personal: appParam.routing.C5_orderBin.C5003,
      charity: appParam.routing.C5_orderBin.C5003
    }
  },
  C5002: {
    id: appParam.routing.C5_orderBin.id + '002',
    progressTitle: 'Subscription',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 2,
    title: 'Your charity',
    subscription_type: {
      key: '',
      description: '',
      charityKey: '',
      taxInvoiceEmail: ''
    },
    primaryButtonCaption: 'Next',
    next: appParam.routing.C5_orderBin.C5003
  },

  C5003: {
    id: appParam.routing.C5_orderBin.id + '003',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / (C5_orderBinFullPayment.steps + 2)) * 2,
    title: 'When would you like us to deliver the bin?',
    primaryButtonCaption: 'Next',
    next: {
      premium: appParam.routing.C5_orderBin.C5007,
      basic: appParam.routing.C5_orderBin.C5009,
      charity: appParam.routing.C5_orderBin.C5009
    }
  },

  C5004: {
    id: appParam.routing.C5_orderBin.id + '004',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / (C5_orderBinFullPayment.steps + 2)) * 1,
    title: 'For additional capacity, select the number of bins required.',
    primaryButtonCaption: 'Next',
    next: {
      full: appParam.routing.C5_orderBin.C5003,
      part: appParam.routing.C5_orderBin.C5007
    }
  },
  C5005: {
    id: appParam.routing.C5_orderBin.id + '005',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / (C5_orderBinFullPayment.steps + 2)) * 5,
    title: 'Please enter your credit card details for your one-off payment.',
    payment_type: {
      key: '',
      description: ''
    },
    primaryButtonCaption: 'Next',
    next: appParam.routing.C5_orderBin.C5009
  },
  C5006: {
    // this steps is not used anymore since 005 and 006 were merged for better security
    id: appParam.routing.C5_orderBin.id + '006',
    progressTitle: 'Payment',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 6,
    title: 'Confirm your order details',
    payment_type: {
      key: '',
      description: ''
    },
    primaryButtonCaption: 'Pay now',
    next: appParam.routing.C5_orderBin.C5007
  },
  C5007: {
    id: appParam.routing.C5_orderBin.id + '007',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 1,
    title: 'Where would you like us to deposit the funds from your collection?',
    primaryButtonCaption: 'Next',
    next: {
      next: appParam.routing.C5_orderBin.C5007b,
      premium: appParam.routing.C5_orderBin.C5005,
      basic: appParam.routing.C5_orderBin.C5003
    }
  },
  C5007b: {
    id: appParam.routing.C5_orderBin.id + '007b',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / (C5_orderBinFullPayment.steps + 2)) * 2,
    title: 'Confirm your bank account details',
    primaryButtonCaption: 'Confirm',
    next: {
      next: appParam.routing.C5_orderBin.C5007b,
      premium: appParam.routing.C5_orderBin.C5005,
      basic: appParam.routing.C5_orderBin.C5003
    }
  },

  // NOTE: NOT USED ANYMORE
  C5008: {
    id: appParam.routing.C5_orderBin.id + '008',
    progressTitle: 'Order details',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 10,
    title: 'Confirm your order details',
    primaryButtonCaption: 'Place order',
    next: appParam.routing.C5_orderBin.C5009
  },
  C5009: {
    id: appParam.routing.C5_orderBin.id + '009',
    progressTitle: 'Request a bin',
    progressValue: () => (100 / C5_orderBinFullPayment.steps) * 4,
    title: 'Thanks for requesting a bin!',
    primaryButtonCaption: 'Done',
    next: appParam.routing.Userhome
  }
};
