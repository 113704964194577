<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="{{ this._style }}-text-title pt-4">Enter the code</div>
      <div class="{{ this._style }}-text-subtitle pt-2 pb-3">
        Please enter the six-digit code sent to your mobile phone
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col">
      <mat-form-field class="{{ this._style }}-form-field" appearance="fill">
        <input
          matInput
          value=""
          id="verificationCode"
          [(ngModel)]="this.verificationCode" />
      </mat-form-field>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col">
      <div class="{{ this._style }}-text-subtitle pt-2 pb-3">Resend code</div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
        (click)="validateCode()">
        Next
      </button>
    </div>
  </div>
</div>
