import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  IAddress,
  IOptionType,
  IOptionWithNumberType
} from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ItemPickerComponent } from 'src/app/Shared/item-picker/item-picker.component';

@Component({
  selector: 'app-uxc8001',
  templateUrl: './uxc8001.component.html',
  styleUrls: ['./uxc8001.component.scss']
})
export class Uxc8001Component implements OnInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  selectedAddress: IAddress = {
    address: '',
    suburb: '',
    lat: 0,
    lng: 0,
    postcode: 0
  };
  items: IOptionWithNumberType[];
  orders: any[] = [];

  selectedItem: IOptionWithNumberType = {
    key: '',
    description: '',
    binCode: '',
    checked: true,
    value: 0
  };
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };

  isItemChecked: boolean = false;

  @ViewChild('addressPicker') addpicker: ItemPickerComponent | undefined;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C8_requestPickup.C8001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    //  get all the bin orders
    let res = await this.appApi.getCustomerOrdersForPickupRequest();

    //  save the orders into the variable for usage later
    this.orders = res;

    // map the incoming data into Key/Description
    // TODO: change to suburb & change the order table to have suburb too
    this.items = res.map((item: any) => {
      return {
        key: item.id,
        description: item['address'],
        postcode: item['post_code'],
        //state: item.refCustomer.refRegion.refState.code,
        value: item['qty'],
        checked: false,
        binCode: item['refBin'] != null ? item['refBin']['code'] : ''
      };
    });

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getRequestPickupSummary();

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.appApi.getSubscriptionTypes(
      this.appService.UserObject.userType
    );
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
    }

    // get order type details and store in workflow object
    let resOrderType = await this.appApi.getOrderTypes(
      appParam.orderType.pickup
    );
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async addressPicked(event: IOptionType) {
    // 2021-10-17 CJ: logic update - Pickup request is per bin (cannot have multiple bins selected for a single pickup request)
    await this.items.forEach((item) => {
      item.checked = false;
    });

    // Mark selected pickup
    let item = await appUtils.searchInArray(this.items, 'key', event.key);
    item.checked = event.checked;
    this.isItemChecked = item.checked;

    await this.save();
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }

  async getPickedOrders() {
    let pickedOrders: any[] = [];

    // TODO: change to suburb
    await this.items.forEach(async (item) => {
      if (item.checked == true) {
        let order = await appUtils.searchInArray(this.orders, 'id', item.key);
        await pickedOrders.push({
          order_id: order.id,
          address: order.address,
          post_code: order.post_code,
          qty: order.qty,
          bin_code: order['refBin'] != null ? order['refBin']['code'] : ''
        });
      }
    });

    return pickedOrders;
  }
  async save() {
    const res = await this.getPickedOrders();

    if (res.length > 0) {
      await this.appService.updateWorkflowData({
        workflowId: this.workflowStep.id,
        workflowData: {
          subscription_type: {
            key: this.subscriptionType.key,
            description: this.subscriptionType.description
          },

          order_type: {
            key: this.orderType.key,
            description: this.orderType.description
          },

          picked_orders: res
        }
      });
    }
  }
}
