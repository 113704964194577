import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBSB } from 'src/app/helper/appInterfaces';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { alertAttributes } from '../../helper/appAlert';

@Component({
  selector: 'app-uxc1201',
  templateUrl: './uxc1201.component.html',
  styleUrls: ['./uxc1201.component.scss']
})
export class Uxc1201Component implements OnInit {
  workflowStep;
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  primaryButtonCaption: string;

  formGroup!: FormGroup;
  BSB: IBSB[] = [];
  bankName: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.appService.appSpinner(true);
    this.workflowStep = appWorkflowData.C12_updateBankDetails.C12001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;
    this.formGroup = this.formBuilder.group({
      accountName: ['', Validators.required],
      accountNumber: [
        '',
        [Validators.required, Validators.pattern(/^\d{0,9}$/)]
      ],
      accountBSB: [
        '',
        [Validators.required, Validators.pattern(/[0-9]{3}-[0-9]{3}/)]
      ],
      bankName: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
    this.formGroup.controls.accountBSB.valueChanges.subscribe((value) => {
      this.updateBankName(value);
    });
  }

  async getData() {
    this.BSB = await this.appApi.getBSB();
    this.appService.appSpinner(false);
  }

  async updateBankName(bsb: any) {
    let regex = /[0-9]{6}$/g;
    if (regex.test(bsb)) {
      this.formGroup.patchValue({
        accountBSB: bsb.substring(0, 3) + '-' + bsb.substring(3, 6)
      });
    }

    this.formGroup.patchValue({ bankName: null });
    if (bsb.length == 7) {
      const res = await appUtils.searchInArray(this.BSB, 'bsb', bsb);
      if (res) this.formGroup.patchValue({ bankName: await res.branchName });
    }
  }

  async next() {
    this.appService.appSpinner(true);
    let res = await this.appApi.updateCustomerAccount({
      accountName: this.formGroup.value.accountName,
      accountNumber: this.formGroup.value.accountNumber,
      bsb: this.formGroup.value.accountBSB
    });
    if (res) {
      this.appService.sendNotification({
        displayNotification: true,
        type: alertAttributes.types.success,
        message: 'Bank details successfully updated'
      });
      await this.router.navigate(['/' + this.workflowStep.next], {
        relativeTo: this.route
      });
    } else this.appService.appSpinner(false);
  }
}
