import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc10001',
  templateUrl: './uxc10001.component.html',
  styleUrls: ['./uxc10001.component.scss']
})
export class Uxc10001Component implements OnInit {
  _style: string = '';
  _dateFormat: string = appParam.defaultDateFormat;

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  items: any[];

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C10_orderStatus.C10001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    const res = await this.appApi.getCustomerOrders();

    this.items = res.map((item: any) => {
      return {
        id: item['id'],
        order_status: this.getOrderStatus(item),
        order_date: item['created_at'],
        bin_code: item['refBin'] != undefined ? item['refBin']['code'] : '',
        bin_id: item['refBin'] != undefined ? item['refBin']['id'] : ''
      };
    });

    this.appService.appSpinner(false);
  }

  getOrderStatus(order: any) {
    // Order is complete
    if (order['order_status'] == 'COMPLETE') return order['order_status'];

    // Order is in a manifest aka planned
    if (order['refManifestHeader'] != undefined) {
      if (
        order['refManifestHeader']['manifest_status'] ==
        appParam.manifestStatus.driverAssigned
      ) {
        return (
          order['refManifestHeader']['manifest_status'] +
          ' ' +
          appUtils.formatShortDate(order['refManifestHeader']['run_date'])
        );
      } else {
        return (
          'Planned ' +
          appUtils.formatShortDate(order['refManifestHeader']['run_date'])
        );
      }
    }

    return order['order_status'] != undefined
      ? order['order_status']
      : 'Pending';
  }

  async orderPicked(order: any) {
    this.router.navigate(['../' + appParam.routing.C10_orderStatus.C10002], {
      relativeTo: this.route,
      queryParams: { id: order['bin_id'], order_id: order['id'] }
    });
  }
}
