import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { AuthService } from 'src/app/services/auth.service';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-uxc1001b',
  templateUrl: './uxc1001b.component.html',
  styleUrls: ['./uxc1001b.component.scss']
})
export class Uxc1001bComponent implements OnInit {
  formGroup!: FormGroup;
  _style: string = '';
  screenConfig: any;

  constructor(
    public appService: AppService,
    public authService: AuthService,
    private appApi: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this._style = this.authService.getStyle();
    this.screenConfig = appWorkflowData.C1_signUp.C1001b;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        this.route.snapshot.queryParamMap.get('email'),
        Validators.required
      ],
      oldPassword: ['', Validators.required],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/
          )
        ]
      ],
      confirmPassword: ['', [Validators.required, this.passwordValidator()]]
    });
  }

  passwordValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (
        controls.value &&
        this.formGroup &&
        controls.value !== this.formGroup.value.password
      ) {
        return { match: true };
      } else return null;
    };
  }

  async resetPassword() {
    const res = await this.authService.initPassword(
      this.formGroup.value.email,
      this.formGroup.value.oldPassword,
      this.formGroup.value.password
    );
    if (res)
      await this.router.navigate(['/' + appParam.routing.C2_signIn.C2001], {
        relativeTo: this.route
      });
  }
}
