<mat-card class="mat-elevation-z3">
  <img
    mat-card-image
    src="./assets/logos/charity/{{ charity.icon }}"
    [alt]="charity.description" />
  <mat-card-header>
    <mat-card-title>{{ charity.description }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>{{ charity.body }}</mat-card-content>
</mat-card>
