<div fxLayout="column" fxLayoutGap="10" class="dc-container">
  <div fxFlex>
    <no-back-screen-header
      fxFlex
      [_headerTitle]="screenConfig.title"
      [_progressTitle]="screenConfig.progressTitle"
      [_progressValue]="screenConfig.progressValue()"
      [_cancelRoute]="screenConfig.cancel">
    </no-back-screen-header>
  </div>
  <div fxFlex class="mat-h3">You have successfully created an account.</div>

  <!-- <div fxFlex class="mat-h3 mt-2">
    There are just two steps to go:<br>
    <b>Step 1: </b>Check your inbox and click the verification link to verify your email.<br>
    <b>Step 2: </b>Sign-in and order your first bin.
  </div> -->

  <!-- <div fxFlex [style.paddingTop.px]="10" class="mat-subheading-1">
    A verification email has been sent to:
    <b>{{ this._email }}</b>
  </div> -->

  <!-- <a fxFlex [routerLink]="" (click)="resendSignUp()">Resend verification email</a> -->

  <!-- <div fxFlex class="mat-h4 mt-3">
    <b>Help?</b><br>
      <li>
        Your email should arrive in a few minutes from <a>donotreply@direct-collect.com.au</a>.
      </li>
      <li>
        Check your spam or junk folder in case it got lost.
      </li>
  </div> -->

  <div fxFlex class="mt-5" *ngIf="_outOfScheme">
    <mat-card class="shadow-sm dc-card">
      <mat-card-content>
        <mat-card-title
          >Hold tight! We'll be hitting your streets soon.</mat-card-title
        >
        <br />
        <mat-card-title style="font-size: 1rem">
          We will be in touch as soon as Direct Collect bins are available for
          drop off in your area, so keep an eye out for email notifications.
        </mat-card-title>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayout="column">
    <button
      mat-raised-button
      data-cy="next"
      class="mt-4"
      color="primary"
      (click)="next()">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>
</div>
