import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

import { alertAttributes } from 'src/app/helper/appAlert';
import { Router, ActivatedRoute } from '@angular/router';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appWorkflowData } from '../../helper/appWorkflowData';
import { appParam } from '../../helper/appSettings';

@Component({
  selector: 'app-uxc4002',
  templateUrl: './uxc4002.component.html',
  styleUrls: ['./uxc4002.component.scss']
})
export class Uxc4002Component implements OnInit, OnDestroy {
  _style: string = '';
  errHandler: appErrorHandler;
  screenConfig: any;
  primarySub: Subscription | undefined;

  public verificationCode: string = '';
  public email: string = '';

  constructor(
    public authService: AuthService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this.errHandler = new appErrorHandler(this.appService);
    this.screenConfig = appWorkflowData.C4_forgotPassword.C4002;
  }

  ngOnInit() {
    setTimeout(() => {
      this.primarySub = this.appService.currentAppNotification.subscribe(
        (notification) => {
          if (
            notification.workflow ===
            alertAttributes.workflowSteps.C4_forgotPassword.C4001
          ) {
            this.email = notification.body.email;
          }
        }
      );
    });
  }

  async ngOnDestroy() {
    if (this.primarySub) {
      await this.primarySub.unsubscribe();
    }
  }

  async resetPassword() {
    // const res = await this.authService.resetPassword(this.email, this.verificationCode, this.newPassword1);
    // if (!res) return;

    this.appService.sendNotification({
      workflow: appParam.routing.C4_forgotPassword.C4002,
      body: {
        email: this.email,
        verification: this.verificationCode
      },
      displayNotification: false
    });

    await this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }

  async resendCode() {
    const res = await this.authService.forgotPassword(this.email);
    if (!res) return;

    await this.appService.sendNotification({
      type: alertAttributes.types.success,
      message: 'Verification code resent!',
      displayNotification: true
    });
  }
}
