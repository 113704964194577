import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { appParam } from '../../helper/appSettings';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';

import { environment } from '../../../environments/environment';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { PrivacyPolicyComponent } from '../../Shared/privacy-policy/privacy-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { IUserAttr } from '../../helper/appInterfaces';

@Component({
  selector: 'app-uxc1-001',
  templateUrl: './uxc1001.component.html',
  styleUrls: ['./uxc1001.component.scss']
})
export class Uxc1001Component implements OnInit {
  _currentApplicationVersion = environment.appVersion;
  errHandler: appErrorHandler;

  _style = '';

  // screen config data related to the workflow step
  screenConfig: any;

  password = '';
  hide: boolean = true;
  public linkSignIn = appParam.routing.C2_signIn.C2001;

  userType = '';
  charityCode: any;
  formGroup!: FormGroup;

  constructor(
    public authService: AuthService,
    public appService: AppService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();

    this.errHandler = new appErrorHandler(this.appService);

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1001;
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobile: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^04\d{8}$|^\+61\d{9}$|^04\d{2}\s\d{3}\s\d{3}$|^\+61\s\d{3}\s\d{3}\s\d{3}/
          ),
          this.mobileValidator()
        ]
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/
          )
        ]
      ],
      confirmPassword: ['', [Validators.required, this.passwordValidator()]],
      agreed: [false, Validators.requiredTrue]
    });

    this.charityCode = null;
    if (
      this.route.snapshot.queryParams.signup == appParam.routing.signUpType.cash
    ) {
      this.userType = appParam.routing.signUpType.cash;
    } else if (
      this.route.snapshot.queryParams.signup ==
      appParam.routing.signUpType.charity
    ) {
      this.userType = appParam.routing.signUpType.charity;
    } else if (
      this.route.snapshot.queryParams.signup ==
      appParam.routing.signUpType.external.vinnies
    ) {
      this.userType = appParam.routing.signUpType.charity;
      this.charityCode = appParam.routing.signUpType.external.vinnies;
    } else if (
      this.route.snapshot.queryParams.signup ==
      appParam.routing.signUpType.external.sammyd
    ) {
      this.userType = appParam.routing.signUpType.charity;
      this.charityCode = appParam.routing.signUpType.external.sammyd;
    } else {
      this.userType = appParam.routing.signUpType.cash;
    }
  }

  openPrivacyPolicy(): void {
    // this.dialog.open(PrivacyPolicyComponent),
    //   {
    //     width: '300px'
    //   };
    window.open('https://direct-collect.com.au/privacy-policy', '_blank');
  }

  passwordValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value && this.formGroup) {
        if (controls.value !== this.formGroup.value.password) {
          return { match: true };
        }
      }
      return null;
    };
  }

  mobileValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value) {
        const value = controls.value.replace(/\D/gi, '');
        if (value.length < 10 || value.length > 12) {
          return { format: true };
        }
      }
      return null;
    };
  }

  public async next() {
    /**
     * Ensure we store the signing up users' userId so we can send and resend
     * the verification code to them.
     */
    this.appService.UserObject.userId = this.formGroup.value.email
      .toLowerCase()
      .trim();

    /** Save the phone number so we can  */
    this.appService.UserObject.mobile = this.formatMobile(
      this.formGroup.value.mobile
    );

    try {
      this.appService.appSpinner(true);

      const user = {
        userType: this.userType,
        charityCode: this.charityCode,
        userId: this.formGroup.value.email.toLowerCase().trim(),
        password: this.formGroup.value.password,
        email: this.formGroup.value.email.toLowerCase().trim(),
        mobile: this.formatMobile(this.formGroup.value.mobile),
        firstName: this.formGroup.value.firstName.trim(),
        lastName: this.formGroup.value.lastName.trim(),
        privacyPolicyAccepted: new Date().toISOString()
      };

      //  create user in cognito
      const resAuth = await this.authService.signUp(user);
      if (resAuth == false) {
        this.appService.appSpinner(false);
        return;
      }

      // create user in backend database
      user.password = ''; // password is not stored anywhere except in Cognito
      const resSetupCustomer = await this.appApi.createCustomer(user);

      // send an application wide notification with the new user details
      this.appService.sendNotification({
        workflow: alertAttributes.workflowSteps.C1_signUp.C1001,
        body: user,
        displayNotification: false
      });

      // if all tasks above is successful, navigate to next screen
      this.router
        .navigate(['/' + this.screenConfig.next], {
          relativeTo: this.route
        })
        .finally(() => this.appService.appSpinner(false));
    } catch (e: any) {
      this.appService.appSpinner(false);
      this.errHandler.handleC1Errors({
        type: alertAttributes.types.error,
        errorObject: e,
        displayNotification: true,
        displayMessage: e.message
      });
    }
  }

  formatMobile(mobile: any) {
    mobile = mobile.trim();
    if (mobile.startsWith('+')) {
      mobile = mobile.replace(/\D/gi, '');
      if (mobile.length > 11) {
        mobile = '+' + mobile.replace('0', '');
      } else {
        mobile = '+' + mobile;
      }
    } else {
      mobile = mobile.replace(/\D/gi, '');
      if (mobile.length < 10) {
        mobile = '+61' + mobile;
      } else if (mobile.length < 11) {
        mobile = '+61' + mobile.slice(1);
      } else {
        mobile = '+' + mobile;
      }
    }
    return mobile;
  }
}
