<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxLayout="row" *ngIf="this.items.length == 0">
    <mat-divider fxFlex class="mb-2"></mat-divider>
  </div>
  <div
    fxFlex
    class="mat-h3"
    fxLayoutAlign="center center"
    *ngIf="this.items.length == 0">
    You have no processed bins yet!
  </div>

  <table fxFlex class="table table-striped" *ngIf="this.items.length > 0">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Code</th>
        <th scope="col">Activity</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of items" class="p-2 my-1 order">
        <td>{{ item['bin_date'] }}</td>
        <td>{{ item['bin_code'] }}</td>
        <td>{{ item['bin_status'] }}</td>
      </tr>
    </tbody>
  </table>
</div>
