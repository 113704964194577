<div fxLayout="column" fxLayoutGap="10" class="dc-container">
    <screen-header
            fxFlex
            [_headerTitle]="screenConfig.title"
            [_progressTitle]="screenConfig.progressTitle"
            [_progressValue]="screenConfig.progressValue()"
            [_cancelRoute]="screenConfig.cancel">
    </screen-header>

    <ng-container *ngIf="render">
        <mat-card class="mb-4">
            <mat-card-header>
                <img
                        *ngIf="workflowData.charity.icon !== null"
                        mat-card-avatar
                        class="header-image"
                        src="{{ './assets/logos/charity/' + workflowData.charity.icon }}"/>
                <mat-card-title>{{ workflowData.charity.description }}</mat-card-title>
            </mat-card-header>
            <mat-card-content [style.overflow]="'auto'">
                {{ workflowData.charity.body }}
            </mat-card-content>
        </mat-card>
        <div fxLayout="column" class="mb-2">
            <span fxFlex class="dc-primary-variant mat-h3 mb-0">Tax invoice email</span>
            <span fxFlex class="dc-primary mat-h4">{{
                workflowData.taxInvoiceEmail
                }}</span>
        </div>
    </ng-container>


    <button mat-raised-button color="primary" (click)="next()">
        {{ screenConfig.primaryButtonCaption }}
    </button>
</div>
