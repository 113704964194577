<mat-card class="mat-elevation-z0 p-0">
  <mat-card-header>
    <mat-card-title>{{ data.description }}</mat-card-title>
  </mat-card-header>
  <mat-card-content [style.overflow]="'auto'" [style.height.px]="'150'">{{
    data.body
  }}</mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="onCloseClick()">Close</button>
  </mat-card-actions>
</mat-card>
