import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { appWorkflowData } from '../../helper/appWorkflowData';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-uxc1101',
  templateUrl: './uxc1101.component.html',
  styleUrls: ['./uxc1101.component.scss']
})
export class Uxc1101Component implements OnInit {
  screenConfig: any;
  formGroup!: FormGroup;
  hide: boolean = true;

  constructor(
    public authService: AuthService,
    public appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.screenConfig = appWorkflowData.C11_changePassword.C11001;
  }

  ngOnInit(): void {
    let pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;
    this.formGroup = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern(pattern)]],
      confirmNewPassword: ['', [Validators.required, this.passwordValidator()]]
    });
  }

  passwordValidator(): ValidatorFn {
    return (controls: AbstractControl) => {
      if (controls.value && this.formGroup) {
        if (controls.value !== this.formGroup.value.newPassword) {
          return { match: true };
        }
      }
      return null;
    };
  }

  async next() {
    this.appService.appSpinner(true);
    let result = await this.authService.changePassword(
      this.formGroup.value.oldPassword,
      this.formGroup.value.newPassword
    );
    if (result)
      await this.router.navigate(['/' + this.screenConfig.next], {
        relativeTo: this.route
      });
    this.appService.appSpinner(false);
  }
}
