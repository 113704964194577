import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc10002',
  templateUrl: './uxc10002.component.html',
  styleUrls: ['./uxc10002.component.scss']
})
export class Uxc10002Component implements OnInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  _images: any[] = [];

  id: any;
  order_id: any;

  order: any;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C10_orderStatus.C10002;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.order_id = this.route.snapshot.queryParamMap.get('order_id');
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    const res = await this.appApi.getCDSDetailsByBin(this.id);

    if (res.length > 0) {
      this.order = res[0];
    }

    try {
      const manifestMedia = await this.appApi.getManifestMedia(this.order_id);

      for (let image of manifestMedia) {
        this._images.push(image['picture']);
      }
      this.appService.appSpinner(false);
    } catch (err) {
      console.log(err);
    }
  }
}
