import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc3003',
  templateUrl: './uxc3003.component.html',
  styleUrls: ['./uxc3003.component.scss']
})
export class Uxc3003Component implements OnInit {
  _style: string = '';

  constructor(public appService: AppService, private authService: AuthService) {
    this._style = this.authService.getStyle();
  }

  ngOnInit(): void {}

  async next() {}
}
