<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex class="mt-2">
    <app-date-picker
      [_availableDate]="this.availableDates"
      [_selectedDate]="this.selectedDate"
      (mDatePicked)="datePicked($event)">
    </app-date-picker>
  </div>

  <div fxFlex class="mat-h4 mt-4">
    Do you have any notes for the delivery driver? Unit or Flat number? Gates,
    dogs, etc.<br />
    Tell us a safe place to leave the bin.
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Notes</mat-label>
    <textarea
      matInput
      style="resize: none"
      [(ngModel)]="_customerNotes"></textarea>
  </mat-form-field>

  <div fxLayout="column">
    <button
      mat-raised-button
      class="mt-2"
      color="primary"
      (click)="next()"
      id="primaryButton"
      [disabled]="primaryButtonDisabled">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
