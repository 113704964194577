import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ItemPickerComponent } from 'src/app/Shared/item-picker/item-picker.component';

@Component({
  selector: 'app-uxc9001',
  templateUrl: './uxc9001.component.html',
  styleUrls: ['./uxc9001.component.scss']
})
export class Uxc9001Component {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  items: IOptionType[];

  selectedItem!: IOptionType;

  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };

  @ViewChild('addressPicker') addpicker: ItemPickerComponent | undefined;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C9_requestPickupChange.C9001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
    this.getData();
  }

  async getData() {
    //  get all the bin orders
    let res = await this.appApi.getCustomerPickupRequests(true);
    let orders = await this.appApi.getCustomerOrders();

    // map the incoming data into Key/Description
    if (res.length > 0) {
      this.items = res.map((item: any) => {
        return {
          key: item.id,
          description: 'Pick up request scheduled for',
          body:
            appUtils.getDayofWeek(item['selected_date']) +
            ', ' +
            formatDate(item['selected_date'], 'dd MMM', 'en-US'),
          checked: false,
          code: formatDate(item['selected_date'], 'yyyy-MM-dd', 'en-US'),
          address: item.address
        };
      });
    } else {
      orders.forEach((order) => {
        if (order.order_status == null) {
          this.items = orders.map((order: any) => {
            return {
              key: order.id,
              description: 'Drop off request scheduled for',
              body:
                appUtils.getDayofWeek(order['selected_date']) +
                ', ' +
                formatDate(order['selected_date'], 'dd MMM', 'en-US'),
              checked: false,
              code: formatDate(order['selected_date'], 'yyyy-MM-dd', 'en-US'),
              address: order.address
            };
          });
        }
      });
    }

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getRequestPickupSummary();

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.appApi.getSubscriptionTypes(
      this.appService.UserObject.userType
    );
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
    }

    // get order type details and store in workflow object
    let resOrderType = await this.appApi.getOrderTypes(
      appParam.orderType.pickup
    );
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async requestPicked(event: IOptionType) {
    this.selectedItem = await appUtils.searchInArray(
      this.items,
      'key',
      event.key
    );
    await this.save();
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }

  async save() {
    await this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        selected_date: {
          key: this.selectedItem.code,
          description: this.subscriptionType.body
        },
        picked_request: this.selectedItem
      }
    });
  }
}
