<div fxLayout="column" fxLayoutGap="10" class="dc-container">
  <no-back-screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </no-back-screen-header>

  <div fxFlex class="mt-4">
    <app-item-picker
      [_items]="this.items"
      (itemPicked)="itemPicked($event)"></app-item-picker>
  </div>

  <!--  <div fxFlex class="d-flex flex-column">-->
  <!--    THIS IS THE PLAN WITH THE POPUP AVAILABLE CODE -->

  <!--    <mat-card *ngFor="let item of this.items" class="mat-elevation-z8 m-2" (click)="onItemClick(item)">-->
  <!--      <mat-card-header>-->
  <!--        <mat-card-title>{{ item.description }}</mat-card-title>-->
  <!--      </mat-card-header>-->
  <!--      <mat-card-content>-->
  <!--        {{ item.body }}-->
  <!--      </mat-card-content>-->
  <!--      <mat-divider inset></mat-divider>-->
  <!--      <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">-->
  <!--        <button mat-button (click)="openDialog(item)">Info</button>-->
  <!--        <div fxFlex="60"></div>-->
  <!--        <mat-radio-button [checked]="item === selectedItem" color="primary"> </mat-radio-button>-->
  <!--      </mat-card-actions>-->
  <!--    </mat-card>-->
  <div fxFlex [style.paddingTop.px]="15">
    <mat-checkbox fxFlex [(ngModel)]="checked" color="primary">
      I accept the
      <a
        [ngClass]="{ 'router-link-active': true }"
        (click)="openTermsAndConditions()"
        >Terms & Conditions</a
      >
    </mat-checkbox>
  </div>
  <!--  </div>-->

  <div fxLayout="column">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!checked"
      (click)="next()"
      class="mt-4">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>
</div>
