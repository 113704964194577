<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex class="mt-2">
    <app-item-picker
      [_items]="this.items"
      (itemPicked)="requestPicked($event)"
      #addressPicker>
    </app-item-picker>
  </div>

  <button
    fxFlex
    mat-raised-button
    class="mt-4"
    color="primary"
    (click)="next()"
    [disabled]="!selectedItem">
    {{ primaryButtonCaption }}
  </button>
</div>
