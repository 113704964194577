import { appParam } from '../appSettings';

export const C11_changePassword = {
  steps: 1,
  C11001: {
    id: appParam.routing.C11_changePassword.id + '001',
    progressTitle: '',
    progressValue: () => 100,
    title: 'Update your password',
    primaryButtonCaption: 'Update',
    next: appParam.routing.Userhome,
    cancel: appParam.routing.Userhome
  }
};
