import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc9003',
  templateUrl: './uxc9003.component.html',
  styleUrls: ['./uxc9003.component.scss']
})
export class Uxc9003Component {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  workflowData: any;
  orderResult;

  selectedDate: any;
  mobile: string = '';
  address: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C9_requestPickupChange.C9003;

    this.orderResult = appWorkflowData.OrderResult;
    this.address = this.orderResult.address;

    this.mobile = appUtils.censorWord(appService.UserObject.mobile, 3);

    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.getData();
  }

  async getData() {
    // logic for when user hits back button to preserve values selected previously
    this.workflowData = await this.appService.getRequestPickupChangeSummary();

    // formatting of date for display on page
    if (this.orderResult.selected_date != '') {
      let dt = new Date(this.orderResult.selected_date);
      this.selectedDate = appUtils.getDayofWeek(dt);
      this.selectedDate =
        this.selectedDate + ' ' + formatDate(dt, 'dd MMM', 'en-US');
    }

    let textMessage =
      'Hello ' +
      this.appService.UserObject.firstName +
      ', your bin order date has been changed to ' +
      this.selectedDate +
      ', thank you!';
    if (!this.workflowData.selectedDateSame) {
      try {
        const res = await this.appApi.updateCustomerPickupRequest(
          this.workflowData.picked_request.key,
          appWorkflowData.OrderResult.selected_date,
          this.workflowData.picked_request.bins_to_drop_off,
          this.workflowData.picked_request.bins_to_pick_up,
          this.workflowData.orderStatus,
          this.workflowData.picked_request.notFirstOrder
        );
      } catch (err) {
        console.log(err);
      }
    }
    try {
      const resSMS = await this.appApi.sendSMS(
        textMessage,
        this.appService.UserObject.mobile
      );
    } catch (err) {
      console.log(err);
    }
    this.appService.appSpinner(false);
  }

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }
}
