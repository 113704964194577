import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICustomerOrder, IOptionType } from 'src/app/helper/appInterfaces';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc7003',
  templateUrl: './uxc7003.component.html',
  styleUrls: ['./uxc7003.component.scss']
})
export class Uxc7003Component {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  primaryButtonDisabled: boolean = true;
  selectedItem: IOptionType = { key: '', description: '', checked: false };
  workflowData: any;
  orderSummary;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this.workflowStep = appWorkflowData.C7_orderBinCharity.C7003;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;
    this.orderSummary = appWorkflowData.OrderSummary;
    this.getData();
    this.appService.appSpinner(false);
  }

  getData() {
    this.selectedItem = this.orderSummary.charity.charityObject as IOptionType;
  }

  async next() {
    this.appService.appSpinner(true);

    let date = new Date(this.workflowData.selected_date.key);

    let order: ICustomerOrder = {
      customerId: this.appService.UserObject.userId,
      selectedDate: this.orderSummary.selected_date.key,
      bins_to_drop_off: this.orderSummary.qty
    } as ICustomerOrder;

    const res = await this.appApi.createCustomerOrder(order);

    if (res['id'] != undefined) {
      //  pass the order summary for the common summary screen
      appWorkflowData.OrderResult.selected_date =
        this.orderSummary.selected_date.key;
      appWorkflowData.OrderResult.address =
        this.orderSummary.address.street_address;

      this.router.navigate(['/' + this.workflowStep.next], {
        relativeTo: this.route
      });
    } else {
      this.appService.appSpinner(false);
    }
  }
}
