<form
  [formGroup]="formGroup"
  fxLayout="column"
  fxLayoutGap="15px"
  class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <mat-form-field appearance="fill">
    <mat-label>Charities</mat-label>
    <mat-select [(value)]="selectedCharity">
      <mat-option></mat-option>
      <mat-option [value]="charity" *ngFor="let charity of charities">{{
        charity.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <h3><b>OR</b></h3>
  </div>

  <mat-form-field appearance="fill">
    <mat-label>Clubs</mat-label>
    <mat-select [(value)]="selectedCharity">
      <mat-option></mat-option>
      <mat-option [value]="club" *ngFor="let club of clubs">{{
        club.description
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!--  <div class="d-flex flex-wrap" style="justify-content: center">-->
  <!--    <mat-card *ngFor="let charity of charities" class="mat-elevation-z8 m-2" (click)="onCharityClick(charity)">-->
  <!--      <img mat-card-image src="{{ './assets/logos/charity/' + charity.icon }}" alt="" />-->
  <!--      <mat-divider inset></mat-divider>-->
  <!--      <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">-->
  <!--        <button fxFlex mat-button (click)="openDialog(charity)">Info</button>-->
  <!--        <div fxFlex="60"></div>-->
  <!--        <mat-radio-button fxFlex [checked]="charity === selectedCharity" color="primary"> </mat-radio-button>-->
  <!--      </mat-card-actions>-->
  <!--    </mat-card>-->
  <!--  </div>-->

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Tax invoice email address</mat-label>
    <input
      matInput
      data-cy="email"
      type="email"
      id="taxInvoiceEmail"
      formControlName="taxInvoiceEmail" />
    <mat-error *ngIf="this.formGroup.controls.taxInvoiceEmail.errors?.required"
      >required</mat-error
    >
    <mat-error *ngIf="this.formGroup.controls.taxInvoiceEmail.errors?.email"
      >invalid format</mat-error
    >
  </mat-form-field>

  <div fxLayout="column">
    <button
      mat-raised-button
      color="primary"
      [disabled]="formGroup.status != 'VALID' || !selectedCharity?.key"
      (click)="next()">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>
</form>
