import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBSB } from 'src/app/helper/appInterfaces';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appParam } from '../../helper/appSettings';
import { resolve } from 'path';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { alertAttributes } from 'src/app/helper/appAlert';

@Component({
  selector: 'app-uxc5007',
  templateUrl: './uxc5007.component.html',
  styleUrls: ['./uxc5007.component.scss']
})
export class Uxc5007Component implements OnInit {
  errHandler: appErrorHandler;
  _style: string = '';

  BSB: IBSB[] = [];

  bankName: string = '';

  isConfirmationScreen: boolean = false;
  isReadOnly: boolean = false;

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  formGroup!: FormGroup;
  customerType: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this._style = this.authService.getStyle();
    this.errHandler = new appErrorHandler(this.appService);

    // check if the screen is the Confirmation screen and then make fields read-only by default
    this.isConfirmationScreen = this.router.url.indexOf('confirm') > 0;
    if (this.isConfirmationScreen) {
      this.isReadOnly = true;
      this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5007b;
    } else {
      this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5007;
    }

    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.getData();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      accountName: ['', Validators.required],
      accountNumber: [
        '',
        [Validators.required, Validators.pattern(/^\d{0,9}$/)]
      ],
      accountBSB: [
        '',
        [Validators.required, Validators.pattern(/[0-9]{3}-[0-9]{3}/)]
      ],
      bankName: ['', Validators.required]
    });
    this.formGroup.controls.accountBSB.valueChanges.subscribe((value) => {
      this.updateBankName(value);
    });
  }

  async getData() {
    let resCustomer = await this.appApi.getCustomerByEmail(
      await this.authService.getCurrentUserId()
    );
    this.customerType = resCustomer.customerType;

    if (this.customerType == appParam.userType.premium) {
      this.progressValue *= 2;
    }

    this.BSB = await this.appApi.getBSB();

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getOrderSummary();

    if (
      workflowData.payment_details.bsb != '' ||
      workflowData.payment_details.account_name != '' ||
      workflowData.payment_details.account_number != ''
    ) {
      this.formGroup.patchValue({
        accountBSB: workflowData.payment_details.bsb,
        accountName: workflowData.payment_details.account_name,
        accountNumber: workflowData.payment_details.account_number
      });
      this.updateBankName(workflowData.payment_details.bsb);
    }

    this.appService.appSpinner(false);
  }

  async updateBankName(_bsb: any) {
    let regex = /[0-9]{6}$/g;
    if (regex.test(_bsb)) {
      this.formGroup.patchValue({
        accountBSB: _bsb.substring(0, 3) + '-' + _bsb.substring(3, 6)
      });
    }

    if (_bsb.length == 7) {
      const res = await appUtils.searchInArray(this.BSB, 'bsb', _bsb);
      if (res) {
        this.formGroup.patchValue({
          bankName: res.bankCode + ' - ' + res.state
        });
        await this.save();
      } else this.formGroup.patchValue({ bankName: null });
    } else this.formGroup.patchValue({ bankName: null });
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();

    if (this.isConfirmationScreen) {
      try {
        //add customer account details to the database
        await this.appApi.updateCustomerAccount({
          accountName: this.formGroup.value.accountName,
          accountNumber: this.formGroup.value.accountNumber,
          bsb: this.formGroup.value.accountBSB
        });
        try {
          let gotCustomerAccountDetails =
            await this.appApi.getCustomerHasAccountBoolean();
          //if customer details have successfully been added to the database then move to the next page, else, stay on the confirmation page.
          if (gotCustomerAccountDetails) {
            if (this.customerType == appParam.userType.premium) {
              this.router.navigate(['/' + this.workflowStep.next.premium], {
                relativeTo: this.route
              });
            } else {
              this.router.navigate(['/' + this.workflowStep.next.basic], {
                relativeTo: this.route
              });
            }
          } else {
            console.log('next page', this.workflowStep.next.next);
            this.router.navigate(['/' + this.workflowStep.next.next], {
              relativeTo: this.route
            });
            this.errHandler.handleAPIErrors({
              type: alertAttributes.types.error,
              displayMessage: alertAttributes.alerts.C038,
              displayNotification: true,
              errorObject: {}
            });
            this.getData();
          }
        } catch (err) {
          console.log(err);
          this.router.navigate(['/' + this.workflowStep.next.next], {
            relativeTo: this.route
          });
          this.getData();
        }
      } catch (err) {
        console.log(err);
        this.router.navigate(['/' + this.workflowStep.next.next], {
          relativeTo: this.route
        });
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C030,
          displayNotification: true,
          errorObject: {}
        });
        this.getData();
      }
    } else {
      this.router.navigate(['/' + this.workflowStep.next.next], {
        relativeTo: this.route
      });
      this.getData();
    }
  }

  async save() {
    console.log('triggering save function in uxc5007');

    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        payment_details: {
          bsb: this.formGroup.value.accountBSB,
          account_name: this.formGroup.value.accountName,
          account_number: this.formGroup.value.accountNumber,
          bank_name: this.formGroup.value.bankName
        }
      }
    });
  }
}
