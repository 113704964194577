<div class="dc-container">
  <div class="row">
    <div class="col-1"></div>
    <div class="col pt-4">
      <div class="{{ this._style }}-text-title">Provide your location</div>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col pt-3 pb-4">
      <div class="{{ this._style }}-text-subtitle">
        Where will your bin(s) be kept
      </div>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col">
      <mat-form-field class="{{ this._style }}-form-field" appearance="fill">
        <input
          matInput
          value=""
          id="binLocation"
          placeholder="bin location"
          value="{{ this.address }}"
          readonly />
      </mat-form-field>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col pt-3 d-flex justify-content-center">
      <div class="{{ this._style }}-text-subtitle">
        Is this a residential or business address?
      </div>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col">
      <!-- <app-address-type-picker (onAdddressTypePicked)="itemPicked($event)"></app-address-type-picker> -->

      <app-item-picker
        [_items]="this.items"
        (itemPicked)="itemPicked($event)"></app-item-picker>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
        (click)="next()">
        Next
      </button>
    </div>
  </div>
</div>
