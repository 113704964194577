import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ICustomerOrder } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc5008',
  templateUrl: './uxc5008.component.html',
  styleUrls: ['./uxc5008.component.scss']
})
export class Uxc5008Component implements OnInit {
  _style: string = '';

  accountName: string = '';
  accountNumber: string = '';
  bankName: string = '';

  subscription_type: string = '';
  payment_details: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  workflowData: any;
  orderSummary;

  censoredAccountNumber: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5008;

    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.orderSummary = appWorkflowData.OrderSummary;

    this.appService.appSpinner(true);
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    // logic for when user hits back button to preserve values selected previously
    this.workflowData = await this.appService.getOrderSummary();

    if (this.workflowData.payment_details.account_number != '') {
      this.accountName = this.workflowData.payment_details.account_name;
      this.accountNumber = this.workflowData.payment_details.account_number;
      this.bankName = this.workflowData.payment_details.bank_name;

      this.subscription_type = this.workflowData.subscription_type.description;
      this.payment_details = this.workflowData.payment_type.description;
    }

    this.censoredAccountNumber = appUtils.censorWord(
      this.orderSummary.payment_details.account_number,
      2
    );
    this.appService.appSpinner(false);
  }

  async next() {
    this.appService.appSpinner(true);

    let order: ICustomerOrder = {
      customerId: this.appService.UserObject.userId,
      selectedDate: this.workflowData.selected_date.key,
      accountName: this.workflowData.payment_details.account_name,
      accountNumber: this.workflowData.payment_details.account_number,
      bsb: this.workflowData.payment_details.bsb,
      bins_to_drop_off: this.workflowData.qty
    } as ICustomerOrder;

    // let paymentTypes = await this.appApi.getPaymentTypes();
    // if (this.authService.getUserType() == appParam.userType.premium) {
    //   for (let item of paymentTypes) {
    //     if (item.description == 'Pay the full amount now') {
    //       order.paymentTypeId = item.key;
    //     }
    //   }
    // } else {
    //   for (let item of paymentTypes) {
    //     if (item.description != 'Pay the full amount now') {
    //       order.paymentTypeId = item.key;
    //     }
    //   }
    // }

    const res = await this.appApi.createCustomerOrder(order);

    if (res['id'] != undefined) {
      //  pass the order summary for the common summary screen
      appWorkflowData.OrderResult.selected_date =
        this.orderSummary.selected_date.key;
      appWorkflowData.OrderResult.address =
        this.orderSummary.address.street_address;

      // this.appService.updateWorkflowData({
      //   workflowId: this.workflowStep.id, workflowData: res
      // })

      this.router.navigate(['/' + this.workflowStep.next], {
        relativeTo: this.route
      });
    } else {
      this.appService.appSpinner(false);
    }
  }
}
