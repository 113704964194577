<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <no-back-screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </no-back-screen-header>

  <!--  <div fxFlex>Pick up locations :</div>-->
  <!--  <div fxFlex>-->
  <!--    <app-item-and-number-picker [_items]="this.items" [_readonly]="true"> </app-item-and-number-picker>-->
  <!--  </div>-->

  <div fxFlex>
    New collection day: <b>{{ this.selectedDay }}</b>
  </div>

  <div fxFlex class="mt-4">
    <app-date-picker
      [_availableDate]="this.availableDates"
      [_selectedDate]="this.selectedDate"
      (mDatePicked)="datePicked($event)"></app-date-picker>
  </div>

  <div fxFlex class="mat-h4 mt-4">
    Do you have any notes for the delivery driver? Unit or Flat number? Gates,
    dogs, etc.<br />
    Tell us a safe place to leave the bin.
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Notes</mat-label>
    <textarea
      matInput
      style="resize: none"
      [(ngModel)]="_customerNotes"></textarea>
  </mat-form-field>

  <div fxLayout="column">
    <button
      mat-raised-button
      class="mt-2"
      color="primary"
      [disabled]="!_canChange || _btnDisabled"
      (click)="next()"
      id="primaryButton">
      {{ primaryButtonCaption }}
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!this._firstOrder">
    <mat-divider fxFlex></mat-divider>
    <div fxFlex="10" class="text-center">or</div>
    <mat-divider fxFlex> </mat-divider>
  </div>

  <div fxLayout="column">
    <button
      [disabled]="!this._canChange"
      mat-raised-button
      color="warn"
      (click)="cancelRequest()"
      id="cancelButton"
      class="mt-2"
      *ngIf="!this._firstOrder">
      Cancel pick up
    </button>
  </div>

  <div fxFlex *ngIf="!this._canChange" class="mat-error">
    <b
      >This pickup request has been assigned to our runsheet and cannot be
      changed!</b
    >
  </div>
</div>
