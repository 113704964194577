<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex class="mt-4">
    <app-display-charity [charity]="this.selectedItem"></app-display-charity>
  </div>

  <div fxFlex class="text-center">Your tax invoice will be sent to</div>
  <div fxFlex class="text-center">{{ this.taxInvoiceEmail }}</div>

  <button
    fxFlex
    mat-raised-button
    class="mt-2"
    color="primary"
    (click)="next()">
    {{ primaryButtonCaption }}
  </button>
</div>
