<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="byc-radio-group"
  labelPosition="before">
  <mat-radio-button
    class="byc-radio-button-small d-block"
    *ngFor="let item of this._items; let i = index"
    [value]="item.key"
    [checked]="item.checked"
    data-cy="itemPicker"
    (change)="itemSelected($event)">
    <span class="mat-h4" [ngClass]="{ 'dc-primary-variant': !item.checked }">{{
      item.description
    }}</span>
    <div
      class="mat-h5"
      [ngClass]="{
        'byc-hidden': item.body === undefined,
        'byc-radio-button-small-body pt-2': item.body != '',
        'dc-primary-variant': !item.checked
      }">
      {{ item.body }}
    </div>
  </mat-radio-button>
</mat-radio-group>
