<div fxLayout="column" fxLayoutGap="10px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxLayout="column" class="px-4">
    <!--    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Subscription</span>-->
    <!--    <span fxFlex class="dc-primary mat-h3">{{ this.subscriptionType }}</span>-->

    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Membership fee:</span>
    <span fxFlex class="dc-primary mat-h3">${{ this.memberFee }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0"
      >Additional capacity:</span
    >
    <span fxFlex class="dc-primary mat-h3"
      >${{ this.binFee * (this.qty - 1) }}</span
    >
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Total payable:</span>
    <span fxFlex class="dc-primary mat-h3">${{ this.paymentAmount }}</span>
  </div>

  <div class="row mx-2">
    <div class="col py-1">
      <div class="row px-3">
        <div
          class="col py-2 {{ this._style }}-main-panel {{ this._style }}-panel">
          <div class="container-fluid h-100 py-2" data-cy="stripeCard">
            <div class="row">
              <div class="col-10 py-1 {{ this._style }}-text-bold">
                Card Number
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <div id="card-number"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-10 py-1 pt-4 {{ this._style }}-text-bold">
                Expiry
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <div id="card-expiry"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-10 py-1 pt-4 {{ this._style }}-text-bold">
                CVC
              </div>
            </div>
            <div class="row">
              <div class="col-10">
                <div id="card-cvc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column">
    <button id="submitButton" class="mt-2" mat-raised-button color="primary" [disabled]="submitButtonDisabled" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
