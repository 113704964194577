import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc3002',
  templateUrl: './uxc3002.component.html',
  styleUrls: ['./uxc3002.component.scss']
})
export class Uxc3002Component implements OnInit {
  _style: string = '';

  public verificationCode: string;

  constructor(public appService: AppService, private authService: AuthService) {
    this._style = this.authService.getStyle();

    this.verificationCode = '';
  }

  ngOnInit(): void {}

  validateCode() {}
}
