<!-- header strip -->
<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [style]="{ margin: '30px 30px 0' }">
  <!--  <mat-icon fxFlex (click)="toggleDarkMode()">menu</mat-icon>-->
  <div fxFlex>
    <img
      class="img-user"
      src="../../../assets/images/DIRECT-COLLECT-ICONS-ACCOUNT.png"
      alt="login image"
      (click)="openBottomSheet()" />
  </div>
  <img
    fxFlex
    class="img-logo"
    src="../../../assets/images/DIRECT-COLLECT-SECONDARY-LOGO-RGB-MONOTONE-01.png"
    alt="login image" />
  <mat-icon fxFlex class="text-white"></mat-icon>
</div>

<div
  fxLayout="column"
  fxLayoutGap="15px"
  [style]="{ margin: '15px 30px 0 30px' }">
  <div fxFlex class="mat-h3">Hi {{ this.firstName }}!</div>

  <div fxFlex class="img-top-container" *ngIf="!displayCollection && !displayPickUp && !isOutOfScheme">
    <img
    class="img-top-display"
    src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-INSTRUCTIONS-01.png"
    alt="Order a bin"
    (click)="orderBin()" />
  </div>
  <div fxFlex *ngIf="!displayCollection && !displayPickUp && isOutOfScheme">
    <img
      class="img-top-display-disabled"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-INSTRUCTIONS-01.png"
      alt="Order a bin" />
  </div>
  <div fxFlex *ngIf="displayCollection && !displayPickUp">
    <img
      class="img-top-display"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-INSTRUCTIONS-02.png"
      alt="Order a bin" />
  </div>

  <!--  <div fxFlex *ngIf="displayCollection && displayPickUp">-->
  <div fxFlex *ngIf="binDroppedOff">
    <mat-card [style.padding-bottom]="0" class="shadow-sm dc-card">
      <mat-card-subtitle style="color: #1d2e3c">
        Plan: {{ this.currentUserType }}
      </mat-card-subtitle>
      <mat-divider
        inset
        style="border-top-color: #1d2e3c"
        class="mb-4"></mat-divider>
      <mat-card-title style="font-size: 1.2rem"
        >Your next bin collection is on</mat-card-title
      >
      <mat-card-content>
        <span
          *ngIf="!lastPickupRequest"
          class="mat-h3"
          style="color: #e6e7e8; filter: brightness(70%)"
          >No collection booked</span
        >
        <span *ngIf="lastPickupRequest" class="mat-h2" style="color: #0074ff">
          {{ this.collectionDate }}
        </span>
        <mat-divider
          inset
          style="border-top-color: #1d2e3c"
          class="mt-3"></mat-divider>
        <div fxLayout="row" [style.padding-top.px]="10">
          <img
            fxFlex="20"
            class="img-notif"
            src="../../../assets/images/DIRECT-COLLECT-ICONS-LOCATION.png"
            alt="Request Pickup" />
          <div fxFlex class="mat-h4">{{ this._currentUserAddress }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Account balance -->
  <div fxFlex *ngIf="binDroppedOff">
    <mat-card
      [style.padding-bottom]="0"
      class="shadow-sm dc-card"
      (click)="binStatus()">
      <mat-card-header>
        <div
          *ngIf="this.currentUserType != 'Charity'"
          mat-card-avatar
          style="
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../../assets/images/DIRECT-COLLECT-ARROWS-DEPOSIT.png');
          "></div>
        <div
          *ngIf="this.currentUserType == 'Charity'"
          mat-card-avatar
          style="
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('../../../assets/images/DIRECT-COLLECT-ARROWS-DONATION.png');
          "></div>
        <mat-card-title
          *ngIf="this.currentUserType != 'Charity'"
          style="font-size: 1.1rem"
          >Your total refunded amount</mat-card-title
        >
        <mat-card-title
          *ngIf="this.currentUserType == 'Charity'"
          style="font-size: 1.1rem"
          >You've donated a total of</mat-card-title
        >
        <mat-card-subtitle style="font-size: 1.5rem">
          ${{ this.amountPaid }}
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <div fxFlex>&nbsp;</div>
</div>

<!-- featured panel -->
<div class="bottom-carousel" fxLayoutAlign="center" *ngIf="!closeAnnouncements">
  <div fxLayoutAlign="center">
    <ngb-carousel [interval]="10000" [showNavigationArrows]="false">
      <ng-template ngbSlide *ngFor="let slide of announces">
        <mat-card
          [style.width.px]="310"
          [style.minHeight.px]="190"
          class="dc-carousel">
          <mat-card-header>
            <mat-card-subtitle>{{ slide['main_heading'] }}</mat-card-subtitle>
            <mat-card-title>{{ slide['sub_heading'] }}</mat-card-title>
            <div fxFlex class="close-button">
              <button mat-icon-button class="close-button" (click)="closeAnnouncement()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </mat-card-header>
          <mat-card-content class="mx-3 mb-2">
            {{ slide['body_content'] }}
          </mat-card-content>
        </mat-card>
      </ng-template>
    </ngb-carousel>
  </div>
</div>

<!-- bottom navigation -->
<div class="bottom-nav">
  <div fxLayout="row" fxFlexAlign="center" class="mx-3">
    <img
      fxFlex
      *ngIf="this.displayPickUp"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-COLLECTION.png"
      alt="Order a bin"
      (click)="requestPickup()"
      class="img-menu" />
    <img
      fxFlex
      *ngIf="!this.displayPickUp"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-COLLECTION.png"
      alt="Order a bin"
      class="img-menu-disabled" />
    <img
      fxFlex
      *ngIf="this.displayCollection"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-DATE.png"
      alt="Order a bin"
      (click)="requestPickupChange()"
      class="img-menu" />
    <img
      fxFlex
      *ngIf="!this.displayCollection"
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-DATE.png"
      alt="Order a bin"
      class="img-menu-disabled" />
    <img
      fxFlex
      src="../../../assets/images/DIRECT-COLLECT-APP-BUTTONS-SUPPORT.png"
      alt="Order a bin"
      (click)="redirectToSupport()"
      class="img-menu" />
  </div>
</div>
