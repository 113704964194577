<form [formGroup]="formGroup" fxLayout="column" class="dc-container">
  <no-back-screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </no-back-screen-header>

  <div fxLayout="row" fxLayoutGap="15px">
    <mat-form-field fxFlex appearance="outline">
      <mat-label>First Name</mat-label>
      <input
        matInput
        data-cy="firstName"
        id="firstName"
        formControlName="firstName" />
      <mat-error>Required</mat-error>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Last Name</mat-label>
      <input
        matInput
        data-cy="lastName"
        id="lastName"
        formControlName="lastName" />
      <mat-error>Required</mat-error>
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Mobile number</mat-label>
      <input matInput data-cy="mobile" id="mobile" formControlName="mobile" />
      <mat-error *ngIf="this.formGroup.controls.mobile.errors?.required"
        >Required</mat-error
      >
      <mat-error
        *ngIf="
          this.formGroup.controls.mobile.errors?.pattern ||
          this.formGroup.controls.mobile.errors?.format
        ">
        Invalid format
      </mat-error>
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Email address</mat-label>
      <input
        matInput
        data-cy="email"
        type="email"
        id="email"
        formControlName="email" />
      <mat-error *ngIf="this.formGroup.controls.email.errors?.required"
        >Required</mat-error
      >
      <mat-error *ngIf="this.formGroup.controls.email.errors?.email"
        >Invalid format</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        data-cy="password"
        [type]="hide ? 'password' : 'text'"
        id="password"
        formControlName="password" />
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="this.formGroup.controls.password.errors?.required"
        >Required</mat-error
      >
      <mat-error *ngIf="this.formGroup.controls.password.errors?.pattern">
        Minimum 8 characters with at least 1 number, 1 special character, 1
        upper case
      </mat-error>
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        data-cy="confirmPassword"
        [type]="hide ? 'password' : 'text'"
        id="confirmPassword"
        formControlName="confirmPassword" />
      <mat-error
        *ngIf="this.formGroup.controls.confirmPassword.errors?.required"
        >required</mat-error
      >
      <mat-error *ngIf="this.formGroup.controls.confirmPassword.errors?.match"
        >doesn't match</mat-error
      >
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-checkbox fxFlex id="agreed" formControlName="agreed" color="primary">
      I agree to the
      <a
        [ngClass]="{ 'router-link-active': true }"
        (click)="openPrivacyPolicy()"
        >Privacy Policy</a
      >
    </mat-checkbox>
  </div>

  <div fxFlex>
    <button
      mat-raised-button
      data-cy="next"
      id="signUp"
      class="mt-4"
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="next()">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>

  <app-footer fxFlex class="mt-5"></app-footer>
</form>
