<div [ngClass]="themeToUse" class="vh-99">
  <!--spinner-->
  <div
    *ngIf="this._displaySpinner"
    class="{{ this._style }}-spinner-loaderContainer">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <!--router-->
  <router-outlet></router-outlet>
</div>

<!--If want the video run once code uncomment below-->
<!--<div *ngIf="this._playVideo && this._videoRun" class="video-container" id="splashScreen">-->
<div *ngIf="this._playVideo" class="video-container" id="splashScreen">
  <video class="splashVideo" autoplay muted>
    <source
      src="../assets/videos/DIREC-COLLECT-APP-MOTION-GRAPHIC.mp4"
      type="video/mp4" />
  </video>
</div>
