<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxLayout="column" class="px-4">
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Subscription</span>
    <span fxFlex class="dc-primary mat-h3 mb-2">{{
      this.orderSummary.subscription_type.description
    }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Type</span>
    <span fxFlex class="dc-primary mat-h3 mb-2">{{
      this.orderSummary.container_type.description
    }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Payout</span>
    <span fxFlex class="dc-primary mat-h3 mb-2">$0.07 / container</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Total payable now</span>
    <span fxFlex class="dc-primary mat-h3 mb-2">$0.00</span>
  </div>

  <div class="row">
    <div class="col pb-1">
      <app-display-charity [charity]="this.selectedItem"></app-display-charity>
    </div>
  </div>

  <button
    fxFlex
    mat-raised-button
    class="mt-2"
    color="primary"
    (click)="next()">
    {{ primaryButtonCaption }}
  </button>
</div>
