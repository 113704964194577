import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';
import { ThemeService } from './services/theme.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from './Shared/alert/alert.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  _style: string = '';

  notificationSub: Subscription | undefined;
  spinnerSub: Subscription | undefined;
  videoSub: Subscription | undefined;
  themeSub!: Subscription;

  title = 'Direct Collect - Customer experience';

  // variable that controls the spinner globally
  _displaySpinner: boolean = true;
  // var that controls the video globally
  _playVideo: boolean = false;
  // video has been run logic
  _videoRun: boolean = true;

  themeToUse: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private themeService: ThemeService,
    private snackBar: MatSnackBar
  ) {
    this._style = this.authService.getStyle();
  }

  ngAfterViewInit() {


    setTimeout(() => {
      this.notificationSub = this.appService.currentAppNotification.subscribe(
        (notification) => {
          if (notification && notification.displayNotification)
            this.snackBar.openFromComponent(AlertComponent, {
              data: notification,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
        }
      );

      this.spinnerSub = this.appService.currentAppSpinner.subscribe(
        (status) => {
          if (status) this.snackBar.dismiss();
          this._displaySpinner = status;
        }
      );

      this.videoSub = this.appService.currentSplashVideo.subscribe((status) => {
        // If want the video the only run once uncomment code below and comment out current line
        if (this._videoRun) {
          this._playVideo = status;
        }
        setTimeout(() => {
          this._videoRun = false;
        }, 4000);
        // this._playVideo = status;
      });

      this.themeSub = this.themeService.theme.subscribe((themeToUse) => {
        this.themeToUse = themeToUse;
      });
    });
  }

  async ngOnDestroy() {
    if (this.notificationSub) {
      await this.notificationSub.unsubscribe();
    }
  }
}
