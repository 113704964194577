import { environment } from 'src/environments/environment';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { IAddress } from 'src/app/helper/appInterfaces';

@Component({
  selector: 'app-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss']
})
export class AddressPickerComponent implements OnInit {
  @ViewChild('search')
  public searchElementRef!: ElementRef;

  @Output() addressPicked = new EventEmitter<IAddress>();

  title: string = 'Address picker';
  latitude!: number;
  longitude!: number;
  zoom!: number;
  address!: string;
  suburb!: string;
  postcode!: number;
  private geoCoder: any;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const options = {
        componentRestrictions: { country: 'aus' },
        fields: ['formatted_address', 'geometry', 'name', 'address_components'],
        strictBounds: false,
        types: ['address']
      };

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        options
      );

      navigator.geolocation.getCurrentPosition(
        (resp) => {
          //  localize address search
          const defaultBounds = {
            north: resp.coords.latitude + 0.1,
            south: resp.coords.latitude - 0.1,
            east: resp.coords.longitude + 0.1,
            west: resp.coords.longitude - 0.1
          };

          autocomplete.setBounds(defaultBounds);
        },
        (err) => {
          console.error('err', err);
        }
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(async () => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.address = String(this.searchElementRef.nativeElement.value);

          if (!environment.production) {
            console.log(
              'search box',
              this.searchElementRef.nativeElement.value
            );
            console.log('formatted_address', place.formatted_address);
          }

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          if (
            place.address_components === undefined ||
            place.address_components === null
          ) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          this.suburb = await this.getPlaceAttr(place, 'locality');
          this.postcode = await this.getPlaceAttr(place, 'postal_code');

          this.addressPicked.emit({
            address: this.address,
            suburb: this.suburb,
            postcode: this.postcode,
            lat: this.latitude,
            lng: this.longitude
          });
        });
      });
    });
  }

  public getPlaceAttr(place: any, attr: string) {
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType == attr) {
        return component.short_name;
      }
    }
  }
}
