<div fxLayout="row">
  <div
    fxFlex="40px"
    class="div-alert-icon"
    [style.background-color]="backgroundColour">
    <img class="img-alert" [src]="icon" alt="login image" />
  </div>

  <div fxFlex="grow" class="div-alert-message mat-h4 mb-0">
    <p class="mb-0">{{ data.message }}</p>

    <div *ngIf="data.action" class="mt-3">
      <mat-divider class="mb-3"></mat-divider>

      <a [href]="data.action.href" rel="noopener noreferred" target="_blank">
        {{ data.action.label }}
      </a>
    </div>
  </div>

  <div fxFlex="30px" [style]="{ padding: '10px 10px 0 0' }">
    <mat-icon (click)="onCloseClick()">close</mat-icon>
  </div>
</div>
