import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { appUtils } from 'src/app/helper/appUtils';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc7001',
  templateUrl: './uxc7001.component.html',
  styleUrls: ['./uxc7001.component.scss']
})
export class Uxc7001Component implements OnInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  items: IOptionType[];
  selectedItem: IOptionType = { key: '', description: '', checked: false };
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };

  taxInvoiceEmail: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C7_orderBinCharity.C7001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
    this.getData();
  }

  ngOnInit(): void {}

  async getData() {
    this.taxInvoiceEmail = this.appService.UserObject.taxInvoiceEmail;

    // get customer charity details and store in workflow object
    let resCharity = await this.appApi.getCharities(
      this.appService.UserObject.charityId
    );
    if (resCharity.length > 0) {
      this.selectedItem = resCharity[0];

      this.appService.updateWorkflowData({
        workflowId: appWorkflowData.C7_orderBinCharity.C7001.id,
        workflowData: {
          charity: {
            key: this.selectedItem.key,
            description: this.selectedItem.description,
            charityObject: this.selectedItem
          },
          taxInvoice: this.taxInvoiceEmail
        }
      });
    }

    // get default container details and store in workflow object
    let resContainerType = await this.appApi.getContainerTypes(
      appParam.containerCategory.bin
    );
    if (resContainerType.length > 0) {
      this.containerType.key = resContainerType[0].key;
      this.containerType.description = resContainerType[0].description;
    }

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.appApi.getSubscriptionTypes(
      appParam.subscriptionCategory.charity
    );
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
    }

    // get order type details and store in workflow object
    let resOrderType = await this.appApi.getOrderTypes(appParam.orderType.drop);
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }

  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        qty: 1,
        taxInvoiceEmail: this.taxInvoiceEmail,

        order_type: {
          key: this.orderType.key,
          description: this.orderType.description
        },

        subscription_type: {
          key: this.subscriptionType.key,
          description: this.subscriptionType.description
        },

        charity: {
          key: this.selectedItem.key,
          description: this.selectedItem.description,
          charityObject: this.selectedItem
        },

        container_type: {
          key: this.containerType.key,
          description: this.containerType.description
        }
      }
    });
  }
}
