import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddress } from 'src/app/helper/appInterfaces';
import { appUtils } from '../../helper/appUtils';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() _availableDate: any[] = [];
  @Input() _selectedDate: any = Date.now();
  @Output() mDatePicked = new EventEmitter<IAddress>();

  constructor() {}

  ngOnInit(): void {}

  myFilter = (d: Date | null): boolean => {
    return appUtils.isInDateArray(this._availableDate, d);
  };

  datePicked(event: any) {
    this.mDatePicked.emit(event.value);
  }

  dateClass = (d: Date) => {
    const isValidDate = appUtils.isInDateArray(this._availableDate, d);
    const date = d.getDate();
    return isValidDate ? 'byc-datepicker-available-dates' : '';
  };
}
