<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex>
    <app-item-picker
      [_items]="this.items"
      (itemPicked)="addressPicked($event)"
      #addressPicker>
    </app-item-picker>
  </div>

  <div fxFlex>
    <app-address-picker
      (addressPicked)="addressEntered($event)"></app-address-picker>
  </div>

  <div fxLayout="column">
    <button mat-raised-button class="mt-4" color="primary" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
