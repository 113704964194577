import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'no-back-screen-header',
  templateUrl: './no-back-header.component.html',
  styleUrls: ['./no-back-header.component.scss']
})
export class NoBackHeaderComponent implements OnInit {
  _style: string = '';

  @Input() _progressValue: number = 0;
  @Input() _progressTitle: string = '';
  @Input() _headerTitle: string = '';
  @Input() _cancelRoute: string = '';

  constructor(
    public appService: AppService,
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
  }

  ngOnInit(): void {}

  async cancel() {
    if (this._cancelRoute == '') {
      this.router.navigate(['/' + appParam.routing.C1_signUp.C1005], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/' + this._cancelRoute], {
        relativeTo: this.route
      });
    }
  }
}
