import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { IOptionType } from '../../helper/appInterfaces';

@Component({
  selector: 'app-uxc8003',
  templateUrl: './uxc8003.component.html',
  styleUrls: ['./uxc8003.component.scss']
})
export class Uxc8003Component implements OnInit {
  _style: string = '';
  _customerNotes: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  primaryButtonDisabled: boolean = true;

  availableDates: any[] = [];

  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };
  pickedOrder: any;
  order: any[] = [];

  selectedDate: string = '';
  selectedDay: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C8_requestPickup.C8003;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
    this.updatePrimaryButtonStatus();
  }

  async getData() {
    let resCustomerAddress = await this.appApi.getCustomerAddresses();
    // set the notes to be the previous note
    this._customerNotes = resCustomerAddress[0].notes;

    const result = await Promise.all([
      this.appApi.getAvailableDates(
        resCustomerAddress[0].refSuburb.id,
        false
      ),
      this.appApi.getLatestCustomerOrder(),
      this.appService.getRequestPickupSummary(),
      this.appApi.getSubscriptionTypes(this.appService.UserObject.userType),
      this.appApi.getOrderTypes(appParam.orderType.pickup),
      this.appApi.getCurrentBinCount()
    ]);
    this.pickedOrder = result[1];

    console.log(result[0]);

    let res = result[0];
    await this.order.push({
      order_id: this.pickedOrder.id,
      address: resCustomerAddress[0],
      qty: result[5][0].current_bins
    });

    // 2021-10-21 CJ - Fix - only future dates are available for pickup
    const dtNow = new Date();
    this.availableDates = res.filter((item) => item > dtNow);

    // logic for when user hits back button to preserve values selected previously
    const workflowData = result[2];
    if (
      workflowData.selected_date.key != undefined &&
      workflowData.selected_date.key != ''
    ) {
      await this.updateSelectedDate(workflowData.selected_date.key);
    }

    // get subscription type details and store in workflow object
    let resSubscriptionType = result[3];
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
    }

    // get order type details and store in workflow object
    let resOrderType = result[4];
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event);
  }

  async updateSelectedDate(_date: any) {
    this.selectedDate = _date;
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate);
    await this.save();
    this.updatePrimaryButtonStatus();
  }

  async next() {
    this.appService.appSpinner(true);
    this.primaryButtonDisabled = true;
    await this.save();
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    }).finally(() => this.primaryButtonDisabled = false);
  }

  async updatePrimaryButtonStatus() {
    this.primaryButtonDisabled =
      this.selectedDate == '' || this.selectedDate == null;

    let btn: HTMLElement;
    btn = document.getElementById('primaryButton') as HTMLElement;

    if (this.primaryButtonDisabled == true) {
      btn.className = btn.className + ' byc-bottom-disabled';
    } else {
      btn.className = btn.className.replace('byc-bottom-disabled', '');
    }
  }

  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        selected_date: {
          key: this.selectedDate,
          description: this.selectedDate
        },
        subscription_type: {
          key: this.subscriptionType.key,
          description: this.subscriptionType.description
        },
        order_type: {
          key: this.orderType.key,
          description: this.orderType.description
        },
        picked_orders: this.order,
        notes: this._customerNotes
      }
    });
    if (this._customerNotes !== '') {
      await this.appApi.updateCustomerNotes(this._customerNotes);
    }
  }
}
