<div class="container-fluid">
  <div class="row">
    <div class="col py-2">
      <screen-header
        [_headerTitle]="headerTitle"
        [_progressTitle]="progressTitle"
        [_progressValue]="progressValue">
      </screen-header>
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-10 py-3">
      <h2>
        stripe integration - Display order details and "Pay now" (coming soon)
      </h2>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-2"
        (click)="next()">
        {{ primaryButtonCaption }}
      </button>
    </div>
  </div>
</div>
