<!-- 2021-10-17 CJ: Logic update - allow check box control from parent -->
<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <table fxFlex class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Status</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let item of items"
        class="p-2 my-1 order"
        (click)="orderPicked(item)">
        <td>{{ item['id'] }}</td>
        <td>{{ item['order_date'] | date: this._dateFormat }}</td>
        <td>{{ item['order_status'] }}</td>
      </tr>
    </tbody>
  </table>
</div>
