import { appParam } from '../appSettings';

export const C13_binStatus = {
  steps: 1,
  C13001: {
    id: appParam.routing.C13_binStatus.id + '001',
    progressTitle: 'Bin Summary',
    progressValue: () => 100,
    title: 'Activity History',
    primaryButtonCaption: '',
    next: appParam.routing.Userhome,
    cancel: appParam.routing.Userhome
  }
};
