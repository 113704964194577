import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-item-picker',
  templateUrl: './item-picker.component.html',
  styleUrls: ['./item-picker.component.scss']
})
export class ItemPickerComponent {
  _style: string = '';

  @Input() _items: IOptionType[] = [];
  @Output() itemPicked = new EventEmitter<IOptionType>();

  constructor(public appService: AppService, private authService: AuthService) {
    this._style = this.authService.getStyle();
  }

  itemSelected(event: any) {
    this._items.forEach((i) => (i.checked = i.key === event.value));
    this.itemPicked.emit({
      key: event.value,
      description:
        event.source._elementRef.nativeElement.outerText.split('\n')[0],
      checked: true
    });
  }
}
