<div fxLayout="column" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <div fxFlex [style.paddingTop.px]="10" class="mat-subheading-1">
    An SMS verification code has been sent to your phone number.
  </div>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Verification Code</mat-label>

    <input matInput value="" id="userId" [(ngModel)]="this.verificationCode" />
  </mat-form-field>

  <div fxFlex class="mb-2">
    <a [routerLink]="" (click)="resendCode()">Resend code</a>
  </div>

  <div fxLayout="column">
    <button
      mat-raised-button
      data-cy="next"
      id="signUp"
      class="mt-4"
      color="primary"
      (click)="resetPassword()">
      Next
    </button>
  </div>
</div>
