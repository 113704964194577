<form
  [formGroup]="formGroup"
  fxLayout="column"
  fxLayoutGap="10"
  class="dc-container">
  <div fxFlex>
    <screen-header
      [_headerTitle]="screenConfig.title"
      [_progressTitle]="screenConfig.progressTitle"
      [_progressValue]="screenConfig.progressValue()"
      [_cancelRoute]="screenConfig.cancel">
    </screen-header>
  </div>

  <div fxFlex class="mat-h3">
    <p *ngIf="phone">
      We have sent a verification code to <b> {{ phone }} </b>.
    </p>

    <p *ngIf="!phone">We have sent a verification code to your phone number.</p>
  </div>

  <div class="mat-h3">Please enter the code below to verify your account.</div>

  <div fxFlex>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Verification code</mat-label>
      <input
        matInput
        data-cy="verificationCode"
        id="verificationCode"
        formControlName="verificationCode"
        type="tel"
        pattern="[0-9]*" />
      <mat-error *ngIf="verificationCodeError?.required">Required</mat-error>
      <mat-error *ngIf="verificationCodeError?.pattern"
        >Your code should be 6 digits</mat-error
      >
    </mat-form-field>
  </div>

  <div class="mat-h mt-1">
    <p>
      Didn't receive a code?
      <a (click)="resendCode()" class="link-button">Resend my code</a>
    </p>
  </div>

  <div fxFlex class="mt-2">
    <button
      fxFlex
      mat-raised-button
      data-cy="next"
      id="verifyCode"
      color="primary"
      (click)="verifyCode()"
      [disabled]="!formGroup.valid">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>

  <div class="mt-4">
    <p>
      Still having trouble?
      <a
        href="https://www.direct-collect.com.au/contact-us"
        target="_blank"
        rel="noopener noreferrer"
        class="link-button">
        Contact us
      </a>
    </p>
  </div>

  <!-- 
    Disabled until we potentially add in the Email only feature.
   -->
  <!-- <div class="mt-2" fxLayout="row" fxLayoutAlign="center center">
    <mat-divider fxFlex></mat-divider>
    <div fxFlex="10" class="text-center mat-h5 my-0 dc-accent">OR</div>
    <mat-divider fxFlex> </mat-divider>
  </div>

  <div fxFlex class="mt-2">
    <button
      fxFlex
      mat-flat-button
      data-cy="email"
      id="emailVerify"
      color="secondary"
      (click)="emailVerify()">
      Verify by email
    </button>
  </div> -->

  <app-footer fxFlex class="mt-2"></app-footer>
</form>
