import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ICustomerOrder,
  IOptionType,
  IOptionWithNumberType
} from 'src/app/helper/appInterfaces';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc8004',
  templateUrl: './uxc8004.component.html',
  styleUrls: ['./uxc8004.component.scss']
})
export class Uxc8004Component {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  workflowData: any;
  orderSummary;

  selectedDate: any = '';
  customerAddress: any = '';
  numberOfBins: any;
  items: IOptionWithNumberType[];

  finalPickup: IOptionWithNumberType[];
  isFinalPickup: boolean = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C8_requestPickup.C8004;

    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.orderSummary = appWorkflowData.OrderSummary;
    this.items = [];

    this.finalPickup = [];
    this.finalPickup.push({
      key: '0',
      description: 'This is a final pick up request',
      value: 0,
      binCode: '',
      checked: false,
      body: 'Selecting this means that you no longer require a bin and wish to end your current subscription',
      icon: ''
    });

    this.appService.appSpinner(true);
    this.getData();
  }

  async getData() {
    // logic for when user hits back button to preserve values selected previously
    this.workflowData = await this.appService.getRequestPickupSummary();
    if (this.workflowData.selected_date.key != '') {
      let dt = new Date(this.workflowData.selected_date.key);
      this.selectedDate = appUtils.getDayofWeek(dt);
      this.selectedDate =
        this.selectedDate + ' ' + formatDate(dt, 'dd MMM', 'en-US');
    }

    this.customerAddress =
      this.workflowData.picked_orders[0].address.street_address;
    this.numberOfBins = this.workflowData.picked_orders[0].qty;

    this.appService.appSpinner(false);
  }

  async finalPickupSelected(event: IOptionType) {
    this.isFinalPickup = event.checked as boolean;
  }

  async next() {
    this.appService.appSpinner(true);

    //  pass the order summary for the common summary screen
    appWorkflowData.OrderResult.selected_date =
      this.workflowData.selected_date.key;
    appWorkflowData.OrderResult.address =
      this.workflowData.picked_orders[0].address.street_address;
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
    this.appService.appSpinner(false);
  }
}
