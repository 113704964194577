import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appParam } from 'src/app/helper/appSettings';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { appWorkflowData } from '../../helper/appWorkflowData';

@Component({
  selector: 'app-uxc4001',
  templateUrl: './uxc4001.component.html',
  styleUrls: ['./uxc4001.component.scss']
})
export class Uxc4001Component {
  _style: string = '';
  errHandler: appErrorHandler;
  screenConfig: any;
  email!: string;

  constructor(
    private appService: AppService,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this.errHandler = new appErrorHandler(this.appService);
    this.screenConfig = appWorkflowData.C4_forgotPassword.C4001;
  }

  /**
   * Handle requesting a new verification code to ensure that the user is
   * who they say they are. If the user isn't verified, we want to go
   * to the verification screen to verify their account.
   */
  public async sendCode() {
    const res = await this.authService.forgotPassword(this.email.trim());

    this.appService.UserObject.userId = this.email.trim();

    if (res === 'not-verified') {
      await this.router.navigate(['/' + appParam.routing.C1_signUp.C1002], {
        relativeTo: this.route
      });

      return;
    }

    if (res !== 'successful') return;

    this.appService.sendNotification({
      workflow: appParam.routing.C4_forgotPassword.C4001,
      body: { email: this.email.trim() },
      displayNotification: false
    });

    await this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
