import { C1_signUp } from './workflow/C1';
import { C10_orderStatus } from './workflow/C10';
import { C5_orderBinFullPayment } from './workflow/C5';
import { C7_orderBinCharity } from './workflow/C7';
import { C8_requestPickup } from './workflow/C8';
import { C9_requestPickupChange } from './workflow/C9';
import { C4_forgotPassword } from './workflow/C4';
import { C11_changePassword } from './workflow/C11';
import { C12_updateBankDetails } from './workflow/C12';
import { C13_binStatus } from './workflow/C13';

// used to store the workflow data across each screen
export const appWorkflowData = {
  C1_signUp: C1_signUp,
  C4_forgotPassword: C4_forgotPassword,
  C5_orderBinFullPayment: C5_orderBinFullPayment,
  C7_orderBinCharity: C7_orderBinCharity,
  C8_requestPickup: C8_requestPickup,
  C9_requestPickupChange: C9_requestPickupChange,
  C10_orderStatus: C10_orderStatus,
  C11_changePassword: C11_changePassword,
  C12_updateBankDetails: C12_updateBankDetails,
  C13_binStatus: C13_binStatus,

  SignUpSummary: {
    address: {
      description: '',
      suburb: '',
      state: '',
      lat: 0,
      lng: 0
    },
    subscription_type: {
      key: '',
      description: ''
    },
    charity: {
      key: '',
      description: ''
    },
    taxInvoiceEmail: ''
  },

  OrderSummary: {
    taxInvoiceEmail: '',

    order_type: {
      key: '',
      description: ''
    },
    payment_type: {
      key: '',
      description: ''
    },
    payment_details: {
      account_name: '',
      account_number: '',
      bsb: '',
      bank_name: ''
    },
    subscription_type: {
      key: '',
      description: '',
      bin_fee: 0,
      membership_fee: 0
    },
    charity: {
      key: '',
      description: '',
      charityObject: {}
    },
    address: {
      key: '',
      street_address: '',
      suburb: '',
      lat: 0,
      lng: 0
    },
    selected_date: {
      key: '',
      description: ''
    },
    container_type: {
      key: '',
      description: ''
    },
    notes: '',
    isSelectedDateSame: false,
    qty: 1
  },

  OrderResult: {
    selected_date: '',
    address: ''
  },

  RequestPickupSummary: {
    picked_orders: [],

    subscription_type: {
      key: '',
      description: ''
    },

    order_type: {
      key: '',
      description: ''
    },

    selected_date: {
      key: '',
      description: ''
    },
    notes: ''
  },

  RequestPickupChangeSummary: {
    picked_request: {
      key: '',
      code: '',
      description: '',
      body: ''
    },

    selected_date: {
      key: '',
      description: ''
    },

    subscription_type: {
      key: '',
      description: ''
    },

    order_type: {
      key: '',
      description: ''
    },
    notes: '',
    orderStatus: '',
    selectedDateSame: ''
  }
};
