<form [formGroup]="formGroup" fxLayout="column">
  <img
    fxFlex
    class="img-logo-login"
    src="../../../assets/images/DIRECT-COLLECT-SECONDARY-LOGO-RGB-MONOTONE-01.png"
    alt="login image"
    (click)="openDirectCollectHome()"
    [style.margin-bottom.px]="30" />

  <!--  <img-->
  <!--    fxFlex-->
  <!--    class="img-user"-->
  <!--    src="../../../assets/images/DIRECT-COLLECT-BUTTONS-ONBOARDING-01.png"-->
  <!--    alt="login image"-->
  <!--    [style.margin-bottom.px]="30"-->
  <!--  />-->

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Email address</mat-label>
      <input
        matInput
        data-cy="email"
        type="email"
        id="email"
        formControlName="email" />
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        data-cy="password"
        [type]="hide ? 'password' : 'text'"
        id="password"
        formControlName="password" />
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
  </div>

  <div fxFlex class="mat-h6">
    Forgot password?
    <a routerLink="/{{ this.linkForgotPassword }}" routerLinkActive="active"
      >click here</a
    >
  </div>

  <div fxFlex>
    <button
      mat-flat-button
      id="signIn"
      data-cy="next"
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="signIn()">
      Sign in
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-divider fxFlex></mat-divider>
    <div fxFlex="10" class="text-center mat-h6 my-0 dc-accent">OR</div>
    <mat-divider fxFlex> </mat-divider>
  </div>

  <div fxFlex>
    <button
      mat-stroked-button
      id="signUp"
      data-cy="next"
      color="accent"
      routerLink="/{{ this.linkSignUp }}"
      routerLinkActive="active">
      Sign up
    </button>
  </div>

  <app-footer fxFlex class="mt-5"></app-footer>
</form>
