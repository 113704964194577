import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { appUtils } from '../../helper/appUtils';

@Component({
  selector: 'app-uxc1004d',
  templateUrl: './uxc1004d.component.html',
  styleUrls: ['./uxc1004d.component.scss']
})
export class Uxc1004dComponent implements OnInit {
  _style: string = '';

  // screen config data related to the workflow step
  screenConfig: any;

  formGroup!: FormGroup;
  charities: IOptionType[];
  clubs: IOptionType[];
  clubsAndCharities: IOptionType[];
  selectedCharity: IOptionType | undefined;
  taxInvoiceEmail: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004d;

    this.appService.appSpinner(true);
    this.charities = [];
    this.clubs = [];
    this.clubsAndCharities = [];
    this.getData();
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      taxInvoiceEmail: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        ]
      ]
    });
  }

  openDialog(charity: any): void {
    this.dialog.open(Uxc1004dDialogComponent, {
      width: '300px',
      data: charity
    });
  }

  async getData() {
    this.appService.appSpinner(true);
    this.clubsAndCharities = await this.appApi.getCharities('', true);

    // get all the charities and clubs
    let clubAndCharity = await this.appApi.getCharities('', true);

    for (let item of clubAndCharity) {
       if (item.refOrganisationType.code == "CHARITY") {
          this.charities.push(item)
       } else {
           this.clubs.push(item)
       }
    }

    // sort the charities and clubs by alphabetical order
    this.charities.sort((a, b) =>
      a.description > b.description ? 1 : b.description > a.description ? -1 : 0
    );

    this.clubs.sort((a, b) =>
      a.description > b.description ? 1 : b.description > a.description ? -1 : 0
    );

    this.formGroup.controls['taxInvoiceEmail'].setValue(
      this.appService.UserObject.email
    );

    // logic for when user hits back button to preserve values selected previously
    if (this.clubsAndCharities && this.clubsAndCharities.length) {
      const workflowData = this.appService.getSignUpSummary();
      if (workflowData.charity) {
        this.selectedCharity = this.clubsAndCharities.find(
          (c) => c.key === workflowData.charity.key
        );
      }
      if (workflowData.taxInvoiceEmail) {
        this.formGroup.controls['taxInvoiceEmail'].setValue(
          workflowData.taxInvoiceEmail
        );
      }
    }

    this.appService.appSpinner(false);
  }

  async next() {
    await this.save();
  }

  //  save data into central workflow object which is shared between screens
  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.screenConfig.id,
      workflowData: {
        charity: this.selectedCharity,
        taxInvoiceEmail: this.formGroup.value.taxInvoiceEmail
      }
    });
    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}

@Component({
  selector: 'app-uxc1004d-dialog',
  templateUrl: './uxc1004d.dialog.component.html'
})
export class Uxc1004dDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Uxc1004dDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
