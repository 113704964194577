import { AppService } from '../services/app.service';
import { IError } from './appInterfaces';

export class appErrorHandler {
  constructor(private appService: AppService) {}

  public handleC1Errors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleC2Errors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleC3Errors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleC4Errors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleC5Errors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleAuthErrors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }

  public handleAPIErrors(error: IError): void {
    this.appService.sendNotification({
      type: error.type,
      message: error.displayMessage,
      displayNotification: error.displayNotification,
      body: error.errorObject,
      action: error.action
    });
  }
}
