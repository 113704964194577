import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';


@Component({
  selector: 'app-uxc9002',
  templateUrl: './uxc9002.component.html',
  styleUrls: ['./uxc9002.component.scss']
})
export class Uxc9002Component implements OnInit {
  _style: string = '';
  _customerNotes: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  primaryButtonDisabled: boolean = true;

  availableDates: any[] = [];
  orderItems: IOptionType[];
  order: any;

  selectedDate: string = '';
  selectedDay: string = '';
  address: string = '';
  dates: any[] = [];
  orderType: string = '';

  pickupRequestId: string = '';

  _canChange: boolean = false;
  _btnDisabled: boolean = false;
  _firstOrder: boolean = false;
  _selectedDateSame: boolean = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C9_requestPickupChange.C9002;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.orderItems = [];
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    let resCustomerAddress = await this.appApi.getCustomerAddresses();
    // set the notes to be the previous note
    this._customerNotes = resCustomerAddress[0].notes;

    const result = await Promise.all([
      // get the customer order
      this.appApi.getLatestCustomerOrder()
    ]);

    let latestOrderLog = await this.appApi.getCustomerTransactionLog(
      result[0].id
    );
    let res = result[0];
    this.order = res;

    // if the customer's latest order is created -> can edit, else in manifest and cannot edit
    if (latestOrderLog.refOrderStatus.code == 'CREATED') {
      this._firstOrder = true;
      this._canChange = true;
    } else {
      this._firstOrder = false;
      this._canChange = false;
    }

    this.address = res.refCustomerAddress.street_address;

    if (res.code.includes('DROPOFF')) {
      this.orderItems = [
        {
          key: res.id,
          description: 'Drop off request scheduled for',
          body:
            appUtils.getDayofWeek(res['order_date']) +
            ', ' +
            formatDate(res['order_date'], 'dd MMM', 'en-US'),
          checked: false,
          code: formatDate(res['order_date'], 'yyyy-MM-dd', 'en-US'),
          address: res.refCustomerAddress.street_address,
          bins_to_drop_off: res.bins_to_drop_off,
          notFirstOrder: false
        }
      ];
      this.orderType = 'DROPOFF';
      this.dates = await this.appApi.getAvailableDates(
        resCustomerAddress[0].refSuburb.id,
        true
      );

      console.log(this.dates);

    } else if (res.code.includes('CHANGEOVER')) {
      this.orderItems = [
        {
          key: res.id,
          description: 'Change over request scheduled for',
          body:
            appUtils.getDayofWeek(res['order_date']) +
            ', ' +
            formatDate(res['order_date'], 'dd MMM', 'en-US'),
          checked: false,
          code: formatDate(res['order_date'], 'yyyy-MM-dd', 'en-US'),
          address: res.refCustomerAddress.street_address,
          bins_to_drop_off: res.bins_to_drop_off,
          bins_to_pick_up: res.bins_to_pick_up
        }
      ];
      this.dates = await this.appApi.getAvailableDates(
        resCustomerAddress[0].refSuburb.id,
        false
      );

      console.log(this.dates);

      this.orderType = 'CHANGEOVER';
    } else {
      this.orderItems = [
        {
          key: res.id,
          description: 'Pick up request scheduled for',
          body:
            appUtils.getDayofWeek(res['order_date']) +
            ', ' +
            formatDate(res['order_date'], 'dd MMM', 'en-US'),
          checked: false,
          code: formatDate(res['order_date'], 'yyyy-MM-dd', 'en-US'),
          address: res.refCustomerAddress.street_address,
          bins_to_pick_up: res.bins_to_pick_up,
          notFirstOrder: true
        }
      ];
      this.dates = await this.appApi.getAvailableDates(
        resCustomerAddress[0].refSuburb.id,
        false
      );

      console.log(this.dates);

      this.orderType = 'PICKUP';
    }

    const dtNow = new Date();
    this.availableDates = this.dates.filter((item) => item > dtNow);

    // logic for when user hits back button to preserve values selected previously
    if (this.orderItems != undefined && this.orderItems[0].code != '') {
      if (this.orderItems[0].code !== undefined) {
        await this.updateSelectedDate(this.orderItems[0].code);
      }
    }

    this.appService.appSpinner(false);
  }

  async datePicked(event: any) {
    await this.updateSelectedDate(event);
  }

  async updateSelectedDate(_date: string) {
    let date = new Date(_date);
    let tzOffset = date.getTimezoneOffset() * -1;
    const dateString = moment(_date).utcOffset(tzOffset).format('YYYY-MM-DD');
    this.selectedDate = dateString;
    this.selectedDay = ' ' + appUtils.getDayofWeek(this.selectedDate);
  }

  async next() {
    this.appService.appSpinner(true);
    this._btnDisabled = true;
    await this.save();
    //  pass the order summary for the common summary screen
    appWorkflowData.OrderResult.selected_date = this.selectedDate;
    appWorkflowData.OrderResult.address = this.address;
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    }).finally(() => this._btnDisabled = false);
  }

  async cancelRequest() {
    this.appService.appSpinner(true);
    const res = await this.appApi.cancelCustomerPickupRequest(
      this.pickupRequestId
    );

    if (res['id'] != undefined) {
      //  pass the order summary for the common summary screen
      appWorkflowData.OrderResult.selected_date = '';
      appWorkflowData.OrderResult.address = '';

      this.router.navigate(['/' + this.workflowStep.next], {
        relativeTo: this.route
      });
    } else {
      this.appService.appSpinner(false);
    }
  }

  async save() {
    this.order.order_date == this.selectedDate ? this._selectedDateSame = true : this._selectedDateSame = false;

    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        selected_date: {
          key: this.selectedDate,
          description: this.selectedDate
        },
        picked_request: this.orderItems[0],
        orderStatus: this.orderType,
        notes: this._customerNotes,
        selectedDateSame: this._selectedDateSame
      }
    });

    if (this._customerNotes !== '') {
      await this.appApi.updateCustomerNotes(this._customerNotes);
    }
  }
}
