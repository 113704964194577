import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C10_orderStatus = {
  steps: 3, // used for the progress indicator on each screen
  C10001: {
    id: appParam.routing.C10_orderStatus.id + '001',
    progressTitle: 'Order summary',
    progressValue: () => 100,
    title: 'Need to check your order status?',
    primaryButtonCaption: '',
    next: appParam.routing.C10_orderStatus.C10002
  },
  C10002: {
    id: appParam.routing.C10_orderStatus.id + '002',
    progressTitle: 'Order details',
    progressValue: () => 100,
    title: 'Order details',
    primaryButtonCaption: '-',
    next: appParam.routing.C10_orderStatus.C10001
  }
};
