import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc3001',
  templateUrl: './uxc3001.component.html',
  styleUrls: ['./uxc3001.component.scss']
})
export class Uxc3001Component implements OnInit {
  _style: string = '';

  public mobile: string;

  constructor(public authService: AuthService, public appService: AppService) {
    this._style = this.authService.getStyle();

    this.mobile = '';
  }

  ngOnInit(): void {}

  sendCode() {
    this.authService.forgotUserId(this.mobile);
  }
}
