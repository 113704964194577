<div fxLayout="column" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Email Address</mat-label>
    <input matInput value="" id="userId" [(ngModel)]="this.email" />
  </mat-form-field>

  <div fxLayout="column">
    <button
      mat-raised-button
      data-cy="next"
      id="signUp"
      class="mt-4"
      color="primary"
      (click)="sendCode()">
      Next
    </button>
  </div>
</div>
