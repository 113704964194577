import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C8_requestPickup = {
  steps: 3, // used for the progress indicator on each screen
  C8001: {
    id: appParam.routing.C8_requestPickup.id + '001',
    progressTitle: 'Request pickup',
    progressValue: () => (100 / C8_requestPickup.steps) * 1,
    title: 'Where is the bin pickup location and how many bins?',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C8_requestPickup.C8003
  },
  C8003: {
    id: appParam.routing.C8_requestPickup.id + '003',
    progressTitle: 'Collection',
    progressValue: () => (100 / C8_requestPickup.steps) * 1,
    title: 'When would you like us to collect the bin?',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C8_requestPickup.C8005
  },
  C8004: {
    id: appParam.routing.C8_requestPickup.id + '004',
    progressTitle: 'Collection',
    progressValue: () => (100 / C8_requestPickup.steps) * 2,
    title: 'Confirm your collection request details',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C8_requestPickup.C8005
  },
  C8005: {
    id: appParam.routing.C8_requestPickup.id + '005',
    progressTitle: 'Collection',
    progressValue: () => (100 / C8_requestPickup.steps) * 3,
    title: 'Thanks for requesting a collection!',
    primaryButtonCaption: 'Done',
    next: appParam.routing.C1_signUp.C1005
  }
};
