import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { alertAttributes } from 'src/app/helper/appAlert';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-uxc1004b',
  templateUrl: './uxc1004b.component.html',
  styleUrls: ['./uxc1004b.component.scss']
})
export class Uxc1004bComponent implements OnInit, AfterViewInit, OnDestroy {
  _style: string = '';

  items: any[] = [];
  selectedItem: IOptionType = { key: '', description: '', checked: false };

  address: string = '';

  primarySub: Subscription | undefined;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    this.items = await this.appApi.getAddressTypes(true);

    if (this.items.length > 0) {
      this.selectedItem = this.items[0];
    }
    this.appService.appSpinner(false);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.primarySub = this.appService.currentAppNotification.subscribe(
        (notification) => {
          if (
            notification.workflow ===
            alertAttributes.workflowSteps.C1_signUp.C1004
          ) {
            this.address = notification.body.address;
          }
        }
      );
    });
  }

  async ngOnDestroy() {
    if (this.primarySub) {
      await this.primarySub.unsubscribe();
    }
  }

  async itemPicked(event: IOptionType) {
    this.selectedItem = event;
  }

  async next() {
    this.appService.appSpinner(true);

    await this.appApi.updateCustomerAddressType(
      {
        userId: this.appService.UserObject.userId,
        locationTypeId: this.selectedItem.key
      },
      true
    );

    await this.appService.sendNotification({
      workflow: alertAttributes.workflowSteps.C1_signUp.C1004b,
      body: { locationTypeCode: this.selectedItem },
      displayNotification: false
    });

    await this.router.navigate(['/' + appParam.routing.C1_signUp.C1005], {
      relativeTo: this.route
    });
  }
}
