import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { alertAttributes } from '../../helper/appAlert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  /** The types of alerts that can be shown. */
  public types = alertAttributes.types;

  /** The background colour for the icon part of the notification. */
  public backgroundColour = '';

  /** The accompanying icon that we will display in the notification. */
  public icon = '';

  constructor(
    public snackBarRef: MatSnackBarRef<AlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    const { type } = data;

    this.backgroundColour = this.getBackgroundColour(type);
    this.icon = this.getAlertIcon(type);
  }

  public onCloseClick(): void {
    this.snackBarRef.dismiss();
  }

  /**
   * Handle obtaining the correct background colour to display in the
   * notification to the user.
   *
   * @param type The type of notification we would like to show the user.
   *
   * @returns The background colour that we can apply to the notification.
   */
  private getBackgroundColour(type?: number): string {
    switch (type) {
      /** Success will show a green background colour. */
      case this.types.success:
        return '#cdf0cb';

      /** Warning will show a yellow background colour. */
      case this.types.warning:
        return '#fcedd5';

      /** Error will show a red background colour. */
      case this.types.error:
        return '#f0d5d4';

      /** Information will be the default background colour of blue. */
      default:
        return '#d7e9fe';
    }
  }

  /**
   * Handle obtaining the correct icon that we need to display in the
   * notification to the user.
   *
   * @param type The type of notification we would like to show the user.
   *
   * @returns The associated icon that we can display in the notification.
   */
  private getAlertIcon(type?: number): string {
    switch (type) {
      /** Success will show a check mark icon. */
      case this.types.success:
        return '/assets/images/DIRECT-COLLECT-ICONS-ALERT-SUCCESS.png';

      /** Warning will show an exclamation mark icon. */
      case this.types.warning:
        return '/assets/images/DIRECT-COLLECT-ICONS-ALERT-WARNING.png';

      /** Error will show a cross icon. */
      case this.types.error:
        return '/assets/images/DIRECT-COLLECT-ICONS-ALERT-ERROR.png';

      /** Information will be the default image to show. */
      default:
        return '/assets/images/DIRECT-COLLECT-ICONS-ALERT-INFO.png';
    }
  }
}
