<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <no-back-screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </no-back-screen-header>

  <div fxLayout="column" class="px-4">
    <span fxFlex class="dc-primary-variant mat-h4 mb-0"
      >Your bin will be picked up on</span
    >
    <span fxFlex class="dc-primary mat-h3">{{ selectedDate }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0"
      >At this address</span
    >
    <span fxFlex class="dc-primary mat-h3">{{ address }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0"
      >An SMS notification has been sent to mobile number</span
    >
    <span fxFlex class="dc-primary mat-h3">{{ mobile }}</span>
  </div>

  <div fxLayout="column">
    <button mat-raised-button class="mt-2" color="primary" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
