<mat-selection-list [multiple]="false">
  <mat-list-item>
    <div mat-line>{{ fullName }}</div>
  </mat-list-item>
  <mat-list-item>
    <div mat-line>{{ email }}</div>
  </mat-list-item>
  <!--  <mat-list-option (click)="openOrderHistory()">-->
  <!--    <mat-icon mat-list-icon>history</mat-icon>-->
  <!--    <div mat-line>My order history</div>-->
  <!--  </mat-list-option>-->
  <mat-list-option (click)="openBinHistory()">
    <mat-icon mat-list-icon>history</mat-icon>
    <div mat-line>My bin history</div>
  </mat-list-option>
  <mat-list-option
    (click)="updateBankDetails()"
    *ngIf="this.currentUserType != 'Charity'">
    <mat-icon mat-list-icon class="material-icons-outlined"
      >account_balance</mat-icon
    >
    <div mat-line>Update bank details</div>
  </mat-list-option>
  <mat-list-option (click)="changePassword()">
    <mat-icon mat-list-icon class="material-icons-outlined"
      >lock_reset</mat-icon
    >
    <div mat-line>Change password</div>
  </mat-list-option>
  <mat-list-option (click)="signOut()">
    <mat-icon mat-list-icon>logout</mat-icon>
    <div mat-line>Log out</div>
  </mat-list-option>
</mat-selection-list>

<app-footer></app-footer>
