<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-3">
    <mat-icon fxFlex (click)="back()">keyboard_backspace</mat-icon>
    <img
      fxFlex
      class="img-logo"
      src="../../../assets/images/DIRECT-COLLECT-SECONDARY-LOGO-RGB-MONOTONE-01.png"
      alt="login image" />
    <mat-icon fxFlex (click)="cancel()" class="text-end">close</mat-icon>
  </div>

  <div fxFlex class="mat-h4 my-2">{{ this._progressTitle }}</div>

  <div fxFlex>
    <mat-progress-bar
      mode="determinate"
      value="{{ this._progressValue }}"></mat-progress-bar>
  </div>

  <div fxFlex class="mat-h2 mt-2">{{ this._headerTitle }}</div>
</div>
