import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOptionWithNumberType } from 'src/app/helper/appInterfaces';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-item-and-number-picker',
  templateUrl: './item-and-number-picker.component.html',
  styleUrls: ['./item-and-number-picker.component.scss']
})
export class ItemAndNumberPickerComponent {
  _style: string = '';

  checked = false;
  indeterminate = false;

  @Input() _items: IOptionWithNumberType[] = [];
  @Input() _readonly: boolean = false;
  @Output() itemPicked = new EventEmitter<IOptionWithNumberType>();

  constructor(public appService: AppService, private authService: AuthService) {
    this._style = this.authService.getStyle();
  }

  itemSelected(event: any) {
    this.itemPicked.emit({
      key: this._items[event.option.value].key,
      description: this._items[event.option.value].description,
      checked: event.option._selected,
      binCode: '',
      value: parseInt('' + this._items[event.option.value].value)
    });
  }
}
