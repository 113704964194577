import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { appParam } from '../helper/appSettings';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (!environment.production) console.info('auth-guard service');
  }

  public async canActivate() {
    const isLoggedIn = await this.authService.isLoggedIn();

    if (!isLoggedIn) {
      await this.router.navigate(['/' + appParam.routing.C2_signIn.C2001], {
        relativeTo: this.route
      });
    }

    return isLoggedIn;
  }
}
