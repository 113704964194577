<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <div fxFlex class="mat-body">
    Sorry! This service is unavailable in your area! <br />
    We will notify you when you are able to access the service
  </div>

  <button
    fxFlex
    mat-raised-button
    class="mt-2"
    color="primary"
    (click)="next()">
    {{ screenConfig.primaryButtonCaption }}
  </button>
</div>
