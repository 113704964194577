import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C9_requestPickupChange = {
  steps: 2, // used for the progress indicator on each screen
  C9001: {
    id: appParam.routing.C8_requestPickup.id + '001',
    progressTitle: 'Request change',
    progressValue: () => (100 / C9_requestPickupChange.steps) * 1,
    title: 'Need to change your date?',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C9_requestPickupChange.C9002
  },
  C9002: {
    id: appParam.routing.C8_requestPickup.id + '002',
    progressTitle: 'Collection',
    progressValue: () => (100 / C9_requestPickupChange.steps) * 1,
    title: 'When would you like us to collect the bin?',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C9_requestPickupChange.C9003
  },
  C9003: {
    id: appParam.routing.C8_requestPickup.id + '003',
    progressTitle: 'Collection',
    progressValue: () => (100 / C9_requestPickupChange.steps) * 2,
    title: 'Thanks for requesting a collection!',
    primaryButtonCaption: 'Done',
    next: appParam.routing.C1_signUp.C1005
  }
};
