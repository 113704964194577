<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxLayout="column" class="px-4">
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Subscription</span>
    <span fxFlex class="dc-primary mat-h3">{{
      this.orderSummary.subscription_type.description
    }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Type</span>
    <span fxFlex class="dc-primary mat-h3">{{
      this.orderSummary.container_type.description
    }}</span>
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Payout</span>
    <span
      fxFlex
      class="dc-primary mat-h3"
      *ngIf="this.orderSummary.subscription_type.description == 'Basic'"
      >$0.06 / container</span
    >
    <span
      fxFlex
      class="dc-primary mat-h3"
      *ngIf="this.orderSummary.subscription_type.description == 'Premium'"
      >$0.07 / container</span
    >
    <span fxFlex class="dc-primary-variant mat-h4 mb-0">Account number</span>
    <span fxFlex class="dc-primary mat-h3">{{
      this.censoredAccountNumber
    }}</span>
  </div>

  <div fxLayout="column">
    <button mat-raised-button class="mt-2" color="primary" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
