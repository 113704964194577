import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAddress, IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ItemPickerComponent } from 'src/app/Shared/item-picker/item-picker.component';

@Component({
  selector: 'app-uxc5001',
  templateUrl: './uxc5001.component.html',
  styleUrls: ['./uxc5001.component.scss']
})
export class Uxc5001Component implements OnInit {
  _style: string = '';

  primarySub: Subscription | undefined;

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  selectedAddress: IAddress = {
    address: '',
    suburb: '',
    lat: 0,
    lng: 0,
    postcode: 0
  };
  items: any[];

  selectedItem: IOptionType = { key: '', description: '', checked: true };
  containerType: IOptionType = { key: '', description: '' };
  subscriptionType: IOptionType = { key: '', description: '' };
  orderType: IOptionType = { key: '', description: '' };

  @ViewChild('addressPicker') addpicker: ItemPickerComponent | undefined;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    // get addresses from the backend
    this.items = await this.appApi.getCustomerAddresses();

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getOrderSummary();

    // set default at initial load
    if (this.items.length > 0 && workflowData.address.key == '') {
      this.items[0].checked = true;
      this.selectedItem.key = this.items[0].key;
      this.selectedItem.description = this.items[0].description;

      this.selectedAddress.address = this.items[0].description;
      this.selectedAddress.suburb = this.items[0].suburb;
      this.selectedAddress.lat = this.items[0].lat;
      this.selectedAddress.lng = this.items[0].lng;
    } else {
      //  else use address from session
      this.selectedItem.key = workflowData.address.key;
      this.selectedItem.description = workflowData.address.street_address;
      this.selectedItem.checked = true;

      this.selectedAddress.address = this.items[0].description;
      this.selectedAddress.suburb = this.items[0].suburb;
      this.selectedAddress.lat = this.items[0].lat;
      this.selectedAddress.lng = this.items[0].lng;
    }

    // check the selected address
    this.items = await appUtils.setOptionsDefault(
      this.items,
      this.selectedItem.key
    );

    // get default container details and store in workflow object
    let resContainerType = await this.appApi.getContainerTypes(
      appParam.containerCategory.bin
    );
    if (resContainerType.length > 0) {
      this.containerType.key = resContainerType[0].key;
      this.containerType.description = resContainerType[0].description;
    }

    // get subscription type details and store in workflow object
    let resSubscriptionType = await this.appApi.getSubscriptionTypes(
      this.appService.UserObject.userType
    );
    if (resSubscriptionType.length > 0) {
      this.subscriptionType.key = resSubscriptionType[0].key;
      this.subscriptionType.description = resSubscriptionType[0].description;
      this.subscriptionType.bin_fee = resSubscriptionType[0].bin_fee;
    }

    // get order type details and store in workflow object
    let resOrderType = await this.appApi.getOrderTypes(appParam.orderType.drop);
    if (resOrderType.length > 0) {
      this.orderType.key = resOrderType[0].key;
      this.orderType.description = resOrderType[0].description;
    }

    this.appService.appSpinner(false);
  }

  async addressPicked(event: IOptionType) {
    await this.getSelectedAddressDetails(event.key);
    await this.save();
  }

  async addressEntered(event: IAddress) {
    this.selectedAddress = event;
    let newAddress = true;

    // iterate and check if the address is already existing in the list
    await this.items.forEach((item) => {
      if (item.description == event.address) {
        newAddress = false;
        return;
      }
    });

    //  if it's a new address, then send it to the database
    if (newAddress) {
      const address = {
        userId: this.appService.UserObject.userId,
        address: event.address,
        suburb: event.suburb,
        lat: event.lat,
        lng: event.lng
      };
      const resNewAddress = await this.appApi.updateCustomerAddress(address);

      // get the key from the newly created record in the db
      this.items.push({
        key: resNewAddress.id,
        description: resNewAddress.desc,
        checked: true,
        postcode: resNewAddress.post_code,
        state: resNewAddress.state,
        lat: resNewAddress.lat,
        lng: resNewAddress.lng
      });

      appUtils.updateSelectedItem(this.items, resNewAddress.id);
      await this.getSelectedAddressDetails(resNewAddress.id);
      await this.save();
    }
  }

  // when the user selects an address from the list, get the postcode and state
  //  and update the screen variables
  async getSelectedAddressDetails(_key: any) {
    const addressDetails = await appUtils.searchInArray(
      this.items,
      'key',
      _key
    );

    this.selectedItem.key = addressDetails.key;
    this.selectedItem.description = addressDetails.description;
    this.selectedAddress.address = addressDetails.description;
    this.selectedAddress.suburb = addressDetails.suburb;
    this.selectedAddress.lat = addressDetails.lat;
    this.selectedAddress.lng = addressDetails.lng;
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();

    if (this.authService.getUserType() == appParam.userType.charity) {
      this.router.navigate(['/' + this.workflowStep.next.charity], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/' + this.workflowStep.next.personal], {
        relativeTo: this.route
      });
    }
  }

  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        address: {
          key: this.selectedItem.key,
          description: this.selectedItem.description,
          suburb: this.selectedAddress.suburb,
          lat: this.selectedAddress.lat,
          lng: this.selectedAddress.lng
        },

        subscription_type: {
          key: this.subscriptionType.key,
          description: this.subscriptionType.description,
          bin_fee: this.subscriptionType.bin_fee
        },

        order_type: {
          key: this.orderType.key,
          description: this.orderType.description
        },

        container_type: {
          key: this.containerType.key,
          description: this.containerType.description
        },

        qty: 1
      }
    });

    //  update the subscription type based on user type
    this.appService.updateWorkflowData({
      workflowId: appWorkflowData.C5_orderBinFullPayment.C5002.id,
      workflowData: {
        subscription_type: {
          key: this.authService.getUserType(),
          description: ''
        }
      }
    });
  }
}
