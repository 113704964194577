import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ICustomerOrder } from '../../helper/appInterfaces';

@Component({
  selector: 'app-uxc8005',
  templateUrl: './uxc8005.component.html',
  styleUrls: ['./uxc8005.component.scss']
})
export class Uxc8005Component implements OnInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  workflowData: any;
  orderResult: any;

  selectedDate: any = '';
  mobile: string = '';
  address: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C8_requestPickup.C8005;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;
  }

  async ngOnInit(): Promise<void> {
    this.workflowData = await this.appService.getRequestPickupSummary();

    this.address = this.workflowData.picked_orders[0].address.street_address;

    if (this.workflowData.selected_date.key != '') {
      let dt = new Date(this.workflowData.selected_date.key);
      this.selectedDate = appUtils.getDayofWeek(dt);
      this.selectedDate =
        this.selectedDate + ' ' + formatDate(dt, 'dd MMM', 'en-US');
    }

    this.mobile = appUtils.censorWord(this.appService.UserObject.mobile, 2);
    this.appService.appSpinner(false);

    this.appService.appSpinner(true);
    this.getData();
  }

  async getData() {

    let textMessage =
      'Thank you for requesting a pickup! Your bin will be collected on ' +
      this.selectedDate +
      ' from ' +
      this.address;
    let date = new Date(this.workflowData.selected_date.key);
    let order: ICustomerOrder = {
      customerId: this.appService.UserObject.userId,
      selectedDate: date.toLocaleDateString('en-CA'),
      bins_to_drop_off: this.workflowData.picked_orders[0].qty,
      bins_to_pick_up: this.workflowData.picked_orders[0].qty
    } as ICustomerOrder;
    try {
      const res = await this.appApi.createCustomerChangeoverOrder(order);
      const sendSMS = await this.appApi.sendSMS(
        textMessage,
        this.appService.UserObject.mobile
      );
    } catch (err) {
      console.log(err);
    }
    this.appService.appSpinner(false);
  }

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }
}
