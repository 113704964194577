<mat-form-field appearance="outline" class="my-form-field">
  <mat-label>Your address</mat-label>
  <input
    type="text"
    data-cy="addressInput"
    matInput
    (keydown.enter)="$event.preventDefault()"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    #search />
  <mat-error>Required</mat-error>
</mat-form-field>
