<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <div fxFlex>
    Congratulations !<br /><br />
    You have successfully signed-up<br />
    Please check your inbox for the confirmation email and click the link in it
    to activate your account
  </div>

  <button
    fxFlex
    mat-raised-button
    class="mt-2"
    color="primary"
    (click)="next()">
    {{ screenConfig.primaryButtonCaption }}
  </button>
</div>
