import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C1_signUp = {
  steps: 5, // used for the progress indicator on each screen
  C1001: {
    id: appParam.routing.C1_signUp.id + '001',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 1,
    title: 'Create your account',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C1_signUp.C1002,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1001b: {
    id: appParam.routing.C1_signUp.id + '001',
    progressTitle: 'Change password',
    progressValue: () => 100,
    title: 'Change your password',
    primaryButtonCaption: 'Confirm',
    next: appParam.routing.C2_signIn.C2001,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1002: {
    id: appParam.routing.C1_signUp.id + '001',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 1,
    title: 'Verify your account',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C1_signUp.C1004,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004: {
    id: appParam.routing.C1_signUp.id + '004',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 2,
    title: 'Tell us where your Direct Collect bin will be kept',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C1_signUp.C1004c,
    unavailable: appParam.routing.C1_signUp.C1003,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004c: {
    id: appParam.routing.C1_signUp.id + '004c',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 3,
    title: 'Which plan would you like?',
    primaryButtonCaption: 'Next',
    next: {
      personal: appParam.routing.C1_signUp.C1003,
      charity: appParam.routing.C1_signUp.C1004d
    },
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004d: {
    id: appParam.routing.C1_signUp.id + '004d',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 4,
    title: 'Choose a charity or club',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C1_signUp.C1004dConfirm,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004dConfirm: {
    id: appParam.routing.C1_signUp.id + '004d-confirm',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 4,
    title: 'Confirm charity or club',
    primaryButtonCaption: 'Confirm',
    next: appParam.routing.C1_signUp.C1003,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004f: {
    id: appParam.routing.C1_signUp.id + '004f',
    progressTitle: 'Subscription',
    progressValue: () => (100 / C1_signUp.steps) * 4,
    title: 'Subscription is complete',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C2_signIn.C2001,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1003: {
    id: appParam.routing.C1_signUp.id + '003',
    progressTitle: 'Sign up',
    progressValue: () => (100 / C1_signUp.steps) * 5,
    title: 'Congratulations!',
    primaryButtonCaption: 'Login',
    next: appParam.routing.C2_signIn.C2001,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C1004g: {
    id: appParam.routing.C1_signUp.C1004g,
    progressTitle: 'Unavailable',
    progressValue: () => 100,
    title: 'Unavailable in your area',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C1_signUp.C1003,
    cancel: appParam.routing.C2_signIn.C2001
  }
};
