<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="{{ this._style }}-text-title pt-4">
        Forgot your email address?
      </div>
      <div class="{{ this._style }}-text-subtitle pt-2 pb-3">
        Enter your mobile number and we'll send you a 6-digit code"
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col">Mobile number</div>
    <div class="col-1"></div>
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col">
      <mat-form-field class="{{ this._style }}-form-field" appearance="fill">
        <input matInput value="" id="userId" [(ngModel)]="this.mobile" />
      </mat-form-field>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
        (click)="sendCode()">
        Next
      </button>
    </div>
  </div>
</div>
