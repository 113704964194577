import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { alertAttributes } from '../helper/appAlert';

import { IAppNotification, IWorkflowData } from '../helper/appInterfaces';
import { appParam } from '../helper/appSettings';
import { appWorkflowData } from '../helper/appWorkflowData';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  /**
   * Store the configuration data for each workflow step. This should be used
   * for each of the sign up screens so we can use a consistent interface
   * between them all.
   */
  public workflowStepConfig = {
    headerTitle: '',
    progressTitle: '',
    progressValue: 0,
    workflowStep: '',
    primaryButtonCaption: ''
  };

  /**
   * Keep track of the users' details throughout the whole application. This
   * will allow us to easily display and attach the users' data to requests.
   */
  public UserObject = {
    userType: '',
    charityId: '',
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    taxInvoiceEmail: '',
    mobile: '',
    userConfirmed: false,
    locationType: '',
    location: {
      address: '',
      lat: 0,
      lng: 0
    }
  };

  // 2021-10-25 CJ: API + Cognito auth integration
  public authToken: any;

  // store the workflow data
  private workflow_C1 = appWorkflowData.C1_signUp;
  private workflow_C5 = appWorkflowData.C5_orderBinFullPayment;
  private workflow_C7 = appWorkflowData.C7_orderBinCharity;

  private workflow_SignUpSummary = appWorkflowData.SignUpSummary;
  private workflow_OrderSummary = appWorkflowData.OrderSummary;
  private workflow_RequestPickupSummary = appWorkflowData.RequestPickupSummary;
  private workflow_RequestPickupChangeSummary =
    appWorkflowData.RequestPickupChangeSummary;

  constructor() {}

  /*
   | --------------------------------------------------------------------------
   |  Application Wide Notifications
   | --------------------------------------------------------------------------
   |
   |  THis is the central place that notifications will be sent to. This is
   |  to ensure they follow a consistent interface or format so that they
   |  can be displayed in a predictable manner.
   |  
   */

  private appNotification = new BehaviorSubject({} as IAppNotification);

  public currentAppNotification = this.appNotification.asObservable();

  /**
   * Handle passing the current notification into the queue to be displayed.
   *
   * @param notification The notification to present to the user.
   */
  public sendNotification(notification: IAppNotification): void {
    /**
     * During the customer sign-up process, we want to pass the customer
     * details to the backend as we show the notification.
     */
    if (
      notification.workflow == alertAttributes.workflowSteps.C1_signUp.C1001
    ) {
      this.UserObject.userType = notification.body['userType'];
      this.UserObject.charityId = notification.body['charityId'];
      this.UserObject.userId = notification.body['userId'];
      this.UserObject.firstName = notification.body['firstName'];
      this.UserObject.lastName = notification.body['lastName'];
      this.UserObject.email = notification.body['email'];
      this.UserObject.mobile = notification.body['mobile'];
    }

    /**
     * Save the customers location from the sign up screen. We will be
     * processing this data once they have signed in.
     */
    if (
      notification.workflow == alertAttributes.workflowSteps.C1_signUp.C1004
    ) {
      this.UserObject.location.address = notification.body.location['address'];
      this.UserObject.location.lat = notification.body.location['lat'];
      this.UserObject.location.lng = notification.body.location['lng'];
      this.UserObject.locationType = notification.body['locationTypeCode'];
    }

    if (
      notification.workflow == alertAttributes.workflowSteps.C1_signUp.C1004b
    ) {
      this.UserObject.locationType = notification.body['locationTypeCode'];
    }

    if (
      notification.workflow ==
      alertAttributes.workflowSteps.C4_forgotPassword.C4001
    ) {
      this.UserObject.userId = notification.body['email'];
      this.UserObject.email = notification.body['email'];
    }

    /**
     * Set the users' data into the app once they have logged in. We want
     * to be sure that it is always available throughout all of the pages
     * and components.
     */
    if (
      notification.workflow == alertAttributes.workflowSteps.C2_signIn.C2001
    ) {
      this.UserObject.userId = notification.body['email'];
      this.UserObject.email = notification.body['email'];
      this.UserObject.firstName = notification.body['name'];
      this.UserObject.lastName = notification.body['family_name'];
      this.UserObject.charityId = notification.body['custom:charityId'];
      this.UserObject.userType = notification.body['custom:userType'];
      this.UserObject.taxInvoiceEmail =
        notification.body['custom:taxInvoiceEmail'];
      this.UserObject.mobile = notification.body['phone_number'];
    }

    this.appNotification.next(notification);
  }

  /*
   | --------------------------------------------------------------------------
   |  Application Wide Spinner
   | --------------------------------------------------------------------------
   |
   |  Allow easy access to a spinner that will block the users' input where
   |  required. This will allow us to ensure only one action is completed
   |  at a time when an asynchronous request is taking place.
   |
   */

  private appSpinnerStatus = new BehaviorSubject(false);

  public currentAppSpinner = this.appSpinnerStatus.asObservable();

  /**
   * Handle activating the whole page spinner. This will result in the page
   * being covered with a spinner to prevent any actions from taking place
   * while we wait for a server side request to complete.
   *
   * @param value The active statue to set the spinner to. `True` will activate
   *  the spinner; `False` deactivates it.
   */
  public appSpinner(value: boolean): void {
    this.appSpinnerStatus.next(value);
  }

  /*
   | --------------------------------------------------------------------------
   |  Application Wide Video
   | --------------------------------------------------------------------------
   |
   |  Handle adding a way of showing an application wide splash video to the
   |  customer. This will be overlayed on the current page and will not be
   |  set to hide unless it is hidden manually.
   |
   */

  private splashVideoStatus = new BehaviorSubject(false);

  public currentSplashVideo = this.splashVideoStatus.asObservable();

  /**
   * Handle toggling the video to the user.
   *
   * @param value The active status we wish to set the video to do. `True` will
   *  display the video to the user; `False`, the opposite.
   */
  public splashVideo(value: boolean): void {
    this.splashVideoStatus.next(value);
  }

  //	used to share workflow data across screens
  //	------------------------------------------------------
  private appWorkflow = new BehaviorSubject({
    workflowId: '',
    workflowData: {}
  });

  public currentWorkflow = this.appWorkflow.asObservable();

  /**
   * Handle updating the workflow data for whatever part of the application
   * we are in.
   *
   * @param value The workflow data to update to.
   */
  public updateWorkflowData(value: IWorkflowData): void {
    //  --------------------------- Workflow C1
    //  ---------------------------------------------------------------------
    if (
      value.workflowId == appWorkflowData.C1_signUp.C1004.id ||
      value.workflowId == appWorkflowData.C1_signUp.C1004c.id ||
      value.workflowId == appWorkflowData.C1_signUp.C1004d.id ||
      value.workflowId == appWorkflowData.C1_signUp.C1003.id
    ) {
      if (value.workflowData['subscription_type'] != undefined) {
        this.workflow_SignUpSummary.subscription_type.key =
          value.workflowData.subscription_type.key;
        this.workflow_SignUpSummary.subscription_type.description =
          value.workflowData.subscription_type.description;
      }
      if (value.workflowData['charity'] != undefined) {
        this.workflow_SignUpSummary.charity = value.workflowData.charity;
      }
      if (value.workflowData['taxInvoiceEmail'] != undefined) {
        this.workflow_SignUpSummary.taxInvoiceEmail =
          value.workflowData.taxInvoiceEmail;
      }
      if (value.workflowData['address'] != undefined) {
        this.workflow_SignUpSummary.address = value.workflowData.address;
      }
    }

    //  --------------------------- Workflow C5 & C7
    //  ---------------------------------------------------------------------
    if (
      value.workflowId == appWorkflowData.C5_orderBinFullPayment.C5001.id ||
      value.workflowId == appWorkflowData.C5_orderBinFullPayment.C5003.id ||
      value.workflowId == appWorkflowData.C5_orderBinFullPayment.C5004.id ||
      value.workflowId == appWorkflowData.C5_orderBinFullPayment.C5007.id ||
      value.workflowId == appWorkflowData.C5_orderBinFullPayment.C5009.id ||
      value.workflowId == appWorkflowData.C7_orderBinCharity.C7001.id
    ) {
      if (value.workflowData['qty'] != undefined) {
        this.workflow_OrderSummary.qty = value.workflowData.qty;
      }

      if (value.workflowData['address'] != undefined) {
        this.workflow_OrderSummary.address.key = value.workflowData.address.key;
        this.workflow_OrderSummary.address.street_address =
          value.workflowData.address.street_address;
        this.workflow_OrderSummary.address.suburb =
          value.workflowData.address.suburb;
        this.workflow_OrderSummary.address.lat = value.workflowData.address.lat;
        this.workflow_OrderSummary.address.lng = value.workflowData.address.lng;
      }

      if (value.workflowData['order_type'] != undefined) {
        this.workflow_OrderSummary.order_type.key =
          value.workflowData.order_type.key;
        this.workflow_OrderSummary.order_type.description =
          value.workflowData.order_type.description;
      }

      if (value.workflowData['container_type'] != undefined) {
        this.workflow_OrderSummary.container_type.key =
          value.workflowData.container_type.key;
        this.workflow_OrderSummary.container_type.description =
          value.workflowData.container_type.description;
      }

      if (value.workflowData['selected_date'] != undefined) {
        this.workflow_OrderSummary.selected_date.key =
          value.workflowData.selected_date.key;
        this.workflow_OrderSummary.selected_date.description =
          value.workflowData.selected_date.description;
      }

      if (value.workflowData['payment_type'] != undefined) {
        this.workflow_OrderSummary.payment_type.key =
          value.workflowData.payment_type.key;
        this.workflow_OrderSummary.payment_type.description =
          value.workflowData.payment_type.description;
      }
      console.log('Updating payment details in workflow');

      if (value.workflowData['payment_details'] != undefined) {
        this.workflow_OrderSummary.payment_details.bsb =
          value.workflowData.payment_details.bsb;
        this.workflow_OrderSummary.payment_details.account_name =
          value.workflowData.payment_details.account_name;
        this.workflow_OrderSummary.payment_details.account_number =
          value.workflowData.payment_details.account_number;
        this.workflow_OrderSummary.payment_details.bank_name =
          value.workflowData.payment_details.bank_name;
      }

      if (value.workflowData['subscription_type'] != undefined) {
        this.workflow_OrderSummary.subscription_type.key =
          value.workflowData.subscription_type.key;
        this.workflow_OrderSummary.subscription_type.description =
          value.workflowData.subscription_type.description;
        this.workflow_OrderSummary.subscription_type.bin_fee =
          value.workflowData.subscription_type.bin_fee;
        this.workflow_OrderSummary.subscription_type.membership_fee =
          value.workflowData.subscription_type.membership_fee;
      }

      if (value.workflowData['charity'] != undefined) {
        this.workflow_OrderSummary.charity.key = value.workflowData.charity.key;
        this.workflow_OrderSummary.charity.description =
          value.workflowData.charity.description;

        //  store the charity details for summary screen - prevent another api call
        this.workflow_OrderSummary.charity.charityObject =
          value.workflowData.charity.charityObject;
      }

      if (value.workflowData['taxInvoiceEmail'] != undefined) {
        this.workflow_OrderSummary.taxInvoiceEmail =
          value.workflowData.taxInvoiceEmail;
      }

      if (value.workflowData['notes'] != undefined) {
        this.workflow_OrderSummary.notes = value.workflowData.notes;
      }
    }

    //  --------------------------- Workflow C8
    //  ---------------------------------------------------------------------
    if (
      value.workflowId == appWorkflowData.C8_requestPickup.C8001.id ||
      value.workflowId == appWorkflowData.C8_requestPickup.C8003.id
    ) {
      if (value.workflowData['subscription_type'] != undefined) {
        this.workflow_RequestPickupSummary.subscription_type.key =
          value.workflowData.subscription_type.key;
        this.workflow_RequestPickupSummary.subscription_type.description =
          value.workflowData.subscription_type.description;
      }

      if (value.workflowData['order_type'] != undefined) {
        this.workflow_RequestPickupSummary.order_type.key =
          value.workflowData.order_type.key;
        this.workflow_RequestPickupSummary.order_type.description =
          value.workflowData.order_type.description;
      }

      if (value.workflowData['picked_orders'] != undefined) {
        this.workflow_RequestPickupSummary.picked_orders =
          value.workflowData.picked_orders;
      }

      if (value.workflowData['selected_date'] != undefined) {
        this.workflow_RequestPickupSummary.selected_date.key =
          value.workflowData.selected_date.key;
        this.workflow_RequestPickupSummary.selected_date.description =
          value.workflowData.selected_date.description;
      }

      if (value.workflowData['notes'] != undefined) {
        this.workflow_RequestPickupSummary.notes = value.workflowData.notes;
      }
    }

    //  --------------------------- Workflow C9
    //  ---------------------------------------------------------------------
    if (
      value.workflowId == appWorkflowData.C9_requestPickupChange.C9001.id ||
      value.workflowId == appWorkflowData.C9_requestPickupChange.C9002.id
    ) {
      if (value.workflowData['selected_date'] != undefined) {
        this.workflow_RequestPickupChangeSummary.selected_date.key =
          value.workflowData.selected_date.key;
        this.workflow_RequestPickupChangeSummary.selected_date.description =
          value.workflowData.selected_date.description;
      }

      if (value.workflowData['picked_request'] != undefined) {
        this.workflow_RequestPickupChangeSummary.picked_request =
          value.workflowData.picked_request;
      }

      if (value.workflowData['orderStatus'] != undefined) {
        this.workflow_RequestPickupChangeSummary.orderStatus =
          value.workflowData.orderStatus;
      }

      if (value.workflowData['notes'] != undefined) {
        this.workflow_RequestPickupChangeSummary.notes =
          value.workflowData.notes;
      }

      if (value.workflowData['selectedDateSame'] != undefined) {
        this.workflow_RequestPickupChangeSummary.selectedDateSame =
          value.workflowData.selectedDateSame;
      }
    }
    this.appWorkflow.next(value);
  }

  public getC1WorkflowData() {
    return this.workflow_C1;
  }

  public getC5WorkflowData() {
    return this.workflow_C5;
  }

  public getSignUpSummary() {
    return this.workflow_SignUpSummary;
  }

  public getOrderSummary() {
    return this.workflow_OrderSummary;
  }

  public getRequestPickupSummary() {
    return this.workflow_RequestPickupSummary;
  }

  public getRequestPickupChangeSummary() {
    return this.workflow_RequestPickupChangeSummary;
  }

  /*
   | --------------------------------------------------------------------------
   |  Application Cache
   | --------------------------------------------------------------------------
   |
   |  The methods below provide a way to interface with the application cache
   |  layer. This allows us to share common data between different pages and
   |  elements in a simple to usse, yet common, inteface.
   |
   */

  /**
   * Handle storing an item with an associated unique identifier for use later.
   *
   * @param key The unique identifier to store the data against.
   * @param data The data to store against the key.
   */
  public updateCache(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error: any) {
      console.error(error.message);
    }
  }

  /**
   * Handle returning a local storage item based on the specific key provided.
   *
   * @param key The item to retrieve from local storage.
   *
   * @returns The parsed object or array. Null if there wasn't anything to
   *  return or the `key` parameter does not exist in local storage.
   */
  public getCache<T = any>(key: string): T | null {
    return JSON.parse(localStorage.getItem(key)!);
  }

  /**
   * Clear all items from the local storage cache to instantiate a `fresh`
   * run of the application to a new user.
   */
  public clearCache(): void {
    for (let key in appParam.cacheKeys) {
      localStorage.removeItem(key);
    }
  }
}
