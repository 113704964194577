<div fxLayout="column" class="dc-container">
  <no-back-screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </no-back-screen-header>

  <div fxFlex class="mat-subheading-2 mt-4">
    Is this a residential or business address?
  </div>
  <div fxFlex>
    <app-item-picker [_items]="this.items" (itemPicked)="itemPicked($event)">
    </app-item-picker>
  </div>

  <div fxFlex class="mat-subheading-2 mt-4">Enter the street address:</div>
  <div fxFlex>
    <app-address-picker
      class="address-picker"
      id="addressPicker"
      (addressPicked)="addressPicked($event)"
      (change)="valueChange($event)"></app-address-picker>
  </div>

  <!--  <div fxFlex *ngIf="!!this.address" class="mat-subheading-2 mt-5">Is this a residential or business address?</div>-->
  <!--  <div fxFlex>-->
  <!--    <app-item-picker *ngIf="!!this.address" [_items]="this.items" (itemPicked)="itemPicked($event)"> </app-item-picker>-->
  <!--  </div>-->

  <div fxLayout="column">
    <button
      mat-raised-button
      class="mt-4"
      color="primary"
      [disabled]="!this.address || !selectedItem"
      (click)="next()">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>
</div>
