import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { alertAttributes } from 'src/app/helper/appAlert';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';

import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc1002',
  templateUrl: './uxc1002.component.html',
  styleUrls: ['./uxc1002.component.scss']
})
export class Uxc1002Component implements OnInit {
  /**
   * The screen configuration for the workflow step. This contains each part
   * of the page that is common to the sign in screens.
   */
  public screenConfig: any;

  /** The form binding to easily validate and get access to the form fields. */
  public formGroup!: FormGroup;

  /** The phone number we sent the verification code to. */
  public phone!: string;

  /**
   * The global error handler that will display the errors passed to it.
   */
  private errorHandler: appErrorHandler;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.screenConfig = appWorkflowData.C1_signUp.C1002;

    this.errorHandler = new appErrorHandler(this.appService);
  }

  /**
   * Handle initialising all of the data that is required to load the page.
   * This is where we can check things are in place before proceeding
   * forwards with loading the page/component.
   */
  public ngOnInit(): void {
    /** Ensure the form is set up with all of the validation we require. */
    this.formGroup = this.formBuilder.group({
      verificationCode: ['', this.verificationCodeValidators]
    });

    /** Get the phone number we just sent the verification code to. */
    this.phone = this.appService.UserObject.mobile;
  }

  /**
   * Handle resending the SMS verification code to the new user.
   */
  public async resendCode(): Promise<void> {
    const userId = this.appService.UserObject.userId;

    const result = await this.authService.resendConfirmationCode(userId);

    /** Check that we received a result from the authentication library. */
    if (result) {
      this.appService.sendNotification({
        workflow: alertAttributes.workflowSteps.C1_signUp.C1002,
        message: 'Successfully sent you a new verification code.',
        displayNotification: true
      });
    }
  }

  /**
   * Handle verifying the verification code that was input. We want to
   * ensure that we catch any basic errors, such as field validations
   * that we have set up. Otherwise, we want to pass off the code to
   * Cognito to validate.
   */
  public async verifyCode(): Promise<void> {
    /**
     * Ensure the input field is validated before we continue to process
     * the verification code.
     */
    if (!this.formGroup.valid) {
      this.errorHandler.handleC1Errors({
        type: alertAttributes.types.error,
        displayMessage:
          'Please check that you have entered your verification code correctly.',
        displayNotification: true,
        errorObject: {}
      });

      return;
    }

    this.appService.appSpinner(true);

    const userId = this.appService.UserObject.userId;
    const code = this.formGroup.value.verificationCode;

    if (!environment.production) {
      console.log('code', code);
    }

    /** Try and validate the verification code that we were provided. */
    const result = await this.authService.verifyConfirmationCode(userId, code);

    /**
     * We received a successful result, therefore the account has been
     * activated. We now want to proceed on with the process of
     * setting up the users' account.
     */
    if (result) {
      await this.router.navigate([`/${this.screenConfig.next}`], {
        relativeTo: this.route
      });
    }

    this.appService.appSpinner(false);
  }

  /**
   * Get all of the verification code errors. This object will return objects
   * containing the errors if they have occurred.
   *
   * Errors to check for:
   *  - `required` => The input must be filled in.
   *  - `pattern` => Exactly 6 digits need to be entered.
   *
   * @returns The list of validation errors that have occurred.
   */
  public get verificationCodeError(): ValidationErrors | null {
    return this.formGroup.controls.verificationCode.errors;
  }

  /**
   * Define the array of verification code validators that will be used.
   *
   * @returns The array of validators.
   */
  private get verificationCodeValidators(): any[] {
    return [
      Validators.required,
      Validators.pattern(/[0-9]{6}/),
      Validators.maxLength(6)
    ];
  }
}
