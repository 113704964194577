<form [formGroup]="formGroup" fxLayout="column" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Old Password</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        id="oldPassword"
        formControlName="oldPassword" />
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error>required</mat-error>
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>New Password</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        id="newPassword"
        formControlName="newPassword" />
      <mat-error *ngIf="this.formGroup.controls.newPassword.errors?.required"
        >required</mat-error
      >
      <mat-error *ngIf="this.formGroup.controls.newPassword.errors?.pattern">
        minimum 8 characters with at least 1 number, 1 special char, 1 upper
        case
      </mat-error>
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Confirm New Password</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        id="confirmNewPassword"
        formControlName="confirmNewPassword" />
      <mat-error
        *ngIf="this.formGroup.controls.confirmNewPassword.errors?.required"
        >required</mat-error
      >
      <mat-error
        *ngIf="this.formGroup.controls.confirmNewPassword.errors?.match"
        >doesn't match</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayout="column">
    <button
      mat-raised-button
      data-cy="next"
      class="mt-4"
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="next()"
      [style.width]="'100%'">
      {{ screenConfig.primaryButtonCaption }}
    </button>
  </div>
</form>
