import { Component, OnInit } from '@angular/core';
import { appParam } from '../../helper/appSettings';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { appWorkflowData } from '../../helper/appWorkflowData';
import { appUtils } from '../../helper/appUtils';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-uxc13001',
  templateUrl: './uxc13001.component.html',
  styleUrls: ['./uxc13001.component.scss']
})
export class Uxc13001Component implements OnInit {
  _style: string = '';
  _dateFormat: string = appParam.defaultDateFormat;

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  items: any[];

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C13_binStatus.C13001;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    const res = await this.appApi.getCustomerBinHistory();

    this.items = res.map((item: any) => {
      return {
        bin_date: item['event_datetime'],
        bin_code: item['bin_code'],
        bin_status: item['last_status']
      };
    });

    for (let item of this.items) {
      const date = moment(item.bin_date).utc();
      item.bin_date = date.local().format('DD MMM YY');
    }

    this.appService.appSpinner(false);
  }
}
