import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { alertAttributes } from 'src/app/helper/appAlert';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxc5005',
  templateUrl: './uxc5005.component.html',
  styleUrls: ['./uxc5005.component.scss']
})
export class Uxc5005Component implements OnInit, AfterViewInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  orderSummary: any;

  primaryButtonCaption: string;
  submitButtonDisabled: boolean = false;

  stripe: any;
  stripeElements: any;
  cardNumberElement: any;
  cardExpiryElement: any;
  cardCVCElement: any;

  paymentAmount: number = 0;
  memberFee: number = 0;
  binFee: number = 0;
  qty: number = 1;
  subscriptionType: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5005;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.stripe = (<any>window).Stripe(environment.stripe_pk);
  }

  ngOnInit(): void {
    this.getData();
    this.stripeElements = this.stripe.elements();
    var style = {
      base: {
        padding: '25px',
        color: '#6C757D',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#6C757D'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.cardNumberElement = this.stripeElements.create('cardNumber', {
      style: style
    });
    this.cardNumberElement.mount('#card-number');

    this.cardExpiryElement = this.stripeElements.create('cardExpiry', {
      style: style
    });
    this.cardExpiryElement.mount('#card-expiry');

    this.cardCVCElement = this.stripeElements.create('cardCvc', {
      style: style
    });
    this.cardCVCElement.mount('#card-cvc');

    this.cardNumberElement.on('change', function (event: any) {});
  }

  async getData() {
    const workflowData = await this.appService.getOrderSummary();

    this.memberFee = Number(workflowData.subscription_type.membership_fee);
    this.binFee = Number(workflowData.subscription_type.bin_fee);
    this.qty = workflowData.qty;
    this.subscriptionType = workflowData.subscription_type.description;

    this.paymentAmount = this.binFee * (this.qty - 1) + this.memberFee;
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.appService.appSpinner(false);
    });
  }

  async makePayment() {
    this.appService.appSpinner(true);

    // send a request to the BYC backend which sends a request to the Stripe api to
    // create a payment intent
    const paymentIntent = await this.appApi.createPaymentIntent(
      this.paymentAmount,
      this.memberFee,
      this.binFee * (this.qty - 1)
    );

    if (paymentIntent['client_secret'] == undefined) {
      return undefined;
    }

    // now send the payment confirmation request to the Stripe api along with the
    // payment intent secret received from the above step
    return this.stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        card: this.cardNumberElement
      }
    });
  }

  async next() {
    this.submitButtonDisabled = true;
    this.appService.appSpinner(true);
    await this.makePayment().then((payment) => {
      if (payment.hasOwnProperty('paymentIntent')) {
        this.appService.appSpinner(true);
        this.router.navigate(['/' + this.workflowStep.next], {
          relativeTo: this.route
        });
      } else {
        this.appService.sendNotification({
          type: alertAttributes.types.error,
          message: payment.error.message,
          displayNotification: true
        });
        this.submitButtonDisabled = false;
      }
    })

    this.appService.appSpinner(false);
  }
}
