<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="{{ this._style }}-text-title pt-4">Your email address</div>
      <div class="{{ this._style }}-text-subtitle pt-2 pb-3">
        Below is the email address you signed up with
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="{{ this._style }}-text-subtitle pt-2 pb-3">
        a****z@xyz.com
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        class="d-block {{ this._style }}-next {{ this._style }}-primary mt-4"
        (click)="next()">
        Next
      </button>
    </div>
  </div>
</div>
