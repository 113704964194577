<form
  [formGroup]="formGroup"
  fxLayout="column"
  fxLayoutGap="15px"
  class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex *ngIf="!isReadOnly">
    Please provide the bank accounts details.
  </div>
  <div fxFlex *ngIf="isReadOnly">Please confirm the bank account details.</div>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Account Name</mat-label>
    <input
      matInput
      id="accountName"
      placeholder="Names(s) account is held under"
      (change)="this.save()"
      [readonly]="isReadOnly"
      formControlName="accountName" />
    <mat-hint>e.g. John Smith</mat-hint>
    <mat-error>Required</mat-error>
  </mat-form-field>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Account number</mat-label>
    <input
      matInput
      id="accountNumber"
      placeholder="00000000"
      (change)="this.save()"
      [readonly]="isReadOnly"
      formControlName="accountNumber" />
    <mat-hint>e.g. 12345678</mat-hint>
    <mat-error *ngIf="this.formGroup.controls.accountNumber.errors?.required"
      >Required</mat-error
    >
    <mat-error *ngIf="this.formGroup.controls.accountNumber.errors?.pattern"
      >Invalid format</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>BSB</mat-label>
    <input
      matInput
      id="accountBSB"
      placeholder="000-000"
      [readonly]="isReadOnly"
      formControlName="accountBSB" />
    <mat-hint>e.g. 123-456</mat-hint>
    <mat-error *ngIf="this.formGroup.controls.accountBSB.errors?.required"
      >Required</mat-error
    >
    <mat-error *ngIf="this.formGroup.controls.accountBSB.errors?.pattern"
      >Invalid format</mat-error
    >
  </mat-form-field>

  <mat-form-field fxFlex appearance="outline">
    <mat-label>Bank</mat-label>
    <input
      matInput
      id="bankName"
      [(ngModel)]="this.bankName"
      placeholder="Name of bank"
      readonly
      formControlName="bankName" />
    <mat-error>Required</mat-error>
  </mat-form-field>

  <div fxLayout="column">
    <button
      mat-raised-button
      class="mt-2"
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</form>
