<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div class="mb-5">
    <mat-list>
      <mat-list-item *ngFor="let item of this.items">
        <div
          fxFlex
          fxLayout="row"
          fxLayoutGap="5px"
          fxLayoutAlign="space-between center">
          <button
            fxFlex="12"
            mat-mini-fab
            color="accent"
            (click)="decrementValue()">
            <mat-icon> remove </mat-icon>
          </button>
          <span>{{ value }}</span>
          <button
            fxFlex="12"
            mat-mini-fab
            color="accent"
            (click)="incrementValue()">
            <mat-icon> add </mat-icon>
          </button>
          <div fxFlex="60" fxLayoutAlign="center">
            {{ item.description }}
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <!--  <div fxFlex>-->
  <!--    <app-item-picker [_items]="this.items" (itemPicked)="itemPicked($event)"></app-item-picker>-->
  <!--  </div>-->

  <div fxLayout="column">
    <button mat-raised-button class="mt-2" color="primary" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
