import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { TermsAndConditionsComponent } from 'src/app/Shared/terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-uxc1004c',
  templateUrl: './uxc1004c.component.html',
  styleUrls: ['./uxc1004c.component.scss']
})
export class Uxc1004cComponent implements OnInit {
  _style: string = '';

  // screen config data related to the workflow step
  screenConfig: any;

  items: IOptionType[];
  plans: IOptionType[];
  selectedItem: IOptionType = { key: '', description: '', checked: false };
  checked = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004c;

    this.appService.appSpinner(true);
    this.items = [];
    this.plans = [];
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  // openDialog(selectedItem: any): void {
  //   this.dialog.open(Uxc1004cDialogComponent, {
  //     width: '300px',
  //     data: selectedItem
  //   });
  // }

  openTermsAndConditions(): void {
    // this.dialog.open(TermsAndConditionsComponent),
    //   {
    //     width: '300px'
    //   };
    window.open('https://direct-collect.com.au/terms-and-conditions', '_blank');
  }

  async getData() {
    this.plans = await this.appApi.getSubscriptionTypes('', true);
    for (let plan of this.plans) {
      if (plan.displayOnUI === true) {
        this.items.push(plan);
      }
    }

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getSignUpSummary();

    this.items = await appUtils.addToOptionsIfNew(
      this.items,
      workflowData.subscription_type.key,
      workflowData.subscription_type.description
    );

    // sort by descending
    this.items.sort((a, b) => b.description.localeCompare(a.description));

    if (this.items.length > 0 && workflowData.subscription_type.key == '') {
      this.items[0].checked = true;
      this.selectedItem.key = this.items[0].key;
      this.selectedItem.description = this.items[0].description;
    } else {
      this.selectedItem.key = workflowData.subscription_type.key;
      this.selectedItem.description =
        workflowData.subscription_type.description;
      this.selectedItem.checked = true;
    }

    this.appService.appSpinner(false);
  }

  // async onItemClick(item: any) {
  //   this.selectedItem = item;
  // }

  async itemPicked(event: IOptionType) {
    this.selectedItem = event;
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();

    let customerType: any;

    // get the code to assign to the customer details from the selected item
    for (let item of this.items) {
      if (this.selectedItem.key == item.key) {
        customerType = item.code;
      }
    }

    const resUpdateCustomerDetails = await this.appApi.updateCustomerDetails(
      {
        userId: this.appService.UserObject.userId,
        subscriptionId: this.selectedItem.key,
        planTermsAccepted: new Date().toISOString(),
        userPoolId: appParam.auth.userPoolId,
        customerType: customerType
      },
      true
    );

    const resCognito = await this.appApi.updateCognitoUserAttributes(
      {
        userId: this.appService.UserObject.userId,
        subscriptionId: this.selectedItem.key,
        userPoolId: appParam.auth.userPoolId,
        customerType: customerType
      },
      true
    );

    if (resUpdateCustomerDetails.length == 0) {
      this.appService.appSpinner(true);
      return;
    }

    if (customerType == 'CHARITY') {
      this.router.navigate(['/' + this.screenConfig.next.charity], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/' + this.screenConfig.next.personal], {
        relativeTo: this.route
      });
    }
  }

  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.screenConfig.id,
      workflowData: {
        subscription_type: {
          key: this.selectedItem.key,
          description: this.selectedItem.description
        }
      }
    });
  }
}

@Component({
  selector: 'app-uxc1004c-dialog',
  templateUrl: './uxc1004c.dialog.component.html'
})
export class Uxc1004cDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Uxc1004cDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
