import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';

import { alertAttributes } from 'src/app/helper/appAlert';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { IAddress, IOptionType } from 'src/app/helper/appInterfaces';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc1004',
  templateUrl: './uxc1004.component.html',
  styleUrls: ['./uxc1004.component.scss']
})
export class Uxc1004Component implements AfterViewInit {
  // screen config data related to the workflow step
  screenConfig: any;

  _style: string = '';

  items: any[] = [];
  selectedItem!: IOptionType;
  selectedAddress!: IAddress;

  primarySub: Subscription | undefined;

  latitude!: number;
  longitude!: number;
  zoom!: number;
  address!: string;
  suburb!: string;
  postcode!: number;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004;
    this.getData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.appService.appSpinner(false);
    });
  }

  valueChange(newValue: any) {
    if (newValue.target.value != this.address) this.address = '';
  }

  async getData() {
    let locationTypes = await this.appApi.getAddressTypes(true);
    for (let location of locationTypes) {
      if (location.displayOnUI) {
        this.items.push(location);
      }
    }

    if (this.items.length > 0) {
      this.selectedItem = this.items[0];
      this.selectedItem.checked = true;
    }

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getSignUpSummary();

    if (workflowData.address.description !== '') {
      this.address = workflowData.address.description;
      this.latitude = workflowData.address.lat;
      this.longitude = workflowData.address.lng;
      this.suburb = workflowData.address.suburb;

      let element = document.getElementById('addressPicker');
      (element!.querySelector('input') as HTMLInputElement).value =
        this.address;
    }

    this.appService.appSpinner(false);
  }

  async itemPicked(event: IOptionType) {
    this.selectedItem = event;
  }

  async addressPicked(event: IAddress) {
    this.address = (
      document
        .getElementById('addressPicker')!
        .querySelector('input') as HTMLInputElement
    ).value;
    this.latitude = event.lat;
    this.longitude = event.lng;
    this.suburb = event.suburb;
    this.postcode = event.postcode;

    // get the last comma dynamically. Google Places API emits '..., Australia'
    const lastComma = this.address.lastIndexOf(',');
    // join the postcode to the emitted address
    this.address = [
      this.address.slice(0, lastComma),
      ' ',
      this.postcode,
      this.address.slice(lastComma)
    ].join('');
  }

  async next() {
    this.appService.appSpinner(true);

    const address = {
      userId: this.appService.UserObject.userId,
      address: this.address,
      suburb: this.suburb,
      postcode: this.postcode,
      lat: this.latitude,
      lng: this.longitude
    };

    this.appService.updateWorkflowData({
      workflowId: this.screenConfig.id,
      workflowData: {
        address: {
          description: this.address,
          lat: this.latitude,
          lng: this.longitude,
          suburb: this.suburb,
          postcode: this.postcode
        }
      }
    });

    let addressRes = await this.appApi.getCustomerAddressesCheck(
      this.address,
      true
    );
    if (addressRes.length !== 0) {
      this.appService.appSpinner(false);

      this.appApi.errHandler.handleC1Errors({
        displayMessage:
          'This address is already registered. Please use a different address or contact Direct Collect support',
        displayNotification: true,
        type: alertAttributes.types.error,
        errorObject: {},
        action: {
          label: 'Contact Direct Collect Support',
          href: 'https://www.direct-collect.com.au/contact-us'
        }
      });
      return;
    }

    let postcodeRes = await this.appApi.getPostcode(this.postcode);
    if (postcodeRes == null) {
      this.suburb = '';
      address.suburb = '';
    }

    const resUpdateAddress = await this.appApi.updateCustomerAddress(
      address,
      true
    );
    if (resUpdateAddress.length == 0) {
      this.appService.appSpinner(true);
      return;
    }

    // update the database with customer location type Business / Residential
    // and pass the suburb so that the backend logic can assign the suburb area to the customer
    const resUpdateLocationType = await this.appApi.updateCustomerAddressType(
      {
        userPoolId: appParam.auth.userPoolId,
        userId: this.appService.UserObject.userId,
        locationTypeId: this.selectedItem.key,
        suburb: this.suburb
      },
      true
    );

    if (resUpdateLocationType.length == 0) {
      this.appService.appSpinner(true);
      return;
    }

    await this.appService.sendNotification({
      workflow: alertAttributes.workflowSteps.C1_signUp.C1004,
      body: { location: address, locationTypeCode: this.selectedItem },
      displayNotification: false
    });

    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
