import { appParam } from '../appSettings';

export const C4_forgotPassword = {
  steps: 3,
  C4001: {
    id: appParam.routing.C4_forgotPassword.id + '001',
    progressTitle: 'Password',
    progressValue: () => (100 / C4_forgotPassword.steps) * 1,
    title: 'Enter your email address',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C4_forgotPassword.C4002,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C4002: {
    id: appParam.routing.C4_forgotPassword.id + '002',
    progressTitle: 'Password',
    progressValue: () => (100 / C4_forgotPassword.steps) * 2,
    title: 'Enter the six-digit code',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C4_forgotPassword.C4003,
    cancel: appParam.routing.C2_signIn.C2001
  },
  C4003: {
    id: appParam.routing.C4_forgotPassword.id + '003',
    progressTitle: 'Password',
    progressValue: () => (100 / C4_forgotPassword.steps) * 3,
    title: 'Create a new password',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C2_signIn.C2001,
    cancel: appParam.routing.C2_signIn.C2001
  }
};
