import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc1004e',
  templateUrl: './uxc1004e.component.html',
  styleUrls: ['./uxc1004e.component.scss']
})
export class Uxc1004eComponent implements OnInit {
  _style: string = '';
  _charityCode: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.getData();
  }

  ngOnInit(): void {}

  async getData() {
    let res = await this.appApi.getCharities(this._charityCode, true);
    // this.items = res

    // if (this.items.length > 0) {
    //   this.selectedItem = this.items[0]
    // }
    this.appService.appSpinner(false);
  }
}
