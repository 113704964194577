<div fxLayout="column" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        data-cy="password"
        type="password"
        id="password"
        [(ngModel)]="this.newPassword1" />
    </mat-form-field>
  </div>

  <div fxFlex>
    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        data-cy="confirmPassword"
        type="password"
        id="confirmPassword"
        [(ngModel)]="this.newPassword2" />
    </mat-form-field>
  </div>

  <div fxLayout="column">
    <button
      mat-raised-button
      data-cy="next"
      id="signUp"
      class="mt-4"
      color="primary"
      (click)="resetPassword()">
      Next
    </button>
  </div>
</div>
