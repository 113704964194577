<mat-form-field appearance="outline" [style]="{ width: '100%' }">
  <mat-label>Choose a date</mat-label>
  <input
    matInput
    [matDatepickerFilter]="myFilter"
    [matDatepicker]="picker"
    [(ngModel)]="this._selectedDate"
    (dateChange)="datePicked($event)"
    disabled />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    [dateClass]="dateClass"
    #picker
    disabled="false"></mat-datepicker>
</mat-form-field>
