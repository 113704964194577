<!-- 2021-10-17 CJ: Logic update - allow check box control from parent -->
<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue"></screen-header>

  <div *ngIf="order == undefined">
    <div fxFlex class="mat-h3">Order has not been processed yet</div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-10 py-3">
      <div *ngIf="order">
        <div>Status: {{ order['batch_status'] }}</div>
        <div>Refund $: {{ order['batch_refund'] }}</div>
        <div>Qty: {{ order['total_qty'] }}</div>
        <div>Pay method: {{ order['method'] }}</div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row">
    <!-- <div class="col-1"></div> -->
    <div class="col-12 py-3 px-3">
      <div *ngIf="order">
        <!--  -->
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Qty</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let item of order['cdsDetails']">
              <td>{{ item['item'] }}</td>
              <td>{{ item['qty'] }}</td>
            </tr>
          </tbody>
        </table>
        <!--  -->
      </div>
    </div>
    <!-- <div class="col-1"></div> -->
  </div>

  <div fxFlex class="mat-h3" *ngIf="_images.length > 0">Images</div>
  <div fxFlex class="mat-h3" *ngIf="order == undefined && _images.length == 0">
    No images to display
  </div>
  <div class="d-flex flex-wrap">
    <mat-card
      fxLayout="column"
      fxLayoutAlign="start center"
      *ngFor="let item of _images; let i = index"
      class="card-secondary m-1 p-0">
      <img
        [src]="item"
        style="object-fit: contain; height: 75px"
        [alt]="'img bin' + i" />
    </mat-card>
  </div>
</div>
