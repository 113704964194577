import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDarkMode = false;
  private userType = 'PERSONAL';
  private _theme = new BehaviorSubject('');
  theme = this._theme.asObservable();

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
    this.sendThemeToUse();
  }

  setUserTheme(userType: string): void {
    this.userType = userType;
    this.sendThemeToUse();
  }

  sendThemeToUse(): void {
    if (this.isDarkMode) {
      if (this.userType === 'PERSONAL') this._theme.next('personal-dark-theme');
      else this._theme.next('charity-dark-theme');
    } else {
      if (this.userType === 'PERSONAL') this._theme.next('');
      else this._theme.next('charity-theme');
    }
  }
}
