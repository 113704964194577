import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appParam } from 'src/app/helper/appSettings';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uxc5006',
  templateUrl: './uxc5006.component.html',
  styleUrls: ['./uxc5006.component.scss']
})
export class Uxc5006Component implements OnInit, AfterViewInit {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  stripe: any;

  stripeElements: any;

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCVCElement: any;

  card: any;
  paymentIntent: any;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5006;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.stripe = (<any>window).Stripe(environment.stripe_pk);
  }

  ngOnInit(): void {
    this.stripeElements = this.stripe.elements();
    var style = {
      base: {
        padding: '25px',
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.cardNumberElement = this.stripeElements.create('cardNumber', {
      style: style
    });
    this.cardNumberElement.mount('#card-number');

    this.cardExpiryElement = this.stripeElements.create('cardExpiry', {
      style: style
    });
    this.cardExpiryElement.mount('#card-expiry');

    this.cardCVCElement = this.stripeElements.create('cardCvc', {
      style: style
    });
    this.cardCVCElement.mount('#card-cvc');

    this.cardNumberElement.on('change', function (event: any) {});
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.appService.appSpinner(false);
    });
  }

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }
}
