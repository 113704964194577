// 2021-08-12 CJ: This file contains all the attributes used for alerts (info, warning and errors)

import { appParam } from './appSettings';

export const alertAttributes = {
  workflowSteps: appParam.routing,
  types: {
    info: 0,
    success: 1,
    warning: 2,
    error: 3,
    addressTaken: 4
  },
  alerts: {
    C001: 'C001 - Cannot get customer details!',
    C002: 'C002 - Cannot get location types!',
    C003: 'C003 - Cannot get customer address(s)!',
    C004: 'C004 - Cannot get customer orders for pickup!',
    C005: 'C005 - Cannot get customer pickup requests!',
    C006: 'C006 - Cannot get customer order by pickup request!',
    C007: 'C007 - Cannot get subscription types!',
    C008: 'C008 - Cannot get order types!',
    C009: 'C009 - Cannot get available charities!',
    C010: 'C010 - Cannot get available container types!',
    C011: 'C011 - Cannot get available dates for pickup or drop off!',
    C012: 'C012 - Cannot get list of payment types!',
    C013: 'C013 - Cannot get list of BSB!',
    C014: 'C014 - Cannot add/update customer address!',
    C015: 'C015 - Cannot update customer location type!',
    C016: 'C016 - Cannot update customer details!',
    C017: 'C017 - Cannot update customer attributes!',
    C018: 'C018 - Cannot create payment intent!',
    C019: 'C019 - Cannot create order!',
    C020: 'C020 - Cannot update pickup request!',
    C021: 'C021 - Cannot cancel pickup request!',
    C022: 'C022 - Cannot get orders!',
    C023: 'C023 - Cannot register new customer!',
    C024: 'C024 - Cannot resend verification code!  ',
    C025: 'C025 - Cannot authenticate user id and password!  ',
    C026: 'C026 - Cannot initiate forgot password!  ',
    C027: 'C027 - Cannot reset password!  ',
    C028: 'C028 - Cannot get list of announcements!  ',
    C029: 'C029 - Cannot update your password',
    C030: 'C030 - Cannot update your bank details',
    C031: 'C031 - Cannot get manifest media',
    C032: 'C032 - Cannot get suburb',
    C033: 'C033 - Cannot send SMS',
    C034: 'C034 - Cannot update the customer notes',
    C035: 'C035 - Cannot get the customer bin history',
    C036: 'C036 - Cannot get postcode',
    C037: 'C037 - ',
    C038: 'C038 - Cannot get customer account details. This error usually means that your payment details have not succesfully been added to our database. Please wait a few minutes and try again.'
  }
};
