import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { alertAttributes } from '../helper/appAlert';
import { appErrorHandler } from '../helper/appErrorHandler';
import {
  IBSB,
  ICustomerAccount,
  ICustomerOrder,
  IOptionType,
  IUserAttr
} from '../helper/appInterfaces';
import { appParam } from '../helper/appSettings';
import { AppService } from './app.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  errHandler: appErrorHandler;
  apiUrl: string;

  constructor(
    public httpClient: HttpClient,
    private appService: AppService
  ) {
    this.errHandler = new appErrorHandler(this.appService);
    this.apiUrl = appParam.apiBase;
  }

  // 2021-10-25 CJ: API + Cognito auth integration
  async getAuthDocs(
    _method: any,
    _query: any,
    _apiBase: string = ''
  ): Promise<any[]> {
    // switch between Admin and Customer API when required.
    if (_apiBase == '') {
      _apiBase = env.apiBase;
    }
    const res = await this.httpClient
      .post<any>(_apiBase + '/' + _method, _query, {
        headers: new HttpHeaders({
          Authorization: await this.appService.authToken
        })
      })
      .toPromise();

    // console.log('res', res);
    return res;
  }

  async getDocs(
    _method: any,
    _query: any,
    _apiBase: string = '',
    _isSignup: boolean = false
  ): Promise<any[]> {
    // switch between Admin and Customer API when required.
    if (_apiBase == '') {
      _apiBase = this.apiUrl;
    }

    // 2021-11-19 CJ: bug fix for api calls when user is signing up
    let res;

    if (_isSignup == false) {
      res = await this.httpClient
        .post<any>(_apiBase + '/' + _method, _query, {
          headers: new HttpHeaders({
            Authorization:
              this.appService.getCache(appParam.cacheKeys.authToken) ?? ''
          })
        })
        .toPromise();
    } else {
      res = await this.httpClient
        .post<any>(_apiBase + '/' + _method, _query)
        .toPromise();
    }
    return res;
  }

  async sendDocs(
    _method: any,
    _query: any,
    _isSignup: boolean = false
  ): Promise<any[]> {
    // 2021-11-19 CJ: bug fix for api calls when user is signing up
    let res;
    if (_isSignup == false) {
      res = await this.httpClient
        .post<any>(this.apiUrl + '/' + _method, _query, {
          headers: new HttpHeaders({
            Authorization:
              this.appService.getCache(appParam.cacheKeys.authToken) ?? ''
          })
        })
        .toPromise();
    } else {
      res = await this.httpClient
        .post<any>(this.apiUrl + '/' + _method, _query)
        .toPromise();
    }
    return res;
  }

  //  get customer
  async getCustomer(_userId: string): Promise<any[]> {
    return this.getDocs('getCustomer', { body: { userId: _userId } })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C001,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer by email
  async getCustomerByEmail(email: string): Promise<any> {
    return this.getDocs('getCustomer', { body: { email } })
      .then((data) => {
        return data[0].length ? data[0][0] : null;
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C001,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all address types for current tenant
  async getAddressTypes(_isSignup: boolean = false): Promise<IOptionType[]> {
    //  user id is empty since this function is called during sign-up where the user/customer does not
    //  have a region/state assgined yet
    return this.getDocs(
      'getAddressTypes',
      { body: { userId: '' } },
      '',
      _isSignup
    )
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            checked: false,
            displayOnUI: item.display_on_ui
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C002,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getSuburb(_suburb: string): Promise<any> {
    return this.getDocs('getSuburb', { body: { suburb: _suburb } }, '', true)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C032,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getPostcode(_postcode: number): Promise<any> {
    return this.getDocs(
      'getPostcode',
      { body: { postcode: _postcode } },
      '',
      true
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C036,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getBinTransactionLogByOrderId(_orderId: number): Promise<any> {
    return this.getDocs(
      'getBinTransactionLog',
      { body: { orderId: _orderId } },
      '',
      false
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Could not get bin transaction log!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getCurrentBinCount() {
    return this.getDocs(
      'getCurrentBinCount',
      { body: { userId: this.appService.UserObject.userId } },
      '',
      false
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Could not get current bin count!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  // get latest customer transaction
  async getCustomerTransactionLog(_orderId: number) {
    return this.getDocs(
      'getCustomerTransactionLog',
      { body: { orderId: _orderId } },
      '',
      false
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customer transaction log!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  // async getCustomerAccount() {
  //   console.log('getting customer account');
  //   return this.getDocs(
  //     'getCustomerAccount', //HACK : THIS DOESN'T LOOK RIGHT? WHY IS THIS THE METHOD BEING USED WHEN WE'RE WITHIN GET CUSTOMER ACCOUNT? EDit: I fixed it i hope thats secure?
  //     { body: { userId: this.appService.UserObject.userId } },
  //     '',
  //     false
  //   )
  //     .then((data) => {
  //       return data[0];
  //     })
  //     .catch((err) => {
  //       this.errHandler.handleAPIErrors({
  //         type: alertAttributes.types.error,
  //         displayMessage: 'Cannot get customer account!',
  //         displayNotification: true,
  //         errorObject: {}
  //       });
  //       return [];
  //     });
  // }

  async getCustomerHasAccountBoolean() {
    let hasAccount = true;
    return this.getDocs(
      'getHasCustomerAccount',
      { body: { userId: this.appService.UserObject.userId } },
      '',
      false
    )
      .then((data) => {
        console.log('count of customer accounts:', data[0]);
        if (data[0] > 0) {
          console.log('Customer has account:' + hasAccount);
          return hasAccount;
        } else {
          hasAccount = false;
          return hasAccount;
        }
      })
      .catch((err) => {
        hasAccount = false;
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customer account!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all addressed for this customer
  async getCustomerAddresses(_isSignup: boolean = false): Promise<any> {
    return this.getDocs(
      'getCustomerAddresses',
      { body: { userId: this.appService.UserObject.userId, address: '' } },
      '',
      _isSignup
    )
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            street_address: item.street_address,
            refSuburb: item.refSuburb,
            lat: item.lat,
            lng: item.lng,
            notes: item.notes,
            checked: false
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C003,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all addressed for this customer
  async getCustomerAddressesCheck(
    _address: string,
    _isSignup: boolean = false
  ): Promise<any> {
    return this.getDocs(
      'getCustomerAddresses',
      {
        body: { userId: this.appService.UserObject.userId, address: _address }
      },
      '',
      _isSignup
    )
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            street_address: item.street_address
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C003,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer orders for pickup request
  async getCustomerOrdersForPickupRequest(): Promise<any[]> {
    return this.getDocs('getCustomerOrdersForPickups', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C004,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer pickup request
  async getCustomerPickupRequests(_pending: boolean): Promise<any[]> {
    return this.getDocs('getCustomerPickupRequests', {
      body: { userId: this.appService.UserObject.userId, pending: _pending }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C005,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getManifestMedia(_orderId: number): Promise<any[]> {
    return this.getDocs('getManifestMedia', {
      body: { order_id: _orderId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C031,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer orders in a specific pickup request
  async getCustomerOrdersByPickup(_pickupId: string): Promise<any[]> {
    return this.getDocs('getCustomerOrdersByPickup', {
      body: { userId: this.appService.UserObject.userId, pickup_id: _pickupId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C006,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer subscription types
  async getSubscriptionTypes(
    _category: string = '',
    _isSignup: boolean = false
  ): Promise<IOptionType[]> {
    return this.getDocs(
      'getSubscriptionTypes',
      {
        body: { userId: this.appService.UserObject.userId, category: _category }
      },
      '',
      _isSignup
    )
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            body: item.bodyText,
            binFee: item.bin_fee,
            displayOnUI: item.display_on_ui,
            code: item.code
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C007,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer order types
  async getOrderTypes(_category: string = ''): Promise<IOptionType[]> {
    return this.getDocs('getOrderTypes', {
      body: { userId: this.appService.UserObject.userId, category: _category }
    })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C008,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all charities or individual charity by passing the charity CODE NOT ID
  async getCharities(
    _charityId: string = '',
    _isSignup: boolean = false
  ): Promise<IOptionType[]> {
    return this.getDocs(
      'getCharities',
      {
        body: {
          userId: this.appService.UserObject.userId,
          charityId: _charityId
        }
      },
      '',
      _isSignup
    )
      .then((data) => {
        // map the incoming data into Key/Description
        if (data[0].length > 1) {
          //  multiple charities
          return data[0].map((item: any) => {
            return {
              key: item.id,
              description: item.desc,
              body: item.bodyText,
              icon: item.logo,
              refOrganisationType: item.refOrganisationType,
              checked: false
            };
          });
        } else {
          //  single charity
          return data.map((item: any) => {
            return {
              key: item.id,
              description: item.desc,
              body: item.bodyText,
              icon: item.logo,
              refOrganisationType: item.refOrganisationType,
              checked: false
            };
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C009,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  container types (Bins/Drums)
  async getContainerTypes(_code: string = ''): Promise<IOptionType[]> {
    return this.getDocs('getContainerTypes', {
      body: { userId: this.appService.UserObject.userId, code: _code }
    })
      .then((data) => {
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc
          };
        });
      })
      .catch((err) => {
        console.error(err);
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C010,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get all available dates
  async getAvailableDates(
    _refSuburbId: number,
    _firstDrop: boolean
  ): Promise<any[]> {
    return this.getDocs('getAvailableDates', {
      body: {
        userId: this.appService.UserObject.userId,
        refSuburbId: _refSuburbId,
        firstDrop: _firstDrop
      }
    })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) =>
          moment(item.available_dates, 'YYYY-MMM-DD').toDate()
        );
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C011,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer payment types
  async getPaymentTypes(): Promise<IOptionType[]> {
    return this.getDocs('getPaymentTypes', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            key: item.id,
            description: item.desc,
            body: item.bodyText,
            checked: false
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C012,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get BSB listing
  async getBSB(): Promise<IBSB[]> {
    console.log('triggering getbsb function in api.service');
    return this.getDocs('getBSB', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        // map the incoming data into Key/Description
        return data[0].map((item: any) => {
          return {
            bsb: item.code,
            branchName: item.desc,
            bankCode: item.bank_code,
            state: item.state
          };
        });
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C013,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async sendSMS(_message: string, _number: string): Promise<any> {
    return this.sendDocs('sendSMS', {
      body: { message: _message, phoneNumber: _number }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C033,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createCustomer(_data: IUserAttr): Promise<any> {
    return this.sendDocs(
      'createCustomer',
      {
        body: _data
      },
      true
    );
  }

  async updateCustomerAddress(
    _data: any,
    _isSignup: boolean = false
  ): Promise<any> {
    return this.getDocs('updateCustomerAddress', { body: _data }, '', _isSignup)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C014,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCustomerNotes(
    _notes: string,
    _isSignup: boolean = false
  ): Promise<any> {
    return this.getDocs(
      'updateCustomerNotes',
      { body: { notes: _notes, userId: this.appService.UserObject.userId } },
      '',
      _isSignup
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C034,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCustomerAddressType(
    _data: any,
    _isSignup: boolean = false
  ): Promise<any> {
    return this.getDocs(
      'updateCustomerAddressType',
      { body: _data },
      '',
      _isSignup
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C015,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCustomerDetails(
    _data: any,
    _isSignup: boolean = false
  ): Promise<any> {
    console.log('triggering update customer details function in api.service');

    return this.getDocs('updateCustomerDetails', { body: _data }, '', _isSignup)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C016,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCognitoUserAttributes(
    _data: any,
    _isSignup: boolean = false
  ): Promise<any> {
    return this.getDocs(
      'updateCognitoUserAttributes',
      { body: _data },
      '',
      _isSignup
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C017,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createPaymentIntent(
    _amount: number,
    _membershipFee: number,
    _additionalFee: number
  ): Promise<any> {
    console.log('triggering create payment intent function in api.service');

    return this.getDocs('createPaymentIntent', {
      body: {
        userId: this.appService.UserObject.userId,
        amount: _amount,
        membershipFee: _membershipFee,
        additionalFee: _additionalFee
      }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C018,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createCustomerOrder(_order: ICustomerOrder): Promise<any> {
    console.log('triggering create customer order function in api.service');
    const payload = {
      body: { userId: this.appService.UserObject.userId, order: _order }
    };

    return this.getDocs('createCustomerOrder', payload)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C019,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async createCustomerChangeoverOrder(_order: ICustomerOrder): Promise<any> {
    const payload = {
      body: {
        userId: this.appService.UserObject.userId,
        order: _order,
        changeover: true
      }
    };

    return this.getDocs('createCustomerOrder', payload)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C019,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCustomerPickupRequest(
    _oldOrderId: any,
    _selectedDate: any,
    _binsToDrop: any,
    _binsToPick: any,
    _orderType: any,
    _notFirstOrder: boolean
  ): Promise<any> {
    const payload = {
      body: {
        userId: this.appService.UserObject.userId,
        oldOrderId: _oldOrderId,
        order: {
          selectedDate: _selectedDate,
          bins_to_drop_off: _binsToDrop,
          bins_to_pick_up: _binsToPick,
          orderType: _orderType,
          notFirstOrder: _notFirstOrder
        }
      }
    };

    return this.getDocs('updateCustomerPickupRequest', payload)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C020,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async cancelCustomerPickupRequest(_requestId: any): Promise<any> {
    const payload = {
      body: {
        userId: this.appService.UserObject.userId,
        pickup_request_id: _requestId,
        selected_date: ''
      }
    };

    return this.getDocs('updateCustomerPickupRequest', payload)
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C021,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer subscription (order)
  async getOrder(_id: string = ''): Promise<any[]> {
    return this.getDocs(
      'getOrders',
      { body: { userId: this.appService.UserObject.userId, order_id: _id } },
      ''
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get customer subscription (order)
  async getCustomerOrders(): Promise<any[]> {
    return this.getDocs('getCustomerOrders', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getCustomerBinHistory(): Promise<any[]> {
    return this.getDocs('getBinHistory', {
      body: { userId: this.appService.UserObject.userId }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async getLatestCustomerOrder(): Promise<any> {
    return this.getDocs('getCustomerOrders', {
      body: { userId: this.appService.UserObject.userId, latest: true }
    })
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get announcements
  async getCustomerAnnouncements(_customer: string): Promise<any[]> {
    return this.getDocs(
      'getAnnouncements',
      {
        body: {
          userId: this.appService.UserObject.userId,
          filter_by: 'customer'
        }
      },
      ''
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  // get customer balances
  async getCustomerBalances(_customer: string): Promise<any[]> {
    return this.getDocs(
      'getCustomerTransactionLog',
      {
        body: {
          userId: this.appService.UserObject.userId,
          getBalances: true
        }
      },
      ''
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: 'Cannot get customer balances!',
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  //  get order cds details by
  async getCDSDetailsByBin(_id: string = ''): Promise<any[]> {
    return this.getDocs(
      'getCDSDetails',
      { body: { userId: this.appService.UserObject.userId, bin_id: _id } },
      ''
    )
      .then((data) => {
        return data[0];
      })
      .catch((err) => {
        this.errHandler.handleAPIErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C022,
          displayNotification: true,
          errorObject: {}
        });
        return [];
      });
  }

  async updateCustomerAccount(bankDetails: ICustomerAccount): Promise<any> {
    try {
      const payload = {
        body: {
          userId: this.appService.UserObject.userId,
          bankDetails: bankDetails
        }
      };
      return await this.getDocs('updateCustomerAccount', payload);
    } catch (e: any) {
      this.errHandler.handleAPIErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.C030,
        displayNotification: true,
        errorObject: {}
      });
    }
  }
}
