import { OnInit, Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { Subscription } from 'rxjs/internal/Subscription';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { alertAttributes } from '../../helper/appAlert';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-uxc1003',
  templateUrl: './uxc1003.component.html',
  styleUrls: ['./uxc1003.component.scss']
})
export class Uxc1003Component implements OnInit {
  errHandler: appErrorHandler;
  _outOfScheme: boolean = false;

  _style: string = '';
  _email: string = '';
  workflowData: any;

  // screen config data related to the workflow step
  screenConfig: any;

  primarySub: Subscription | undefined;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.errHandler = new appErrorHandler(this.appService);

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1003;
  }

  async ngOnInit() {
    await this.getData();
    await this.appService.updateWorkflowData({
      workflowId: this.screenConfig.id,
      workflowData: {
        address: {
          description: ''
        },
        subscription_type: {
          key: '',
          description: ''
        },
        charity: {
          key: '',
          description: ''
        },
        taxInvoiceEmail: ''
      }
    });
  }

  async getData() {
    this._email = this.appService.UserObject.email;
    this.appService.appSpinner(false);
    let res = await this.appApi.getCustomerAddresses(true);
    if (res != undefined) {
      if (res[0].refSuburb == null) {
        this._outOfScheme = true;
      }
    }
  }

  async resendSignUp() {
    await this.authService
      .resendConfirmationCode(this._email)
      .then(() => {
        this.errHandler.handleC1Errors({
          type: alertAttributes.types.success,
          displayMessage: 'Verification email sent!',
          displayNotification: true,
          errorObject: {}
        });
      })
      .catch((err) => {
        this.errHandler.handleC1Errors({
          type: alertAttributes.types.error,
          displayMessage: 'Verification email not sent: ' + err,
          displayNotification: true,
          errorObject: {}
        });
      });
  }

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
    // this.router.navigate(['/' + this.screenConfig.cancel], { relativeTo: this.route });
  }
}
