import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uxc9004',
  templateUrl: './uxc9004.component.html',
  styleUrls: ['./uxc9004.component.scss']
})
export class Uxc9004Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
