<!-- 2021-10-17 CJ: Logic update - allow check box control from parent -->
<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxFlex>
    <app-item-and-number-picker
      [_items]="this.items"
      (itemPicked)="addressPicked($event)"
      #addressPicker>
    </app-item-and-number-picker>
  </div>

  <button
    fxFlex
    [disabled]="!items.length || !isItemChecked"
    mat-raised-button
    class="mt-4"
    color="primary"
    (click)="next()">
    {{ primaryButtonCaption }}
  </button>
</div>
