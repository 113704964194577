import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IOptionType } from 'src/app/helper/appInterfaces';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc5004',
  templateUrl: './uxc5004.component.html',
  styleUrls: ['./uxc5004.component.scss']
})
export class Uxc5004Component implements OnInit {
  _style: string = '';
  _currentUser: string;

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;
  subRes: any;

  items: IOptionType[];
  selectedItem: IOptionType = { key: '', description: '', checked: false };
  value = 1;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this._currentUser = this.authService.getCurrentUserId();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5004;
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.items = [];
  }

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData() {
    let res = await this.appApi.getContainerTypes();
    this.subRes = await this.appApi.getCustomerByEmail(this._currentUser);
    this.items = res;

    // logic for when user hits back button to preserve values selected previously
    const workflowData = await this.appService.getOrderSummary();

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].description == '240L Bin') {
        workflowData.payment_type.key = this.items[i].key;
      } else {
        this.items.splice(i, 1);
      }
    }

    this.appService.appSpinner(false);
  }

  async incrementValue() {
    if (this.value < this.subRes.refSubscriptionType.bin_limit) {
      this.value += 1;
    }
  }

  async decrementValue() {
    if (this.value > 1) {
      this.value -= 1;
    }
  }

  async next() {
    this.appService.appSpinner(true);
    await this.save();

    if (this.subRes.refSubscriptionType.code == 'PREMIUM') {
      this.router.navigate(['/' + this.workflowStep.next.full], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/' + this.workflowStep.next.part], {
        relativeTo: this.route
      });
    }
  }

  async save() {
    this.appService.updateWorkflowData({
      workflowId: this.workflowStep.id,
      workflowData: {
        subscription_type: {
          key: this.subRes.refSubscriptionType.id,
          description: this.subRes.refSubscriptionType.desc,
          bin_fee: this.subRes.refSubscriptionType.bin_fee,
          membership_fee: this.subRes.refSubscriptionType.membership_fee
        },
        qty: this.value
      }
    });
  }
}
