<div fxLayout="column" fxLayoutGap="15px" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="headerTitle"
    [_progressTitle]="progressTitle"
    [_progressValue]="progressValue">
  </screen-header>

  <div fxLayout="column" fxLayoutGap="15px">
    <div fxFlex>Pick up date</div>
    <div fxFlex>
      <b>{{ this.selectedDate }}</b>
    </div>
    <div fxFlex>Pick up location</div>
    <div fxFlex>
      <b>{{ this.customerAddress }}</b>
    </div>
    <div fxFlex>
      Number of bins to swap: <b> {{ this.numberOfBins }} </b>
    </div>
    <!--      <app-item-and-number-picker [_items]="this.items" [_readonly]="true"> </app-item-and-number-picker>-->
  </div>

  <div fxFlex>
    <app-item-and-number-picker
      [_items]="this.finalPickup"
      (itemPicked)="finalPickupSelected($event)">
    </app-item-and-number-picker>
  </div>

  <div fxLayout="column">
    <button mat-raised-button class="mt-2" color="primary" (click)="next()">
      {{ primaryButtonCaption }}
    </button>
  </div>
</div>
