import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs/internal/observable/from';
import { appParam } from 'src/app/helper/appSettings';
import { appUtils } from 'src/app/helper/appUtils';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { alertAttributes } from '../../helper/appAlert';
import { ThemeService } from '../../services/theme.service';
import {
  MatBottomSheet,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-uxc1005',
  templateUrl: './uxc1005.component.html',
  styleUrls: ['./uxc1005.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Uxc1005Component implements OnInit {
  _style = '';

  _currentUser: string;
  _currentUserAddress = '';
  _currentUserType = '';
  currentUserType = '';

  lastPickupRequest: any;
  bins: any;
  balances: any = '0.00';
  amountPaid: any = '0.00';
  amountOwing: any = '0.00';
  displayPickUp = true;
  displayCollection = true;
  isNewOrder = false;
  binDroppedOff = false;
  isOutOfScheme = false;
  collectionDate: any = null;
  closeAnnouncements: boolean = false;

  _currentAnnouncements: any = [];
  // index: Observable<number> | undefined;

  numArrayObs = from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  firstName: string = '';
  announces: any[] = [];
  slideConfig = {
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000,
    accessibility: false,
    arrows: false
  };

  constructor(
    public appService: AppService,
    private renderer: Renderer2,
    private appApi: ApiService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private _bottomSheet: MatBottomSheet
  ) {
    this._currentUser = this.authService.getCurrentUserId();
    this._style = this.authService.getStyle();
    this.firstName = this.authService.getFirstName();
  }

  async ngOnInit(): Promise<void> {
    await this.authService.isExpired();
    // this.appService.splashVideo(true);
    await this.getData();
    // setTimeout(() => {
    //   this.appService.splashVideo(false);
    // }, 4500);
  }

  async getData() {
    this.appService.appSpinner(false);
    // await this.appService.clearCache();

    // if customer is out of scheme, set variable to true
    let customerAddress = await this.appApi.getCustomerAddresses();
    if (customerAddress != undefined) {
      if (customerAddress[0].refSuburb == null) {
        this.isOutOfScheme = true;
      }
    }
    // set customer address here
    this._currentUserAddress = customerAddress[0]['street_address'];

    if (!this.isOutOfScheme) {
      const result = await Promise.all([
        this.appApi.getCustomerPickupRequests(true),
        this.appApi.getCustomerOrders(),
        this.appApi.getCustomerByEmail(this._currentUser),
        this.appApi.getCustomerAnnouncements(this._currentUser),
        this.appApi.getCustomerBalances(this._currentUser)
      ]);

      const res = result[0];
      const newOrder = result[1];
      const currentUser = result[2];
      this.announces = result[3];
      this.balances = result[4];

      this.bins = await this.binCodes(newOrder);

      if (res.length > 0) {
        this.lastPickupRequest = {};
        this.lastPickupRequest.date = appUtils.formatHumanDate(
          res[0].selected_date
        );
        this.lastPickupRequest.address = res[0].address;
      }

      if (newOrder) {
        for (let order of newOrder) {
          let orderRes = await this.appApi.getCustomerTransactionLog(order.id);
          if (orderRes.refOrderStatus.code == 'CREATED') {
            this.lastPickupRequest = true;
            this.collectionDate = appUtils.formatHumanDate(order.order_date);
            this.isNewOrder = true;
          } else if (orderRes.refOrderStatus.code == 'IN-PROGRESS') {
            this.lastPickupRequest = true;
            this.collectionDate = appUtils.formatHumanDate(order.order_date);
          }
        }
      }

      if ((!res || !res.length) && !this.isNewOrder) {
        this.displayCollection = false;
      }

      if (this.bins.length == 0) {
        this.displayPickUp = false;
        this.bins = ['None assigned'];
      }

      // get amount paid out
      // const currentUser = await this.appApi.getCustomerByEmail(this._currentUser);
      this._currentUserType = currentUser.customerType;
      this.currentUserType =
        this._currentUserType.charAt(0) +
        this._currentUserType.toLowerCase().slice(1);

      if (this.balances.length !== 0) {
        this.amountPaid = Number(this.balances[0].amount).toFixed(2);
      }
    } else {
      this.isOutOfScheme = true;
      this.displayCollection = false;
      this.displayPickUp = false;
      this.announces = [
        {
          main_heading: 'Please check back soon!',
          body_content:
            'We will be in touch as soon as Direct Collect bins are available for drop off in your area, so keep an eye out for email notifications.'
        }
      ];
    }
  }

  // TODO: get the bins associated with an order
  async binCodes(customerOrders: any) {
    let binIds: any[] = [];

    for (let order of customerOrders) {
      let orderRes = await this.appApi.getCustomerTransactionLog(order.id);
      if (orderRes.refOrderStatus.code == 'COMPLETED') {
        let binRes = await this.appApi.getBinTransactionLogByOrderId(order.id);
        for (let bin of binRes) {
          if (bin.refBinStatus.code == 'DELIVERED') {
            binIds.push(bin.refBin.code);
            this.binDroppedOff = true;
          }
        }
      }
    }
    return binIds;
  }

  async signOut() {
    await this.authService.signOut();
    await this.appService.clearCache();
    await this.router.navigate(['/' + appParam.routing.C2_signIn.C2001], {
      relativeTo: this.route
    });
  }

  async orderBin() {
    this.appService.appSpinner(true);
    let res = await this.appApi.getCustomerOrders();
    if (res.length > 0) {
      await this.appService.sendNotification({
        type: alertAttributes.types.warning,
        message: 'You already have a bin ordered',
        displayNotification: true
      });
      this.appService.appSpinner(false);
    } else {
      if (this._currentUserType == appParam.userType.basic) {
        await this.router.navigate(['/' + appParam.routing.C5_orderBin.C5007], {
          relativeTo: this.route
        });
      } else if (this._currentUserType == appParam.userType.charity) {
        await this.router.navigate(['/' + appParam.routing.C5_orderBin.C5003], {
          relativeTo: this.route
        });
      } else {
        await this.router.navigate(['/' + appParam.routing.C5_orderBin.C5004], {
          relativeTo: this.route
        });
      }
    }
  }

  async requestPickup() {
    this.appService.appSpinner(true);
    let res = await this.appApi.getCustomerOrdersForPickupRequest();
    if (res.length > 0) {
      if (res[0].refOrderStatus.id != '1' && res[0].refOrderStatus.id != '2') {
        await this.router.navigate(
          ['/' + appParam.routing.C8_requestPickup.C8003],
          { relativeTo: this.route }
        );
      } else {
        await this.appService.sendNotification({
          type: alertAttributes.types.warning,
          message: "Your order hasn't been completed yet!",
          displayNotification: true
        });
        this.appService.appSpinner(false);
      }
    }
  }

  async requestPickupChange() {
    this.appService.appSpinner(true);
    let res = await this.appApi.getCustomerPickupRequests(true);
    let order = await this.appApi.getLatestCustomerOrder();
    let newOrder = false;

    let orderTransactionLog = await this.appApi.getCustomerTransactionLog(
      order.id
    );
    if (orderTransactionLog.refOrderStatus.code == 'CREATED') {
      newOrder = true;
    }

    if ((!res || !res.length) && !newOrder) {
      await this.appService.sendNotification({
        type: alertAttributes.types.warning,
        message: 'Order is in progress!',
        displayNotification: true
      });
      this.appService.appSpinner(false);
    } else {
      await this.router.navigate(
        ['/' + appParam.routing.C9_requestPickupChange.C9002],
        { relativeTo: this.route }
      );
    }
  }

  async redirectToSupport() {
    window.open('https://direct-collect.com.au/contact-us', '_blank');
  }

  async orderStatus() {
    await this.router.navigate(
      ['/' + appParam.routing.C10_orderStatus.C10001],
      { relativeTo: this.route }
    );
  }

  async binStatus() {
    this.appService.appSpinner(true);
    await this.router.navigate(['/' + appParam.routing.C13_binStatus.C13001], {
      relativeTo: this.route
    });
    this.appService.appSpinner(false);
  }

  toggleDarkMode() {
    this.themeService.toggleDarkMode();
  }

  openBottomSheet(): void {
    this._bottomSheet.open(Uxc1005BottomSheetComponent);
  }

  closeAnnouncement() {
    this.closeAnnouncements = !this.closeAnnouncements;
  }
}

@Component({
  selector: 'app-bottom-sheet-menu',
  templateUrl: './uxc1005.bottom-sheet.component.html'
})
export class Uxc1005BottomSheetComponent implements OnInit {
  fullName!: string;
  email!: string;
  currentUserType = '';
  _currentUserType = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<Uxc1005BottomSheetComponent>,
    private router: Router,
    private route: ActivatedRoute,
    public appService: AppService,
    private appApi: ApiService,
    private authService: AuthService
  ) {
    this.fullName =
      this.appService.UserObject.firstName +
      ' ' +
      this.appService.UserObject.lastName;
    this.email = this.appService.UserObject.email;
  }

  async ngOnInit(): Promise<void> {
    let currentUser = await this.appApi.getCustomerByEmail(this.email);
    this._currentUserType = currentUser.customerType;
    this.currentUserType =
      this._currentUserType.charAt(0) +
      this._currentUserType.toLowerCase().slice(1);
  }

  async openOrderHistory(): Promise<void> {
    this._bottomSheetRef.dismiss();
    let commands = ['/' + appParam.routing.C10_orderStatus.C10001];
    await this.router.navigate(commands, { relativeTo: this.route });
  }

  async openBinHistory(): Promise<void> {
    this._bottomSheetRef.dismiss();
    let commands = ['/' + appParam.routing.C13_binStatus.C13001];
    await this.router.navigate(commands, { relativeTo: this.route });
  }

  async updateBankDetails(): Promise<void> {
    this._bottomSheetRef.dismiss();
    let commands = ['/' + appParam.routing.C12_updateBankDetails.C12001];
    await this.router.navigate(commands, { relativeTo: this.route });
  }

  async changePassword(): Promise<void> {
    this._bottomSheetRef.dismiss();
    let commands = ['/' + appParam.routing.C11_changePassword.C11001];
    await this.router.navigate(commands, { relativeTo: this.route });
  }

  async signOut() {
    this._bottomSheetRef.dismiss();
    await this.authService.signOut();
    await this.appService.clearCache();
    await this.router.navigate(['/' + appParam.routing.C2_signIn.C2001], {
      relativeTo: this.route
    });
  }
}
