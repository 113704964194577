import { Uxc1002Component } from './UX-C1/002/uxc1002.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Uxc1001Component } from './UX-C1/001/uxc1001.component';
import { Uxc1001bComponent } from './UX-C1/001b/uxc1001b.component';
import { Uxc1003Component } from './UX-C1/003/uxc1003.component';
import { Uxc1004Component } from './UX-C1/004/uxc1004.component';
import { Uxc1004bComponent } from './UX-C1/004b/uxc1004b.component';
import { Uxc1004cComponent } from './UX-C1/004c/uxc1004c.component';
import { Uxc1004dComponent } from './UX-C1/004d/uxc1004d.component';
import { Uxc1004dConfirmComponent } from './UX-C1/004d-confirm/uxc1004d-confirm.component';
import { Uxc1004eComponent } from './UX-C1/004e/uxc1004e.component';
import { Uxc1004fComponent } from './UX-C1/004f/uxc1004f.component';
import { Uxc1004gComponent } from './UX-C1/004g/uxc1004g.component';
import { Uxc1005Component } from './UX-C1/005/uxc1005.component';

import { Uxc2001Component } from './UX-C2/001/uxc2001.component';

import { Uxc3001Component } from './UX-C3/001/uxc3001.component';
import { Uxc3002Component } from './UX-C3/002/uxc3002.component';
import { Uxc3003Component } from './UX-C3/003/uxc3003.component';

import { Uxc4001Component } from './UX-C4/001/uxc4001.component';
import { Uxc4002Component } from './UX-C4/002/uxc4002.component';
import { Uxc4003Component } from './UX-C4/003/uxc4003.component';

import { Uxc5001Component } from './UX-C5/001/uxc5001.component';
import { Uxc5003Component } from './UX-C5/003/uxc5003.component';
import { Uxc5004Component } from './UX-C5/004/uxc5004.component';
import { Uxc5005Component } from './UX-C5/005/uxc5005.component';
import { Uxc5006Component } from './UX-C5/006/uxc5006.component';
import { Uxc5007Component } from './UX-C5/007/uxc5007.component';
import { Uxc5008Component } from './UX-C5/008/uxc5008.component';
import { Uxc5009Component } from './UX-C5/009/uxc5009.component';

import { Uxc7001Component } from './UX-C7/001/uxc7001.component';
import { Uxc7003Component } from './UX-C7/003/uxc7003.component';

import { Uxc8001Component } from './UX-C8/001/uxc8001.component';
import { Uxc8003Component } from './UX-C8/003/uxc8003.component';
import { Uxc8004Component } from './UX-C8/004/uxc8004.component';
import { Uxc8005Component } from './UX-C8/005/uxc8005.component';

import { Uxc9001Component } from './UX-C9/001/uxc9001.component';
import { Uxc9002Component } from './UX-C9/002/uxc9002.component';
import { Uxc9003Component } from './UX-C9/003/uxc9003.component';
import { Uxc9004Component } from './UX-C9/004/uxc9004.component';

import { Uxc10001Component } from './UX-C10/001/uxc10001.component';
import { Uxc10002Component } from './UX-C10/002/uxc10002.component';

import { Uxc13001Component } from './UX-C13/001/uxc13001.component';

import { Uxc1101Component } from './UX-C11/001/uxc1101.component';

import { Uxc1201Component } from './UX-C12/001/uxc1201.component';

import { appParam } from './helper/appSettings';
import { AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  // { path: "", redirectTo: "sign-in", pathMatch: "full" },
  { path: appParam.routing.C2_signIn.C2001, component: Uxc2001Component },

  { path: appParam.routing.C1_signUp.C1001, component: Uxc1001Component },
  { path: appParam.routing.C1_signUp.C1002, component: Uxc1002Component },
  { path: appParam.routing.C1_signUp.C1001b, component: Uxc1001bComponent },
  { path: appParam.routing.C1_signUp.C1003, component: Uxc1003Component },
  { path: appParam.routing.C1_signUp.C1004, component: Uxc1004Component },
  { path: appParam.routing.C1_signUp.C1004b, component: Uxc1004bComponent },
  {
    path: appParam.routing.C1_signUp.C1004dConfirm,
    component: Uxc1004dConfirmComponent
  },
  { path: appParam.routing.C1_signUp.C1004c, component: Uxc1004cComponent },
  { path: appParam.routing.C1_signUp.C1004d, component: Uxc1004dComponent },
  { path: appParam.routing.C1_signUp.C1004e, component: Uxc1004eComponent },
  { path: appParam.routing.C1_signUp.C1004f, component: Uxc1004fComponent },
  { path: appParam.routing.C1_signUp.C1004g, component: Uxc1004gComponent },

  { path: appParam.routing.C3_forgotEmail.C3001, component: Uxc3001Component },
  { path: appParam.routing.C3_forgotEmail.C3002, component: Uxc3002Component },
  { path: appParam.routing.C3_forgotEmail.C3003, component: Uxc3003Component },

  {
    path: appParam.routing.C4_forgotPassword.C4001,
    component: Uxc4001Component
  },
  {
    path: appParam.routing.C4_forgotPassword.C4002,
    component: Uxc4002Component
  },
  {
    path: appParam.routing.C4_forgotPassword.C4003,
    component: Uxc4003Component
  },

  {
    path: appParam.routing.C5_orderBin.C5001,
    component: Uxc5001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5002,
    component: Uxc7001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5003,
    component: Uxc5003Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5004,
    component: Uxc5004Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5005,
    component: Uxc5005Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5006,
    component: Uxc5006Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5007,
    component: Uxc5007Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5007b,
    component: Uxc5007Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5008,
    component: Uxc5008Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C5_orderBin.C5009,
    component: Uxc5009Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C1_signUp.C1005,
    component: Uxc1005Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C7_orderBinCharity.C7001,
    component: Uxc7001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C7_orderBinCharity.C7003,
    component: Uxc7003Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C8_requestPickup.C8001,
    component: Uxc8001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C8_requestPickup.C8003,
    component: Uxc8003Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C8_requestPickup.C8004,
    component: Uxc8004Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C8_requestPickup.C8005,
    component: Uxc8005Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C9_requestPickupChange.C9001,
    component: Uxc9001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C9_requestPickupChange.C9002,
    component: Uxc9002Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C9_requestPickupChange.C9003,
    component: Uxc9003Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C9_requestPickupChange.C9004,
    component: Uxc9004Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C10_orderStatus.C10001,
    component: Uxc10001Component,
    canActivate: [AuthGuard]
  },
  {
    path: appParam.routing.C10_orderStatus.C10002,
    component: Uxc10002Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C11_changePassword.C11001,
    component: Uxc1101Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C12_updateBankDetails.C12001,
    component: Uxc1201Component,
    canActivate: [AuthGuard]
  },

  {
    path: appParam.routing.C13_binStatus.C13001,
    component: Uxc13001Component,
    canActivate: [AuthGuard]
  },

  { path: '', redirectTo: appParam.routing.C2_signIn.C2001, pathMatch: 'full' } // redirect to the default login page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
