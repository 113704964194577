import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';

import { appParam } from '../helper/appSettings';
import { AppService } from '../services/app.service';
import { alertAttributes } from '../helper/appAlert';
import { IUserAttr } from '../helper/appInterfaces';
import { appErrorHandler } from '../helper/appErrorHandler';
import { ApiService } from './api.service';
import { ThemeService } from './theme.service';
import { environment } from 'src/environments/environment';
import { appWorkflowData } from '../helper/appWorkflowData';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: appParam.auth.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: appParam.auth.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: appParam.auth.userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: "backyard-cash-dev.auth.ap-southeast-2.amazoncognito.com",
    //   // OPTIONAL - Cookie path
    //   // path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   // expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   // sameSite: "strict" | "lax",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: appParam.auth.domain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: appParam.auth.redirectSignIn,
      redirectSignOut: appParam.auth.redirectSignOut,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

// You can get the current config object
const currentConfig = Auth.configure();

export enum LoginStatus {
  UNSUCCESSFUL = -1,
  NEW_PASSWORD_REQUIRED = 0,
  SUCCESSFUL = 1,
  NOT_VERIFIED = 2
}

export type ResetPasswordStatus =
  | 'successful'
  | 'unsuccessful'
  | 'not-verified';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  errHandler: appErrorHandler;

  private userId: string;
  private userObject: any;

  private verificationCodeValidated: boolean = false;

  constructor(
    private appService: AppService,
    private appApi: ApiService,
    private themeService: ThemeService
  ) {
    this.errHandler = new appErrorHandler(this.appService);
    this.userId = '';
  }

  // create user in AWS Cognito
  async signUp(_userAttr: IUserAttr): Promise<any> {
    try {
      if (_userAttr.mobile.trim() == '') {
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C023,
          displayNotification: true,
          errorObject: {}
        });
        return false;
      }

      const res = await Auth.signUp({
        username: _userAttr.userId,
        password: _userAttr.password,
        attributes: {
          email: _userAttr.email, // optional
          phone_number: _userAttr.mobile, // optional - E.164 number convention
          family_name: _userAttr.lastName,
          name: _userAttr.firstName
        }
      });

      console.log('authSignUp', res);

      this.userId = _userAttr.userId;

      this.appService.sendNotification({
        type: alertAttributes.types.info,
        message: _userAttr.email,
        displayNotification: false
      });

      return true;
    } catch (err: any) {
      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: err.message,
        displayNotification: true,
        errorObject: err
      });
      return false;
    }
  }

  /**
   * Handle resending the confirmation code for the account we are setting up.
   * We want to ensure it is delivered correctly, otherwise we need to display
   * an error message to the user.
   *
   * @param userId The userId for the account we are verifying.
   *
   * @returns The result from the authentication library.
   */
  public async resendConfirmationCode(userId: string): Promise<any> {
    try {
      return await Auth.resendSignUp(userId);
    } catch (err: any) {
      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.C024 + err.message,
        displayNotification: true,
        errorObject: {}
      });
    }
  }

  /**
   * Handle verifying the SMS verification code that we have sent out to the
   * new user. This function will check if it was correct otherwise, we will
   * show the user an error.
   *
   * @param userId The user we are verifying the code against.
   * @param code The verification code we are checking.
   */
  public async verifyConfirmationCode(
    userId: string,
    code: string
  ): Promise<any> {
    try {
      return await Auth.confirmSignUp(userId, code);
    } catch (err: any) {
      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.C037 + err.message,
        displayNotification: true,
        errorObject: {}
      });
    }
  }

  /**
   * Try signing a user in from the details provided. We want to catch and
   * handle all cases that have occurred, such as requiring a new password,
   * verifying your account or just displaying an error message for
   * entering in the incorrect details.
   *
   * @param userId The users' email address that was provided.
   * @param pwd The users' password that was provided.
   *
   * @returns The status we determined based on the response from Cognito.
   */
  public async signIn(userId: string, pwd: string): Promise<LoginStatus> {
    this.userId = userId;

    try {
      const user = await Auth.signIn(userId, pwd);

      /**
       * Ensure we notify the calling component that we require a password
       * reset to login first.
       */
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return LoginStatus.NEW_PASSWORD_REQUIRED;
      }

      this.userObject = user['signInUserSession'];
      console.log('userObject', this.userObject['idToken']['payload']);

      // Change the theme depending on the user type
      /** Change the theme based on the type of user that has signed in. */
      this.themeService.setUserTheme(
        this.userObject.idToken.payload['custom:userType']
      );

      // 2021-10-25 CJ: API + Cognito auth integration
      await this.appService.updateCache(
        appParam.cacheKeys.authToken,
        user.signInUserSession.idToken.jwtToken
      );

      this.appService.sendNotification({
        workflow: alertAttributes.workflowSteps.C2_signIn.C2001,
        body: this.userObject['idToken']['payload'],
        displayNotification: false
      });

      return LoginStatus.SUCCESSFUL;
    } catch (err: any) {
      console.error(err);

      /** Handle unknown types of exceptions with a generic message. */
      if (!(err instanceof Error)) {
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C025 + err.message,
          displayNotification: true,
          errorObject: {}
        });

        return LoginStatus.UNSUCCESSFUL;
      }

      /**
       * Ensure we don't print out the details of the error in a production
       * like environment.
       */
      if (!environment.production) {
        console.dir(err);
      }

      /**
       * If we come across the case where a user has not confirmed their
       * account, we want to resend a verification code and get them
       * to confirm it.
       */
      if (err.name === 'UserNotConfirmedException') {
        const result = await this.resendConfirmationCode(userId);

        /** We were not able to send a verification code. */
        if (!result) {
          return LoginStatus.UNSUCCESSFUL;
        }

        this.appService.sendNotification({
          message:
            "Before we can log you in, you'll need to verify your account.",
          type: alertAttributes.types.info,
          displayNotification: false
        });

        return LoginStatus.NOT_VERIFIED;
      } else if (
        err.name === 'NotAuthorizedException' ||
        err.name === 'UserNotFoundException'
      ) {
        /**
         * Ensure we show an appropriate error for incorrect login details,
         * as well as the user not existing.
         */
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C025 + err.message,
          displayNotification: true,
          errorObject: {}
        });

        return LoginStatus.UNSUCCESSFUL;
      }

      /** Show a generic error if we don't know what the error is. */
      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.C025 + err.message,
        displayNotification: true,
        errorObject: {}
      });

      return LoginStatus.UNSUCCESSFUL;
    }
  }

  async isExpired() {
    let currentSession = await Auth.currentSession();
  }

  public async isLoggedIn() {
    try {
      this.userObject = await Auth.currentSession();

      if (!environment.production) {
        console.log('userObject', this.userObject);
        console.log(
          'token.exp  ',
          this.parseJwt(this.userObject.accessToken.jwtToken).exp
        );
        console.log('currentTime', Math.floor(Date.now() / 1000));
      }

      /**
       * Get the authentication token, and then update the cache to ensure
       * it is always up to date.
       */
      const token = this.userObject.idToken.jwtToken;
      this.appService.updateCache(appParam.cacheKeys.authToken, token);

      // Change the theme depending on the user type
      this.themeService.setUserTheme(
        this.userObject.idToken.payload['custom:userType']
      );

      this.appService.sendNotification({
        workflow: alertAttributes.workflowSteps.C2_signIn.C2001,
        body: this.userObject['idToken']['payload'],
        displayNotification: false
      });

      this.appService.UserObject.userId =
        this.userObject['idToken']['payload']['cognito:username'];

      // logic to reload the user details from the DB if the user refresh the page or at initial login
      if (
        this.appService.UserObject.userType == '' ||
        this.appService.UserObject.mobile == '' ||
        this.appService.UserObject.charityId == undefined
      ) {
        const res = await this.appApi.getCustomer(
          this.appService.UserObject.userId
        );

        if (res.length > 0) {
          this.appService.UserObject.userType = res[0].customerType;
          this.appService.UserObject.taxInvoiceEmail = res[0].taxInvoiceEmail;

          if (res[0].refCharity != null)
            this.appService.UserObject.charityId = res[0].refCharity.id;

          this.appService.UserObject.mobile = res[0].mobile;
        }
      }
      return true;
    } catch (err: any) {
      console.error('isLoggedIn', err);
      return false;
    }
  }

  //  get the cognito custom attribute
  getUserType() {
    if (this.userObject != undefined) {
      return this.userObject['idToken']['payload']['custom:userType'];
    } else {
      return '';
    }
  }

  getStyle() {
    const userType = this.getUserType();

    if (userType == appParam.userType.charity) {
      return 'byc-02';
    } else {
      return 'byc-01';
    }
  }

  // to be implemented
  async forgotUserId(_mobile: string) {}

  /**
   * Try sending an SMS verification code to the users' phone number. To
   * identify their account, we will need to input their `userId`
   * which is set to their email address.
   *
   * @param userId The users' email address that was provided.
   *
   * @returns
   */
  public async forgotPassword(userId: string): Promise<ResetPasswordStatus> {
    this.userId = userId;

    try {
      const resp = await Auth.forgotPassword(this.userId);

      if (!resp) return 'unsuccessful';

      return 'successful';
    } catch (error: any) {
      console.error(error);

      /** Handle unknwon types of exceptions with a generic message. */
      if (!(error instanceof Error)) {
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: error.message,
          displayNotification: true,
          errorObject: {}
        });

        return 'unsuccessful';
      }

      /**
       * Ensure we don't print out the details of the error in a production
       * like environment.
       */
      if (!environment.production) {
        console.dir(error);
      }

      /**
       * If we come across the case where a user has not confirmed their
       * account, we want to resend a verification code and get them to
       * confirm it.
       */
      if (error.name === 'InvalidParameterException') {
        const result = await this.resendConfirmationCode(userId);

        if (!result) return 'unsuccessful';

        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.warning,
          displayMessage:
            "Before we can reset your password, you'll need to verify your account.",
          displayNotification: true,
          errorObject: {}
        });

        return 'not-verified';
      } else {
        /** Show a generic error if we don't know what the error is. */
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: error.message,
          displayNotification: true,
          errorObject: {}
        });
      }

      return 'unsuccessful';
    }
  }

  // reset the user's password if the entered verification code is correct
  async resetPassword(
    _userId: string,
    _code: string,
    _newPassword: string
  ): Promise<boolean> {
    return Auth.forgotPasswordSubmit(_userId, _code, _newPassword)
      .then((data) => {
        return true;
      })
      .catch((err) => {
        console.log('err: ', err);
        this.errHandler.handleAuthErrors({
          type: alertAttributes.types.error,
          displayMessage: alertAttributes.alerts.C027 + err.message,
          displayNotification: true,
          errorObject: {}
        });
        return false;
      });
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      this.appService.sendNotification({
        displayNotification: true,
        type: alertAttributes.types.success,
        message: 'Password successfully updated'
      });
      return true;
    } catch (err: any) {
      console.log('err: ', err);
      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: alertAttributes.alerts.C029 + ': ' + err.message,
        displayNotification: true,
        errorObject: {}
      });
      return false;
    }
  }

  public async initPassword(
    userId: string,
    oldPwd: string,
    newPwd: string
  ): Promise<boolean> {
    try {
      const user = await Auth.signIn(userId, oldPwd);

      this.appService.updateCache(appParam.cacheKeys.userDetails, user);

      await Auth.completeNewPassword(user, newPwd);

      this.appService.sendNotification({
        displayNotification: true,
        type: alertAttributes.types.success,
        message: 'Password successfully changed!'
      });

      return true;
    } catch (err: any) {
      if (!environment.production) console.log(err);

      this.errHandler.handleAuthErrors({
        type: alertAttributes.types.error,
        displayMessage: 'Could not change password!',
        displayNotification: true,
        errorObject: {}
      });

      return false;
    }
  }

  async signOut() {
    try {
      await Auth.signOut();

      this.appService.UserObject.userType = '';
      this.appService.UserObject.userId = '';
      this.appService.UserObject.mobile = '';
      this.appService.UserObject.charityId = '';
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }

  getIdToken() {
    return this.userObject['idToken']['payload'];
  }

  getCurrentUserId() {
    const res = this.getIdToken();
    return res['cognito:username'];
  }

  getFirstName() {
    return this.userObject['idToken']['payload']['name'];
  }

  /**
   * Handle decoding the contents ofthe authentication token to obtain
   * access to the details.
   *
   * @param token The authentication token to decode.
   *
   * @returns The payload of the authentication or undefined.
   */
  private parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch {
      return undefined;
    }
  }
}
