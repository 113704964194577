import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { appUtils } from '../../helper/appUtils';
import { AuthService } from 'src/app/services/auth.service';
import { ICustomerOrder } from '../../helper/appInterfaces';
import { appParam } from '../../helper/appSettings';

@Component({
  selector: 'app-uxc5009',
  templateUrl: './uxc5009.component.html',
  styleUrls: ['./uxc5009.component.scss']
})
export class Uxc5009Component {
  _style: string = '';

  //  setup the variables for the screen generic header (progress, title...etc)
  headerTitle: string;
  progressTitle: string;
  progressValue: number;
  workflowStep;
  primaryButtonCaption: string;

  workflowData: any;

  selectedDate: any = '';
  mobile: string = '';
  address: string = '';
  date: string = '';
  accountName: string = '';
  accountNumber: string = '';
  userType: string = '';
  bsb: string = '';
  binQty: number = 0;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    this.workflowStep = appWorkflowData.C5_orderBinFullPayment.C5009;
    this.selectedDate = appUtils.formatHumanDate(
      appWorkflowData.OrderSummary.selected_date.key
    );
    this.address = appWorkflowData.OrderSummary.address.street_address;
    this.accountName =
      appWorkflowData.OrderSummary.payment_details.account_name;
    this.accountNumber =
      appWorkflowData.OrderSummary.payment_details.account_number;
    this.bsb = appWorkflowData.OrderSummary.payment_details.bsb;
    this.binQty = appWorkflowData.OrderSummary.qty;

    this.mobile = appUtils.censorWord(appService.UserObject.mobile, 3);
    this.progressValue = this.workflowStep.progressValue();
    this.progressTitle = this.workflowStep.progressTitle;
    this.headerTitle = this.workflowStep.title;
    this.primaryButtonCaption = this.workflowStep.primaryButtonCaption;

    this.appService.appSpinner(true);
    this.getData();
  }

  async getData() {
    // logic for when user hits back button to preserve values selected previously
    this.workflowData = await this.appService.getOrderSummary();
    const customer = await this.appApi.getCustomerByEmail(this.authService.getCurrentUserId());
    this.userType = customer.customerType;

    this.appService.appSpinner(false);
    await this.createOrder();
  }

  async createOrder() {
    let textMessage =
      'Yay! Your Direct Collect bin has been ordered! It will be dropped off on ' +
      this.selectedDate +
      ' at ' +
      this.address;
    let notFirstOrder = false;
    let date = new Date(this.workflowData.selected_date.key);

    let order: ICustomerOrder = {
      customerId: this.appService.UserObject.userId,
      selectedDate: date.toLocaleDateString('en-CA'),
      accountName: this.accountName,
      accountNumber: this.accountNumber,
      bsb: this.bsb,
      bins_to_drop_off: this.binQty,
      notFirstOrder: notFirstOrder
    } as ICustomerOrder;

    try {
      const res = await this.appApi.createCustomerOrder(order);
      const sendSMS = await this.appApi.sendSMS(
        textMessage,
        this.appService.UserObject.mobile
      );
    } catch (err) {
      console.log(err);
    } finally {
      // Once order is complete reset the workflow data
      await this.appService.updateWorkflowData({
        workflowId: this.workflowStep.id,
        workflowData: {
          taxInvoiceEmail: '',
          order_type: {
            key: '',
            description: ''
          },
          payment_type: {
            key: '',
            description: ''
          },
          payment_details: {
            account_name: '',
            account_number: '',
            bsb: '',
            bank_name: ''
          },
          subscription_type: {
            key: '',
            description: '',
            bin_fee: 0,
            membership_fee: 0
          },
          charity: {
            key: '',
            description: '',
            charityObject: {}
          },
          address: {
            key: '',
            street_address: '',
            suburb: '',
            lat: 0,
            lng: 0
          },
          selected_date: {
            key: '',
            description: ''
          },
          container_type: {
            key: '',
            description: ''
          },
          notes: '',
          qty: 1
        }
      });
    }
  }

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.workflowStep.next], {
      relativeTo: this.route
    });
  }
}
