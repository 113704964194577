import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { appWorkflowData } from '../../helper/appWorkflowData';
import { ActivatedRoute, Router } from '@angular/router';
import { alertAttributes } from '../../helper/appAlert';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-uxc4003',
  templateUrl: './uxc4003.component.html',
  styleUrls: ['./uxc4003.component.scss']
})
export class Uxc4003Component implements AfterViewInit, OnDestroy {
  _style: string = '';
  screenConfig: any;
  primarySub: Subscription | undefined;

  public newPassword1: string;
  public newPassword2: string;
  public verificationCode: string = '';
  public email: string = '';

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();
    this.screenConfig = appWorkflowData.C4_forgotPassword.C4003;

    this.newPassword1 = '';
    this.newPassword2 = '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.primarySub = this.appService.currentAppNotification.subscribe(
        (notification) => {
          if (
            notification.workflow ===
            alertAttributes.workflowSteps.C4_forgotPassword.C4002
          ) {
            this.email = notification.body.email;
            this.verificationCode = notification.body.verification;
          }
        }
      );
    });
  }

  public ngOnDestroy(): void {
    if (this.primarySub) {
      this.primarySub.unsubscribe();
    }
  }

  async resetPassword() {
    this.appService.appSpinner(true);
    const res = await this.authService.resetPassword(
      this.email,
      this.verificationCode,
      this.newPassword2
    );
    this.appService.appSpinner(false);

    if (!res) return;

    this.appService.sendNotification({
      type: alertAttributes.types.success,
      message: 'Successfully changed password!',
      displayNotification: true
    });

    await this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
