import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { appWorkflowData } from 'src/app/helper/appWorkflowData';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-uxc1004f',
  templateUrl: './uxc1004f.component.html',
  styleUrls: ['./uxc1004f.component.scss']
})
export class Uxc1004fComponent implements OnInit {
  _style: string = '';

  // screen config data related to the workflow step
  screenConfig: any;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004f;
    this.appService.appSpinner(false);
  }

  ngOnInit(): void {}

  async next() {
    this.appService.appSpinner(true);
    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
