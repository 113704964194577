import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { appErrorHandler } from 'src/app/helper/appErrorHandler';
import { appParam } from 'src/app/helper/appSettings';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { AuthService, LoginStatus } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { IUserAttr } from '../../helper/appInterfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-uxc2001',
  templateUrl: './uxc2001.component.html',
  styleUrls: ['./uxc2001.component.scss']
})
export class Uxc2001Component implements OnInit, AfterViewInit {
  _currentApplicationVersion = environment.appVersion;
  _style: string = '';

  errHandler: appErrorHandler;

  public userId: string;

  public linkForgotPassword = appParam.routing.C4_forgotPassword.C4001;
  public linkForgotUserId = appParam.routing.C3_forgotEmail.C3001;

  public linkSignUp: string;
  formGroup!: FormGroup;
  loading: boolean = false;
  hide: boolean = true;

  constructor(
    public appService: AppService,
    public authService: AuthService,
    private appApi: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    updates: SwUpdate
  ) {
    this._style = this.authService.getStyle();

    this.userId = '';

    this.linkSignUp = appParam.routing.C1_signUp.C1001;
    this.errHandler = new appErrorHandler(this.appService);

    updates.available.subscribe((event) => {
      updates.activateUpdate().then(() => document.location.reload());
    });

    updates.activated.subscribe((event) => {});
  }

  async ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.appService.appSpinner(false);
    });
    await this.appService.clearCache();
  }

  /**
   * Sign in the user with details as provided from the form inputs.
   */
  public async signIn(): Promise<void> {
    this.appService.appSpinner(true);

    /** Try signing in the user with the provided details from the form. */
    const email = this.formGroup.value.email.toLowerCase().trim();
    const password = this.formGroup.value.password;
    const signIn = await this.authService.signIn(email, password);

    if (!environment.production) {
      console.log(signIn);
    }

    if (signIn === LoginStatus.NEW_PASSWORD_REQUIRED) {
      await this.router.navigate(['/' + appParam.routing.C1_signUp.C1001b], {
        relativeTo: this.route,
        queryParams: { email: email }
      });
    } else if (signIn === LoginStatus.NOT_VERIFIED) {
      this.appService.UserObject.userId = email;

      await this.router.navigate(['/' + appParam.routing.C1_signUp.C1002], {
        relativeTo: this.route
      });
    } else if (signIn === LoginStatus.SUCCESSFUL) {
      // Get customer
      let customer = await this.appApi.getCustomerByEmail(email);

      /** Create a new customer if the customer does not exist in the system. */
      if (!customer) customer = await this.createCustomer();

      /** Save a local copy of the data we received from the backend. */
      if (customer) {
        this.appService.UserObject.userType = customer.customerType;
        this.appService.UserObject.taxInvoiceEmail = customer.taxInvoiceEmail;
      }

      // Redirect to the right page
      // Initial redirect
      let commands = ['/' + appParam.routing.C1_signUp.C1005];

      const address = await this.appApi.getCustomerAddresses();

      // if the customer has exited before entering their address
      if (!address || !address.length) {
        commands = ['/' + appParam.routing.C1_signUp.C1004];
        // if the customer is out of scheme
      } else if (!address[0].refSuburb) {
        commands = ['/' + appParam.routing.C1_signUp.C1005];
        // if the customer did not select a subscription type
      } else if (customer.customerType == 'PERSONAL') {
        commands = ['/' + appParam.routing.C1_signUp.C1004c];
        // if the customer selected charity subscription and did not select a charity
      } else if (
        customer.refCharity == null &&
        customer.customerType == 'CHARITY'
      ) {
        commands = ['/' + appParam.routing.C1_signUp.C1004d];
      }

      // console.log('command', commands);
      await this.router.navigate(commands, { relativeTo: this.route });
    }
    this.appService.appSpinner(false);
  }

  openDirectCollectHome(): void {
    window.open('https://direct-collect.com.au/', '_blank');
  }

  async createCustomer(): Promise<any> {
    const user: IUserAttr = {
      userType: this.appService.UserObject.userType,
      userId: this.appService.UserObject.userId,
      firstName: this.appService.UserObject.firstName,
      lastName: this.appService.UserObject.lastName,
      mobile: this.appService.UserObject.mobile,
      email: this.appService.UserObject.email,
      charityCode: '',
      password: '',
      privacyPolicyAccepted: null
    };
    let customers = await this.appApi.createCustomer(user);
    return customers[0];
  }
}
