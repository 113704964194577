<div fxLayout="column" class="dc-container">
  <screen-header
    fxFlex
    [_headerTitle]="screenConfig.title"
    [_progressTitle]="screenConfig.progressTitle"
    [_progressValue]="screenConfig.progressValue()"
    [_cancelRoute]="screenConfig.cancel">
  </screen-header>

  <form [formGroup]="formGroup" fxLayout="column">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Old Password</mat-label>
        <input
          matInput
          data-cy="oldPassword"
          type="password"
          id="oldPassword"
          formControlName="oldPassword" />
        <mat-error>required</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          data-cy="password"
          type="password"
          id="password"
          formControlName="password" />
        <mat-error *ngIf="this.formGroup.controls.password.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.formGroup.controls.password.errors?.pattern">
          minimum 8 characters with at least 1 number, 1 special char, 1 upper
          case
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          data-cy="confirmPassword"
          type="password"
          id="confirmPassword"
          formControlName="confirmPassword" />
        <mat-error
          *ngIf="this.formGroup.controls.confirmPassword.errors?.required"
          >required</mat-error
        >
        <mat-error *ngIf="this.formGroup.controls.confirmPassword.errors?.match"
          >doesn't match</mat-error
        >
      </mat-form-field>
    </div>

    <div fxLayout="column">
      <button
        mat-raised-button
        data-cy="next"
        id="signUp"
        class="mt-4"
        color="primary"
        (click)="resetPassword()"
        [disabled]="!formGroup.valid">
        Confirm
      </button>
    </div>
  </form>
</div>
