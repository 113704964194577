import { appParam } from '../appSettings';

// used to store the workflow data across each screen
export const C7_orderBinCharity = {
  steps: 5, // used for the progress indicator on each screen
  C7001: {
    id: appParam.routing.C7_orderBinCharity.id + '001',
    progressTitle: 'Subscription',
    progressValue: () => (100 / C7_orderBinCharity.steps) * 2,
    title: 'Your charity',
    primaryButtonCaption: 'Next',
    next: appParam.routing.C5_orderBin.C5001
  },
  C7003: {
    id: appParam.routing.C7_orderBinCharity.id + '003',
    progressTitle: 'Subscription',
    progressValue: () => 100,
    title: 'Confirm your order details',
    primaryButtonCaption: 'Confirm subscription',
    next: appParam.routing.C5_orderBin.C5009
  }
};
