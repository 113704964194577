import { Component, OnInit } from '@angular/core';
import { appWorkflowData } from '../../helper/appWorkflowData';
import { AuthService } from '../../services/auth.service';
import { appParam } from '../../helper/appSettings';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-uxc1004d-confirm',
  templateUrl: './uxc1004d-confirm.component.html',
  styleUrls: ['./uxc1004d-confirm.component.scss']
})
export class Uxc1004dConfirmComponent implements OnInit {
  _style: string = '';

  // screen config data related to the workflow step
  screenConfig: any;
  workflowData: any;

  render: boolean = false;

  constructor(
    private authService: AuthService,
    private appApi: ApiService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._style = this.authService.getStyle();

    // get the config data
    this.screenConfig = appWorkflowData.C1_signUp.C1004dConfirm;
  }

  async ngOnInit() {
    this.workflowData = await this.appService.getSignUpSummary();
    this.render = true;
  }

  async next() {
    //  update Cognito and DB table of customer
    this.appService.appSpinner(true);
    const resUpdateCustomerDetails = await this.appApi.updateCustomerDetails(
      {
        userId: this.appService.UserObject.userId,
        userPoolId: appParam.auth.userPoolId,
        charityId: this.workflowData.charity.key,
        taxInvoiceEmail: this.workflowData.taxInvoiceEmail
      },
      true
    );

    const resCognito = await this.appApi.updateCognitoUserAttributes(
      {
        userId: this.appService.UserObject.userId,
        userPoolId: appParam.auth.userPoolId,
        charityId: this.workflowData.charity.key,
        taxInvoiceEmail: this.workflowData.taxInvoiceEmail
      },
      true
    );
    this.router.navigate(['/' + this.screenConfig.next], {
      relativeTo: this.route
    });
  }
}
